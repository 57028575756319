import { Link } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withProps } from "recompose";
import { Link as RouterLink } from 'react-router-dom';


const SexyLink = withStyles(theme => ({
    root: {
        color: (props) => props.color || theme.palette.text.secondary,
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
            background: `linear-gradient(#fff,#fff), linear-gradient(#fff,#fff), linear-gradient(${theme.palette.text.secondary}, ${theme.palette.text.secondary})`,
            backgroundSize: '0.05em 1px, 0.05em 1px, 1px 1px',
            backgroundRepeat: 'no-repeat, no-repeat, repeat-x',
            backgroundPosition: '0% 93%, 100% 93%, 0% 93%',
            textShadow: '0.03em 0 #fff, -0.03em 0 #fff, 0 0.03em #fff, 0 -0.03em #fff, 0.06em 0 #fff, -0.06em 0 #fff, 0.09em 0 #fff, -0.09em 0 #fff, 0.12em 0 #fff, -0.12em 0 #fff, 0.15em 0 #fff, -0.15em 0 #fff'
        }
    }
}))(Link)

export default withProps(({ component }) => ({
    component: component || RouterLink 
}))(SexyLink)