import React, { useState, useRef, useEffect } from "react";
import {
  InputLabel,
  InputBase,
  InputAdornment,
  IconButton,
  Typography,
  Popper,
  Fade,
  Paper,
  useMediaQuery,
  Box,
  Button,
  Hidden,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import moment from "moment";
import { withProps } from "recompose";
import theme from "../theme";
import useLanguage from "../languages/index";
import { useStore } from "../store";

const useStyles = makeStyles({
  label: { fontSize: 14 },
  adorment: { marginLeft: -48, zIndex: 1 },
  dateAdorment: { marginLeft: -36, zIndex: 1 },
  leftAdorment: {
    position: "absolute",
    left: 16,
    zIndex: 1,
  },
  disableHover: { background: "inherit !important" },
  popover: { padding: "10px 15px" },
  eventsNone: { pointerEvents: "none" },
  popper: {
    marginLeft: 10,
  },
  paper: {
    boxShadow: "0px 8px 12px 4px rgba(57, 110, 190, 0.1)",
  },
  error: {
    "& input": {
      border: "1px solid #F36363",
    },
  },
  arrowLeft: {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: "10px solid white",
    position: "absolute",
    left: -8,
    top: "calc(50% - 10px)",
  },
  warning: {
    boxShadow: "0px 0px 1px 1px #f3ac63",
  },
  bottomLine: {
    border: "1px solid #DBE5EA",
    margin: "10px 0 8px",
  },
});

const Helper = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    height: 16,
    width: 16,
    borderColor: "rgba(126, 159, 193, .5)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    padding: 0,
    color: theme.palette.text.secondary,
    background: "white !important",
  },
  label: {
    fontSize: 10,
    lineHeight: 1,
  },
}))(Button);

const TextComponent = (props) => {
  const classes = useStyles();

  return (
    <InputBase
      style={{ fontSize: 16 }}
      {...props}
      {...props.InputProps}
      endAdornment={
        <InputAdornment style={{ color: theme.palette.text.secondary }} className={classes.dateAdorment} position="end">
          <DateRangeIcon />
        </InputAdornment>
      }
    />
  );
};

export default function TextInputs({
  placeholder,
  half,
  children,
  type,
  caption,
  inputRef,
  value,
  onChange,
  error,
  warning,
  autoFocus,
  multiline,
  date,
  hideMultiline,
  inputClass,
  InputProps,
  toolTip,
  helperMessage,
  required,
  onBlur,
  ...others
}) {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const rootEl = useRef(null);
  const getText = useLanguage();
  const id = useRef(String(+new Date()));
  const desktop = useMediaQuery("(min-width:600px)");
  const store = useStore();

  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const inputHandleChange = (ev) => {
    setInputValue(ev.target.value);
  };

  return (
    <div {...others}>
      {error && <div id="inputErrorElement" />}
      {warning ? <div id="inputWarningElement" /> : ""}
      <InputLabel required={required} className={classes.label} htmlFor={id.current}>
        {caption || "Input"} {multiline && !hideMultiline ? getText("multiline") : ""}
        {toolTip && (
          <Hidden xsDown>
            <Tooltip
              placement="right"
              title={
                toolTip === "sourceWealth" ? (
                  <>
                    <Typography>{getText("messages.sourceWealth")}</Typography>
                    <Box className={classes.bottomLine} />
                    <Typography variant="body2">{getText("messages.sourceWealthExamples.sourceWealth1")}</Typography>
                    <Typography variant="body2">{getText("messages.sourceWealthExamples.sourceWealth2")}</Typography>
                    <Typography variant="body2">{getText("messages.sourceWealthExamples.sourceWealth3")}</Typography>
                  </>
                ) : (
                  { toolTip }
                )
              }
            >
              <Helper disableRipple>?</Helper>
            </Tooltip>
          </Hidden>
        )}
      </InputLabel>
      {date ? (
        <div ref={rootEl}>
          <DatePicker
            fullWidth
            disableFuture
            minDate={others.minDate || moment("1900-01-01")}
            maxDate={others.maxDate || moment("2100-01-01")}
            className={clsx(classes.label, error ? classes.error : "")}
            value={value}
            id={id.current}
            onChange={onChange}
            TextFieldComponent={withProps({ InputProps })(TextComponent)}
            emptyLabel={getText("emptyDate")}
            format="DD-MM-YYYY"
          />
        </div>
      ) : (
        <Box width={half ? "50%" : "100%"}>
          <InputBase
            className={warning ? classes.warning : ""}
            multiline={multiline}
            rowsMax="4"
            autoFocus={autoFocus}
            fullWidth
            ref={rootEl}
            placeholder={placeholder}
            error={Boolean(error)}
            value={inputValue}
            onChange={inputHandleChange}
            onBlur={onBlur || onChange}
            inputRef={inputRef}
            type={(type === "password" ? (showPassword ? "text" : "password") : type) || "text"}
            id={id.current}
            inputProps={InputProps}
            startAdornment={children ? <InputAdornment className={classes.leftAdorment}>{children}</InputAdornment> : ""}
            endAdornment={
              type === "password" ? (
                <InputAdornment className={classes.adorment} position="end">
                  <IconButton
                    onTouchStart={() => setShowPassword(true)}
                    onTouchEnd={() => setShowPassword(false)}
                    onMouseDown={() => setShowPassword(true)}
                    onMouseUp={() => setShowPassword(false)}
                    disableRipple
                    className={classes.disableHover}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : (
                ""
              )
            }
          />
        </Box>
      )}
      {desktop ? (
        <Popper open={Boolean(error)} anchorEl={rootEl.current} className={classes.popper} transition placement="bottom">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paper}>
                <div className={classes.arrowLeft} />
                <Typography variant="subtitle2" className={classes.popover}>
                  {(error && error[0]) || helperMessage}
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
      ) : (
        <Typography variant="subtitle2" align="right">
          {error && ((error && error[0]) || helperMessage)}
        </Typography>
      )}
    </div>
  );
}
