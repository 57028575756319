import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  videoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "0 56px",
    },
  },
  closeButton: {
    right: 0,
    top: 0,
    position: "absolute",
  },
  dialogTitle: {
    padding: "16px 0",
    fontSize: 18,
  },
  ModalMessage: {
    marginTop: 300,
    boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
    borderRadius: "4px",
    padding: "3rem",
  },
  buttonModal: {
    width: "30%",
    padding: "12px 0",
    top: "17%",
    left: "35%",
  },
  text: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  kinescope: {
    width: 1080,
    height: 540,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 270,
    },
  },
  listener: {
    position: "relative",
    bottom: 2,
    right: 250,
  },
  openListener: {
    width: 400,
    position: "absolute",
    top: "50%",
    transform: "translate(11%, -180%)",
    padding: 5,
    color: "#000000",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#ffffff",
    border: "1px solid #000000",
    borderRadius: 10,
    [theme.breakpoints.down("xs")]: {
      width: 200,
      transform: "translate(75%, -110%)",
    },
  },
  greenButton: {
    background: theme.palette.text.green,
    color: theme.palette.background.default,
    "&:hover": {
      background: theme.palette.text.green,
    },
  },
}));
