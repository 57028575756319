import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { TextField, Box } from "@material-ui/core";
import useLanguage from "../languages";
import { useStore } from "../store";

const useStyles = makeStyles((theme) => ({
  autoComplete: theme.autoComplete,
  textField: theme.MuiautoCompleteTextfiled,
}));

export function AutocompleteMaterial({ options, text, title, value, onChange, flag, error, required, dataattribute }) {
  const classes = useStyles();
  const getText = useLanguage();
  const store = useStore();

  return (
    <Autocomplete
      disablePortal
      disableClearable
      className={classes.autoComplete}
      options={options}
      // onError
      defaultValue={options.find((el) => el.value === value)}
      onChange={(event, { value }) => onChange(text, value)}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.label === value.label}
      noOptionsText={getText("noOptions")}
      renderOption={(props) => (
        <div>
          {flag ? (
            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              <span className={`flag-icon flag-icon-${props.value.toLowerCase()}`} />
              {props.label}
            </Box>
          ) : (
            <span>{props.label}</span>
          )}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          InputLabelProps={{ shrink: true }}
          label={getText(`${title || text}`)}
          error={error}
          required={required}
          data-castil={dataattribute}
        />
      )}
    />
  );
}
