import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PopupPaper from "../../../components/PopupPaper";
import useLanguage from "../../../languages";
import { SELECTS_OPTIONS } from "./ProfileAutocomplete";
import TextInput from "../../../components/TextInput";
import { Autocomplete } from "../../../components/Autocomplete";

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28 },
  button: {
    width: 190,
    padding: "12px 0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
  under: {
    textDecorationLine: "underline",
  },
}));

export default observer(({ handleChange, profile }) => {
  const getText = useLanguage();
  const classes = useStyles();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box mt={2} />
      <PopupPaper inline>
        <Box mt={5}>
          <Typography variant="h5">
            <strong>{getText("Accreditation")}</strong>
          </Typography>
        </Box>
        {profile.citizenship !== "RU" && profile.citizenship !== "US" && profile.citizenship !== "IL" && (
          <Box id="legal_entity_type" mt={3}>
            <Autocomplete
              notflag
              options={SELECTS_OPTIONS.legal_entity_type.map((el) => ({ label: getText(`${el.label}`), value: el.value }))}
              caption={getText("legal_entity_type__label")}
              value={profile?.legal_entity_type}
              onChange={handleChange("legal_entity_type")}
            />
          </Box>
        )}
        {profile.citizenship === "RU" && (
          <Box id="russian_legal_details" mt={3}>
            {profile.entity_type === "Individual" && (
              <Autocomplete
                notflag
                options={SELECTS_OPTIONS.russian_individual_person_select.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                caption={getText("russian_legal_details.INDIVIDUAL_ENTITY")}
                value={profile?.russian_individual_person_select}
                onChange={handleChange("russian_individual_person_select")}
              />
            )}
            {profile.entity_type === "Legal" && (
              <Autocomplete
                notflag
                options={SELECTS_OPTIONS.russian_legal_person_select.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                caption={getText("russian_legal_details.LEGAL_ENTITY")}
                value={profile?.russian_legal_person_select}
                onChange={handleChange("russian_legal_person_select")}
              />
            )}
            {profile.entity_type === "Legal" && profile.russian_legal_person_select === "QUALIFIED_INVESTOR" ? (
              <Autocomplete
                notflag
                options={SELECTS_OPTIONS.russian_legal_qualified_investor_select.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                value={profile?.russian_legal_qualified_investor_select}
                onChange={handleChange("russian_legal_qualified_investor_select")}
              />
            ) : (
              profile.entity_type === "Legal" &&
              profile.russian_legal_person_select === "OTHER" && (
                <Autocomplete
                  notflag
                  options={SELECTS_OPTIONS.russian_legal_other_select.map((el) => ({
                    label: getText(`${el.label}`),
                    value: el.value,
                  }))}
                  value={profile?.russian_legal_other_select}
                  onChange={handleChange("russian_legal_other_select")}
                />
              )
            )}
          </Box>
        )}
        {profile.citizenship === "IL" && (
          <Box id="israel_legal_details" mt={3}>
            {profile.entity_type === "Individual" ? (
              <Autocomplete
                notflag
                options={SELECTS_OPTIONS.israel_individual_person_select.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                caption={getText("israel_legal_details.INDIVIDUAL_ENTITY")}
                value={profile?.israel_individual_person_select}
                onChange={handleChange("israel_individual_person_select")}
              />
            ) : (
              profile.entity_type === "Legal" && (
                <Autocomplete
                  notflag
                  options={SELECTS_OPTIONS.israel_legal_person_select.map((el) => ({
                    label: getText(`${el.label}`),
                    value: el.value,
                  }))}
                  caption={getText("israel_legal_details.LEGAL_ENTITY")}
                  value={profile?.israel_legal_person_select}
                  onChange={handleChange("israel_legal_person_select")}
                />
              )
            )}
          </Box>
        )}
        {profile.citizenship === "US" && (
          <Box id="usa_legal_details" mt={3}>
            {profile.entity_type === "Individual" ? (
              <Autocomplete
                notflag
                options={SELECTS_OPTIONS.usa_individual_person_select.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                caption={getText("usa_legal_details.INDIVIDUAL_ENTITY")}
                value={profile?.usa_individual_person_select}
                onChange={handleChange("usa_individual_person_select")}
              />
            ) : (
              profile.entity_type === "Legal" && (
                <Autocomplete
                  notflag
                  options={SELECTS_OPTIONS.usa_legal_person_select.map((el) => ({
                    label: getText(`${el.label}`),
                    value: el.value,
                  }))}
                  caption={getText("usa_legal_details.LEGAL_ENTITY")}
                  value={profile?.usa_legal_person_select}
                  onChange={handleChange("usa_legal_person_select")}
                />
              )
            )}
          </Box>
        )}
      </PopupPaper>
    </div>
  );
});
