import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  ListItem,
  List,
  IconButton,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import urls, { useAsyncEndpoint } from "../urls";
import theme from "../theme";
import useLanguage from "../languages";
import TextInput from "./TextInput";
import { useStore } from "../store";
import { pdfReportList } from "../api/api"
import { Autocomplete } from "./Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  quarterElement: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.background.input,
    padding: 10,
    width: "100%",
    borderRadius: "6px",
  },
  quarterTitle: {
    fontSize: 16,
  },
  quarterDate: {
    color: theme.palette.text.secondary,
  },
  a: {
    lineHeight: 0,
  },
  dialog: {
    padding: "39px 110px 56px",
  },
  dialogTitle: {
    padding: "16px 0",
    "& h2": {
      fontSize: 24,
      fontWeight: 600,
    },
  },
}));

const useQuarter = (empty) =>
  useAsyncEndpoint(
    () => ({
      url: urls.quarterReports(),
      method: "GET",
    }),
    empty
  );

const useReport = () =>
  useAsyncEndpoint((type, params) => {
    const data = {
      start_date: params.startDate.format("YYYY-MM-DD"),
      end_date: params.endDate.format("YYYY-MM-DD"),
    };

    if (type === "ahs") delete data.start_date;
    return {
      url: type === "ahs" ? urls.ahsReport() : urls.transactionReport(),
      method: "POST",
      data,
    };
  });

export default function ReportPopup({ onClose, open, reportType, ...other }) {
  const classes = useStyles();
  const getText = useLanguage();
  const store = useStore();
  const [reportUrl, generateUrl] = useReport();
  const handleClose = useCallback(() => {
    setRange({
      startDate: moment().quarter(moment().quarter()).date(1),
      endDate: moment(),
    });
    onClose();
  });

  const [dateRange, setRange] = useState({
    startDate: moment().quarter(moment().quarter()).date(1),
    endDate: moment(),
  });

  const [instance, setInstance] = useState('ac2');
  const [isSplit, toggleSplit] = useState(false);

  const [quarterData, fetchQuarter] = useQuarter([]);

  const [reportList, setReportList] = useState([]);

  const fetchReportList = async () => {
    const response = await pdfReportList(reportType)

    const responseJson = await response.json()

    setReportList(responseJson)
  }

  useEffect(() => {
    generateUrl(reportType, dateRange);
    if (reportType === "quarterArchive" && open) fetchQuarter();
    if (reportType !== "quarterArchive" && open) fetchReportList();
  }, [open]);

  const handleChange = useCallback((field, type) => (value) => {
    const state = {
      ...dateRange,
      [field]: value,
    };
    generateUrl(type, state);
    setRange(state);
  });

  const isDisabled = useMemo(() => !reportUrl.complete, [reportUrl]);

  const REPORT_MAPPING = {
    quarter: 'investor',
    ahs: 'ahs',
    transaction: 'transactional'
  }

  const generateReportLink = (instance, reportType, endDate, startDate, split) => {
    const reportLink = `/pdfReport?instance=${instance}&reportType=${REPORT_MAPPING[reportType]}&endDate=${endDate}&split=${split}`

    if (reportType === 'transaction') {
      return `${reportLink}&startDate=${startDate}`
    }

    return reportLink
  }

  const userInstances = () => {
    const { has_bo_profile, has_us_profile, has_ae_profile } = store.profile;

    const instanceArray = [];

    if (has_bo_profile) {
      instanceArray.push('bo')
    }

    if (has_us_profile) {
      instanceArray.push('us')
    }

    if (has_ae_profile) {
      instanceArray.push('ac2')
    }

    return instanceArray
  }

  const userHasOneInstance = () => {
    const { has_bo_profile, has_us_profile, has_ae_profile } = store.profile;

    const instanceArray = [has_bo_profile, has_us_profile, has_ae_profile];

    const hasOneInstance = instanceArray.filter((instance) => instance).length === 1;

    return hasOneInstance;
  };

  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth open={open} onClose={handleClose} {...other}>
      <DialogTitle className={classes.dialogTitle}>
        {reportType === "quarter" && getText("pages.portfolio.cards.quarterReports")}
        {reportType === "ahs" && getText("pages.portfolio.cards.auditReports")}
        {reportType === "transaction" && getText("pages.portfolio.cards.investReports")}
        <IconButton onClick={handleClose} className={classes.closeButton} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {["quarter", "ahs", "transaction"].includes(reportType) && (
        <div>
          {userHasOneInstance() &&
          <Autocomplete
            notflag
            options={[
              { label: 'USA', value: 'us' },
              { label: 'AC II', value: 'ac2' },
              { label: 'BVI', value: 'bo' }, 
            ].filter((option) => userInstances().includes(option.value))}
            caption="Choose instance"
            value={instance}
            onChange={({ target }) => setInstance(target.value)}
          />}

          {reportType === "transaction" && 
          <>
            <br />
            <TextInput
              date
              value={dateRange.startDate}
              onChange={handleChange("startDate")}
              caption={getText("quarters.startDate")}
            />
          </>
          }

          <br />
          <TextInput
            date
            value={dateRange.endDate}
            onChange={handleChange("endDate")}
            caption={getText("quarters.endDate")}
          />
          {store.profile.is_trust &&
            <FormControlLabel
              className={classes.checkboxTitle}
              label={<p className={classes.checkboxTitle}>Split report by trust investors</p>}
              control={
                <Checkbox
                  checked={isSplit}
                  onChange={({ target }) => toggleSplit(target.checked)}
                  color="primary"
                  className={classes.checkbox}
                />
              }
            />
          }
          <br />

          <Button
            component="a"
            href={
              !isDisabled &&
              generateReportLink(instance, reportType, dateRange.endDate.format('YYYY-MM-DD'), dateRange.startDate.format('YYYY-MM-DD'), isSplit)
            }
            target="_blank"
            disabled={isDisabled}
            fullWidth
            variant="contained"
            size="large"
            color="primary"
          >
            {getText("open")}
          </Button>
        </div>
      )}

      {reportType === "quarterArchive" && (
        <List>
          {quarterData.data.map((q) => (
            <ListItem disableGutters key={q.id}>
              <div className={classes.quarterElement}>
                <span className={classes.quarterTitle}>
                  {q.quarter},{q.year}
                </span>
                <span className={classes.quarterDate}>
                  <span style={{ marginTop: 2 }}>{q.language}</span>
                  {/* {moment(q.create_date).format('DD.MM.YYYY')} */}
                  <IconButton
                    style={{
                      marginLeft: 10,
                      color: theme.palette.text.secondary,
                    }}
                    size="small"
                  >
                    <a className={classes.a} download href={q.report_file}>
                      <GetAppIcon />
                    </a>
                  </IconButton>
                </span>
              </div>
            </ListItem>
          ))}
          {quarterData.data.length === 0 && (
            <ListItem disableGutters>
              <div className={classes.quarterElement}>{getText("quarters.noReports")}</div>
            </ListItem>
          )}
        </List>
      )}

      {reportList.length > 0 && (
        <>
          <Box mt={2}>
            <Typography component="p" variant="subtitle2">
              Last generated reports:
            </Typography>
          </Box>

          <List>
            {reportList.slice(0, 5).map((reportItem) => (
              <ListItem disableGutters key={reportItem.id}>
                <div className={classes.quarterElement}>
                  <span className={classes.quarterTitle}>
                    {moment(reportItem.created_at).format('MM/DD/YYYY')}
                  </span>
                  <span className={classes.quarterDate}>
                    <IconButton
                      style={{
                        marginLeft: 10,
                        color: theme.palette.text.secondary,
                      }}
                      size="small"
                    >
                      <a className={classes.a} download href={reportItem.report_file}>
                        <GetAppIcon />
                      </a>
                    </IconButton>
                  </span>
                </div>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Dialog>
  );
}
