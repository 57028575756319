import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NotificationManager } from 'react-notifications';
import validate from 'validate.js';
import TextInput from '../components/TextInput';
import useLanguage from '../languages';
import PopupPaper from '../components/PopupPaper';
import { useLostPassword } from "../api/api";

const useStyles = makeStyles({
    input: { marginTop: 24 },
    button: { marginTop: 40 }
})

export default observer(({ history }) => {
    const getText = useLanguage();
    const desktop = useMediaQuery('(min-width:600px)');
    const classes = useStyles();

    const [restoreData, restore] = useLostPassword("POST");

    const [email, setEmail] = useState();
    const [errors, setErrors] = useState({});

    const restoreConstraints = {
        email: {
            presence: { allowEmpty: false, message: `^${getText('errors.emailEmpty')}` },
            email: { message: `^${getText('errors.emailInvalid')}` }
        },
    }

    const onSubmit = event => {
        event.preventDefault();
        const _errors = validate({ email }, restoreConstraints);
        if (_errors) {
            setErrors(_errors);
            return
        }

        const data = {email}
        restore({data})
    }

    useEffect(() => {
        if (restoreData.complete) {
            if (!restoreData.error) {
                NotificationManager.success(getText('messages.passwordRestore'))
                history.push('/login')
            } else {
                NotificationManager.error(getText('messages.somethingWrong'))
            }
        }
    }, [restoreData])

    return (
        <form onSubmit={onSubmit} noValidate>
            <PopupPaper popup caption={getText('pages.restoration.caption')}>
                <Box mt={2} mb={4}>
                    <Typography color="textSecondary" align={desktop ? "center" : "left"} variant="subtitle1">
                        {getText('pages.restoration.subtitle')}
                    </Typography>
                </Box>
                <TextInput
                    autoFocus
                    type="email"
                    error={errors.email}
                    className={classes.input}
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    caption={getText('email')}
                />
                <Button type="submit" size="large" fullWidth variant="contained" color="primary" className={classes.button}>
                    {getText('pages.restoration.okButton')}
                </Button>
            </PopupPaper>
        </form>
    )
})
