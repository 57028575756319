import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { useMediaQuery, Box, Typography, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import useLanguage from "../../languages/index";
import { ReportsIcon, AuditIcon, UsersIcon, NewsIcon } from "../../components/Icons";
import PopupPaper from "../../components/PopupPaper";
import Echarts from "../../components/Echarts";
import ReportsPopup from "../../components/ReportsPopup";
import RoundTables from "../../components/RoundTables";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      // marginLeft: 260,
      position: "absolute",
      // left: 0,
      // right: 0,
      width: "100%",
      padding: "0 56px",
    },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: -20,
      marginRight: -20,
    },
    position: "relative",
    right: 0,
    left: 0,
    overflow: "hidden",
  },
  expansionPanel: {
    overflowX: "auto",
    overflowY: "hidden",
  },
  depositPanel: {
    background: theme.palette.text.primary,
  },
  radioLabel: {
    height: 35,
  },
  table: {
    "& td": {
      maxWidth: 240,
    },
    "& #col_3": { width: 1 },
    "& #col_4": { width: 1 },
    "& #col_5": { width: 1 },
    "& tr:last-child td": {
      border: "none",
    },
  },
  fileLink: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardButton: {
    marginTop: "auto",
  },
}));

export default function Portfolio() {
  const classes = useStyles();
  const getText = useLanguage();
  const desktop = useMediaQuery("(min-width:600px)");

  const [reportsOpen, openReports] = useState();
  const [reportType, setReportType] = useState();
  const onReportsClose = useCallback(() => {
    openReports(false);
  });

  const showReportPopup = useCallback((reportType) => {
    setReportType(reportType);
    openReports(true);
  });

  return (
    <>
      <ReportsPopup reportType={reportType} open={reportsOpen} onClose={onReportsClose} />
      <Box className={classes.container}>
        <Echarts />
        <Box mt={6}>
          <Grid container direction={desktop ? "row" : "column"} spacing={5}>
            <Grid item sm={6}>
              <PopupPaper wide className={classes.card}>
                <Box display="flex">
                  <ReportsIcon htmlColor="transparent" />
                  <Box ml={2} fontSize={18}>
                    <Typography variant="inherit">
                      <strong>{getText("pages.portfolio.cards.quarterReports")}</strong>
                    </Typography>
                  </Box>
                </Box>
                <Box mt={1.8} mb={2.5} fontSize={12}>
                  <Typography variant="inherit">{getText("pages.portfolio.cards.quarterDescription")}</Typography>
                </Box>
                <Button
                  className={classes.cardButton}
                  onClick={() => showReportPopup("quarter")}
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  {getText("download")}
                </Button>
              </PopupPaper>
            </Grid>
            <Grid item sm={6}>
              <PopupPaper wide className={classes.card}>
                <Box display="flex">
                  <AuditIcon htmlColor="transparent" />
                  <Box ml={2} fontSize={18}>
                    <Typography variant="inherit">
                      <strong>{getText("pages.portfolio.cards.auditReports")}</strong>
                    </Typography>
                  </Box>
                </Box>
                <Box mt={1.8} mb={2.5} fontSize={12}>
                  <Typography variant="inherit">{getText("pages.portfolio.cards.auditDescription")}</Typography>
                </Box>
                <Button
                  className={classes.cardButton}
                  onClick={() => showReportPopup("ahs")}
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  {getText("open")}
                </Button>
              </PopupPaper>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container direction={desktop ? "row" : "column"} spacing={5}>
              <Grid item sm={6}>
                <PopupPaper wide className={classes.card}>
                  <Box display="flex">
                    <UsersIcon htmlColor="transparent" />
                    <Box ml={2} fontSize={18}>
                      <Typography variant="inherit">
                        <strong>{getText("pages.portfolio.cards.investReports")}</strong>
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1.8} mb={2.5} fontSize={12}>
                    <Typography variant="inherit">{getText("pages.portfolio.cards.investDescription")}</Typography>
                  </Box>
                  <Button
                    className={classes.cardButton}
                    onClick={() => showReportPopup("transaction")}
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    {getText("open")}
                  </Button>
                </PopupPaper>
              </Grid>
              <Grid item sm={6}>
                <PopupPaper wide className={classes.card}>
                  <Box display="flex">
                    <NewsIcon className={classes.tabIcon} style={{ fill: "#2356B2" }} />
                    <Box ml={2} fontSize={18}>
                      <Typography variant="inherit">
                        <strong>{getText("pages.portfolio.cards.newsLink")}</strong>
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1.8} mb={2.5} fontSize={12}>
                    <Typography variant="inherit">{getText("pages.portfolio.cards.newsDescription")}</Typography>
                  </Box>
                  <Link to="/news?page=1">
                    <Button
                      className={classes.cardButton}
                      onClick={() => showReportPopup("transaction")}
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      {getText("open")}
                    </Button>
                  </Link>
                </PopupPaper>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container direction={desktop ? "row" : "column"} spacing={5}>
              <Grid item sm={6}>
                <PopupPaper wide className={classes.card}>
                  <Box display="flex">
                    <ReportsIcon htmlColor="transparent" />
                    <Box ml={2} fontSize={18}>
                      <Typography variant="inherit">
                        <strong>{getText("pages.portfolio.cards.quarterArchiveReports")}</strong>
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1.8} mb={2.5} fontSize={12}>
                    <Typography variant="inherit">{getText("pages.portfolio.cards.quarterArchiveDescription")}</Typography>
                  </Box>
                  <Button
                    className={classes.cardButton}
                    onClick={() => showReportPopup("quarterArchive")}
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    {getText("download")}
                  </Button>
                </PopupPaper>
              </Grid>
            </Grid>
          </Box>
          {/* <RoundTables />  Странный ноль в профиле */}
        </Box>
      </Box>
    </>
  );
}
