import React from "react";
import { observer } from "mobx-react-lite";
import useLanguage from "../../../languages";
import { useStyles } from "../../Settings/Cabinet/style";

const Document = observer(() => {
  const getText = useLanguage();
  const classes = useStyles();
  return (
    <>
      I have read and accept the&nbsp;
      <a
        className={classes.under}
        href="/sign-up/risk-factors"
        target="_blank"
        rel="noreferrer"
      >
        Risk Factors
      </a>
    </>
  )
})

export const documentsState = [
  { document: "nda", label: <Document />, status: false },
];
