import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { NotificationManager } from "react-notifications";
import validate from "validate.js";
import PopupPaper from "../../../components/PopupPaper";
import useLanguage from "../../../languages";
import { useStore } from "../../../store";
import { Personal } from "./Personal";
import { Company } from "./Company";
import { Investment } from "./Investment";
import { registerUser } from "../../../api/api";

const useStyles = makeStyles({
  input: { marginTop: 24 },
  button: {
    width: 190,
    padding: "12px 0",
  },
});

const initialState = {
  citizenship: "",
  countryOfTax: "",
  taxIdNumber: "",
  registeredAddress: "",
  city: "",
  country: "",
  investorLegalType: "individual",
  companyName: "",
  registeredAddressCompany: "",
  addressCompany: "",
  cityCompany: "",
  countryCompany: "",
  commentEnd: "",
  recommended: "",
  roles: [
    { role: "beneficiary", label: "pages.signUp.role.beneficiary", status: false },
    { role: "director", label: "pages.signUp.role.director", status: false },
    { role: "management", label: "pages.signUp.role.management", status: false },
    { role: "signatory", label: "pages.signUp.role.signatory", status: false },
  ],
  investmentFocus: [
    {
      stage: "pages.signUp.investmentFocus.stage1.caption",
      label: "pages.signUp.investmentFocus.stage1.label",
      status: false,
    },
    {
      stage: "pages.signUp.investmentFocus.stage2.caption",
      label: "pages.signUp.investmentFocus.stage2.label",
      status: false,
    },
    {
      stage: "pages.signUp.investmentFocus.stage3.caption",
      label: "pages.signUp.investmentFocus.stage3.label",
      status: false,
    },
  ],
  sumInvest: "",
  errors: {},
  remember: true,
};

const templateValidForm = {
  personalForm: false,
  companyForm: false,
  investmentForm: false,
};

export default observer(({ history }) => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();
  const [state, setState] = useState(initialState);
  const [tab, setTab] = useState(0);
  const [validForms, setValidForms] = useState(templateValidForm);
  const [resp, register] = registerUser();

  useEffect(() => {
    setState({ ...state, errors: {} });
  }, [store.language]);

  const handleSwitchTab = async (event) => {
    const min = 0;
    const max = 2;
    const current = event === "back" ? tab - 1 : tab + 1;
    if (current === 1 && state.investorLegalType === "individual") {
      event === "back" ? setTab(current - 1) : setTab(current + 1);
    } else {
      setTab(current);
    }
    current < min && setTab(min);
    current > max && setTab(max);

    if (current === 3) {
      store.setSecondaryRegisterData({
        addressCompany: state.addressCompany,
        citizenship: state.citizenship,
        city: state.city,
        cityCompany: state.cityCompany,
        commentEnd: state.commentEnd,
        companyName: state.companyName,
        country: state.country,
        countryCompany: state.countryCompany,
        countryOfTax: state.countryOfTax,
        investorLegalType: state.investorLegalType,
        recommended: state.recommended,
        registeredAddress: state.registeredAddress,
        registeredAddressEn: state.registeredAddressEn || "",
        registeredAddressCompany: state.registeredAddressCompany,
        remember: state.remember,
        sumInvest: state.sumInvest,
        taxIdNumber: state.taxIdNumber,
        language: store.language,
      });
      const registrationData = {
        ...store.secondaryRegisterData,
      };
      try {
        await register(registrationData);
        if (resp.status !== 200) {
          throw new Error("User exists yet");
        }
        history.push("/sign-up/finish");
      } catch (err) {
        NotificationManager.error("User with this email exists yet"); // TODO переделать обработку ошибки
      }
    }
  };

  const handleChange = (prop) => (event) => {
    if (prop === "citizenship") {
      setState({ ...state, [prop]: event.target.value, countryOfTax: event.target.value });
      return;
    }
    if (prop === "countryOfTax") {
      setState({ ...state, [prop]: event.target.value, country: event.target.value });
      return;
    }

    setState({ ...state, [prop]: event.target.value });
  };

  const onSubmit = (event) => event.preventDefault();

  const handleChangeRole = (prop) => (event) => {
    const updatedRoles = state.roles.map((el) => (el.role === prop ? { ...el, status: event.target.checked } : el));
    setState({ ...state, roles: updatedRoles });
  };

  const handleChangeInvestmentFocus = (prop) => (event) => {
    const updatedInvestmentFocus = state.investmentFocus.map((el) => {
      const elementFocus = el.options?.map((option) =>
        option.label === prop
          ? {
              ...option,
              status: event.target.checked,
            }
          : option
      );
      return { ...el, options: elementFocus };
    });
    setState({ ...state, investmentFocus: updatedInvestmentFocus });
  };
  const checkForm = (value, constraints) => {
    if (value === "back") {
      handleSwitchTab(value);
      return;
    }

    const errors = validate(state, constraints);

    if (!errors) {
      setState({ ...state, errors: {} });
      handleSwitchTab(value);
    } else {
      NotificationManager.error(getText("pages.settings.password.messages.errorFormat"));
      setState({ ...state, errors: errors || {} });
    }
    // handleValidForm('personalForm');
  };

  const handleChangeCheck = (prop) => (event) => {
    const updatedDocs = state.investmentFocus.map((el) =>
      el.label === prop
        ? {
            ...el,
            status: event.target.checked,
          }
        : el
    );
    setState({ ...state, investmentFocus: updatedDocs });
  };

  return (
    <form onSubmit={onSubmit} noValidate style={{ display: "flex", justifyContent: "center" }}>
      <PopupPaper popup caption={getText("pages.signUp.subtitle")}>
        <form>
          {tab === 0 && <Personal state={state} history={history} checkForm={checkForm} handleChange={handleChange} />}
          {tab === 1 && (
            <Company
              state={state}
              history={history}
              checkForm={checkForm}
              handleChange={handleChange}
              handleChangeRole={handleChangeRole}
            />
          )}
          {tab === 2 && (
            <Investment
              state={state}
              history={history}
              checkForm={checkForm}
              handleChange={handleChange}
              handleChangeInvestmentFocus={handleChangeInvestmentFocus}
              handleChangeCheck={handleChangeCheck}
            />
          )}
        </form>
      </PopupPaper>
    </form>
  );
});
