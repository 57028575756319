export const SELECTS_OPTIONS_CABINET = {
  interestOwnershipTypes: [
    {
      label: "pages.cabinet.interestOwnershipTypes.singleIndividual",
      value: "SI",
    },
    { label: "pages.cabinet.interestOwnershipTypes.jointTenats", value: "JT" },
    {
      label: "pages.cabinet.interestOwnershipTypes.tenantsCommon",
      value: "TC",
    },
    {
      label: "pages.cabinet.interestOwnershipTypes.communityProperty",
      value: "CP",
    },
    { label: "pages.cabinet.interestOwnershipTypes.other", value: "OT" },
  ],
  nonInterestOwnershipTypes: [
    {
      label: "pages.cabinet.nonInterestOwnershipTypes.limitedPartnership",
      value: "LP",
    },
    {
      label: "pages.cabinet.nonInterestOwnershipTypes.generalPartnership",
      value: "GP",
    },
    {
      label: "pages.cabinet.nonInterestOwnershipTypes.limitedCompany",
      value: "LLC",
    },
    {
      label: "pages.cabinet.nonInterestOwnershipTypes.corporation",
      value: "CORP",
    },
    {
      label: "pages.cabinet.nonInterestOwnershipTypes.individualAccount",
      value: "IRA",
    },
    { label: "pages.cabinet.nonInterestOwnershipTypes.trust", value: "TRUST" },
    { label: "pages.cabinet.nonInterestOwnershipTypes.other", value: "OT" },
  ],
  investor_entity_statement: [
    {
      label: "investor_entity_statement.NOT_ORGANIZED",
      value: "NOT_ORGANIZED",
    },
    { label: "investor_entity_statement.NOT_CONTROL", value: "NOT_CONTROL" },
    { label: "investor_entity_statement.WILL_SHARE", value: "WILL_SHARE" },
    {
      label: "investor_entity_statement.MORE_THAN_10_PERCENTS",
      value: "MORE_THAN_10_PERCENTS",
    },
    {
      label: "investor_entity_statement.MORE_THAN_40_PERCENTS",
      value: "MORE_THAN_40_PERCENTS",
    },
    {
      label: "investor_entity_statement.GOVERNING_DOCS",
      value: "GOVERNING_DOCS",
    },
  ],
  investor_entity_for_all_period_statement: [
    {
      label: "investor_entity_for_all_period_statement.NOT_PARTNERSHIP",
      value: "NOT_PARTNERSHIP",
    },
    {
      label: "investor_entity_for_all_period_statement.LESS_THAN_100",
      value: "LESS_THAN_100",
    },
    {
      label: "investor_entity_for_all_period_statement.MORE_THAN_50",
      value: "MORE_THAN_50",
    },
  ],
  entity_type: [
    { label: "entity_type.INDIVIDUAL", value: "Individual" },
    { label: "entity_type.ENTITY", value: "Entity" },
  ],
  tax_form_non_us: [
    { label: "pages.cabinet.taxFrom.ben", value: "W-8BEN" },
    { label: "pages.cabinet.taxFrom.ben-e", value: "W-8BEN-E" },
  ],
  selection: [
    { label: "pages.cabinet.selection.yes", value: "YES" },
    { label: "pages.cabinet.selection.no", value: "NO" },
  ],
  is_us_person: [
    { label: "pages.cabinet.usPerson.isUSPerson", value: "YES" },
    { label: "pages.cabinet.usPerson.isNonUSPerson", value: "NO" },
  ],
  is_us_federal_taxes: [
    { label: "pages.cabinet.usIncomeTax.federalTax", value: "YES" },
    { label: "pages.cabinet.usIncomeTax.federalNotTax", value: "NO" },
  ],
  accredited_investor_representation_points: [
    {
      label: "pages.cabinet.accreditedInvestor.indIncomeTest",
      value: "INCOME_TEST",
    },
    {
      label: "pages.cabinet.accreditedInvestor.indNetWorthTest",
      value: "NET_WORTH_TEST",
    },
    {
      label: "pages.cabinet.accreditedInvestor.director",
      value: "POSITION_OF_ISSUER",
    },
    { label: "pages.cabinet.accreditedInvestor.selfDirector", value: "IRA" },
    {
      label: "pages.cabinet.accreditedInvestor.employeeInvestment",
      value: "CERTAIN_PLAN",
    },
    {
      label: "pages.cabinet.accreditedInvestor.employeeTotal",
      value: "EXCESS_OF_5_MLN",
    },
    {
      label: "pages.cabinet.accreditedInvestor.revocableTrust",
      value: "ACCREDITED_INVESTOR",
    },
    {
      label: "pages.cabinet.accreditedInvestor.irrevocableTrust",
      value: "IRREVOCABLE_TRUST",
    },
    {
      label: "pages.cabinet.accreditedInvestor.corporation",
      value: "BUSSINESS_TRUST",
    },
    {
      label: "pages.cabinet.accreditedInvestor.other",
      value: "OTHER_ENTITIES",
    },
    {
      label: "pages.cabinet.accreditedInvestor.entityWholly",
      value: "OWNED_BY_ACCREDITED",
    },
    { label: "pages.cabinet.accreditedInvestor.none", value: "NONE" },
  ],
  accredited_investor_representation_points_other: [
    { label: "pages.cabinet.accreditedInvestorOther.bank", value: "BANK" },
    {
      label: "pages.cabinet.accreditedInvestorOther.savingAssociation",
      value: "SAVING_ASSOCIATION",
    },
    {
      label: "pages.cabinet.accreditedInvestorOther.brokerDealer",
      value: "BROKER_DEALER",
    },
    {
      label: "pages.cabinet.accreditedInvestorOther.insuranceCompany",
      value: "INSURANCE_COMPANY",
    },
    {
      label: "pages.cabinet.accreditedInvestorOther.investmentCompany",
      value: "INVESTMENT_COMPANY",
    },
    {
      label:
        "pages.cabinet.accreditedInvestorOther.bussinessDevelopmentCompany",
      value: "BUSSINESS_DEVELOPMENT_COMPANY",
    },
    {
      label: "pages.cabinet.accreditedInvestorOther.smallInvestmentCompany",
      value: "SMALL_INVESTMENT_COMPANY",
    },
    {
      label: "pages.cabinet.accreditedInvestorOther.employeeBenefitPlan",
      value: "EMPLOYEE_BENEFIT_PLAN",
    },
    {
      label: "pages.cabinet.accreditedInvestorOther.privateBussiness",
      value: "PRIVATE_BUSSINESS",
    },
  ],
  investment_size: [
    {
      label: "pages.cabinet.isRisksApproved.investmentCompanies",
      value: "INVESTMENT_COMPANIES",
    },
    {
      label: "pages.cabinet.isRisksApproved.investmentPrivately",
      value: "INVESTMENT_PRIVATELY",
    },
    {
      label: "pages.cabinet.isRisksApproved.investorQuestionnaire",
      value: "INVESTOR_QUESTIONNAIRE",
    },
    {
      label: "pages.cabinet.isRisksApproved.acceptedAltaclub",
      value: "ACCEPTED_ALTACLUB",
    },
    {
      label: "pages.cabinet.isRisksApproved.dataProvided",
      value: "DATA_PROVIDED",
    },
  ],
};
