import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import useLanguage from "../../../languages";
import { useStyles } from "./style";
import { useStore } from "../../../store";

export default observer(({ profile, currentStep, setCurrentStep }) => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();
  const TEXT_PAGINATION = useMemo(
    () =>
      profile.entity_type === "Entity"
        ? ["Corporate Questionnaire", "Beneficiary Questionnaire", "Documents verification"]
        : ["Individual Questionnaire", "Documents verification"],
    [profile.entity_type]
  );

  const temp = useMemo(() => new Array(TEXT_PAGINATION.length).fill(1).map((_, i) => i + 1), [profile.entity_type]);

  return (
    <div className={classes.container}>
      {/* {profile.entity_type && ( */}
        <>
          <div className={classes.paginationContainer}>
            {temp.map((num) => (
              <Box className={classes.paginationBlock} key={num}>
                <button
                  type="submit"
                  onClick={() => {
                    if (currentStep > num) {
                      setCurrentStep(num)
                    }
                  }}
                  className={`${classes.paginationItem} ${currentStep >= num && classes.paginationItemCompleted}`}
                >
                  {num}
                </button>
                <div className={classes.paginationLineContainer}>
                  <hr className={classes.paginationLine} />
                </div>
              </Box>
            ))}
          </div>
          <div className={classes.paginationTextContainer}>
            {TEXT_PAGINATION.map((text) => (
              <div key={text} className={classes.paginationText}>
                {text}
              </div>
            ))}
          </div>
        </>
      {/* )} */}
    </div>
  );
});
