import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import { PDFIcon } from "../../components/Icons";
import PopupPaper from "../../components/PopupPaper";
import { useDocusignDocuments } from "../../api/api";
import useLanguage from "../../languages";
import Empty from "../../components/Empty";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    paddingLeft: "12px",
  },
  emptyText: {
    position: "absolute",
    height: "calc(100vh - 87px - 120px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 110px)",
  },
  title: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
export default observer(() => {
  const classes = useStyles();
  const getText = useLanguage();
  const [documents, fetchDocuments] = useDocusignDocuments();
  const [docs, setDocs] = useState();

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    if (documents.complete && !documents.error) {
      const rawData = documents.data;
      setDocs([...rawData]);
    }
  }, [documents]);

  return (
    <Box>
      {docs && docs.length ? (
        docs.map((document) => (
          <PopupPaper clickable style={{ margin: "10px" }}>
            <Box display="flex">
              <PDFIcon />
              <Box className={classes.textContainer}>
                <Typography variant="h4">
                  <a href={document.document} target="_blank" rel="noopener noreferrer" className={classes.title}>
                    {document.document_type}
                  </a>
                </Typography>
                <Typography variant="subtitle1">{document.description || "No description provided"}</Typography>
              </Box>
            </Box>
          </PopupPaper>
        ))
      ) : (
        <Box className={classes.emptyText}>
          <Empty text={getText("tips.noTableFiles")} />
        </Box>
      )}
    </Box>
  );
});
