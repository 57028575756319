import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MultiSelect from "../../../components/MultiSelect";
import PopupPaper from "../../../components/PopupPaper";
import useLanguage from "../../../languages";
import { SELECTS_OPTIONS } from "./ProfileAutocomplete";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
  },
});

export default observer(({ handleChangeSelect, profile }) => {
  const getText = useLanguage();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box mt={2} />
      <PopupPaper inline>
        <Box mt={5}>
          <Typography variant="h5">
            <strong>{getText("Investments information")}</strong>
          </Typography>
        </Box>
        <Box id="investment_size" mt={3}>
          <MultiSelect
            select={SELECTS_OPTIONS}
            handleChangeSelect={handleChangeSelect}
            profile={profile.investment_size}
            type="investment_size"
          />
        </Box>
        <Box id="investment_type" mt={3}>
          <MultiSelect
            select={SELECTS_OPTIONS}
            handleChangeSelect={handleChangeSelect}
            profile={profile.investment_type}
            type="investment_type"
          />
        </Box>
        <Box id="investment_industry" mt={3}>
          <MultiSelect
            select={SELECTS_OPTIONS}
            handleChangeSelect={handleChangeSelect}
            profile={profile.investment_industry}
            type="investment_industry"
          />
        </Box>
      </PopupPaper>
    </div>
  );
});
