import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import urls, { useAsyncEndpoint } from "../../../urls";
import { useStore } from "../../../store";
import { Panel } from "./Panel";
import useLanguage from "../../../languages";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 56px",
    },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      background: "#FFFFFF",
      display: "block",
    },
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
  },
  radioLabel: {
    height: 35,
  },
  table: {
    "& td": {
      maxWidth: 240,
    },
    "& #col_1": { minWidth: 85 },
    "& #col_3": { width: 110 },
    "& #col_4": { width: 140 },
    "& #col_5": { width: 120 },
    "& tr:last-child td": {
      border: "none",
    },
  },
  fileInput: {
    display: "none",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  expansionPanel: {
    overflowX: "auto",
    overflowY: "hidden",
  },
}));

const useDocuments = () =>
  useAsyncEndpoint(({ params } = {}) => ({
    url: urls.documents({ params }),
    method: "GET",
  }));

const useDocumentsTypes = () =>
  useAsyncEndpoint(() => ({
    url: urls.documentsTypes(),
    method: "GET",
  }));

const useUpload = () =>
  useAsyncEndpoint((data, id) => ({
    url: urls.documentUpload(id),
    method: "PUT",
    data,
  }));

export default observer(({ style, disableExpansion, onlyKyc, id, border, header = true }) => {
  const classes = useStyles();
  const history = useHistory();
  const store = useStore();
  const getText = useLanguage();
  const location = useLocation();
  const [documentsTypes, fetchDocumentsTypes] = useDocumentsTypes();
  const [documents, fetchDocuments] = useDocuments();
  const [profile, setProfile] = useState({ ...store.profile });
  const [uploadResult, upload] = useUpload();

  const documentsRequest = () => {
    fetchDocuments({ params: `kyc=${Boolean(onlyKyc)}` });
  };

  const handleChangeDoc = (doc, documentType, id) => {
    const formData = new FormData();
    formData.append("signed", false);
    formData.append("is_active", false);
    formData.append("document_type", documentType);
    formData.append("document_file", doc);
    formData.append("id", id);
    upload(formData, id);
  };

  useEffect(() => {
    documentsRequest();
    fetchDocumentsTypes();
  }, [uploadResult]);

  useEffect(() => {
    if (documents.complete && !documents.error) {
      const data = documents.data.documents;
      store.setDocuments(data);
    }
  }, [documents]);

  useEffect(() => {
    if (Object.keys(profile).length) {
      if (profile.entity_type === "Individual" && profile.citizenship === "") {
        NotificationManager.error(getText("messages.profileCountry"));
      } else if (profile.entity_type === "Legal" && profile.company_country === "") {
        NotificationManager.error(getText("messages.companyCountry"));
      }
    }
  }, [history, location, profile.citizenship, profile.company_country]);

  return (
    <Box className={classes.container} id={id} style={style}>
      <Panel
        header={header}
        disableExpansion={disableExpansion}
        defaultOpen={store.profile.investor_legal_type !== 2}
        documentsTypes={documentsTypes}
        documents={documents.data.documents}
        profile={store.profile}
        useStyles={useStyles}
        border={border}
        documentsRequest={documentsRequest}
        handleChangeDoc={handleChangeDoc}
      />
      {store.profile.investor_legal_type === 2 &&
        documents.data.ubos &&
        documents.data.ubos.map((ubo) => (
          <Panel
            header={header}
            disableExpansion={disableExpansion}
            documentsTypes={documentsTypes}
            key={ubo.id}
            documents={ubo.documents}
            profile={ubo}
            border={border}
            documentsRequest={documentsRequest}
            handleChangeDoc={handleChangeDoc}
          />
        ))}
    </Box>
  );
});
