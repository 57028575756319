import React, { useLayoutEffect, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import posed, { PoseGroup } from "react-pose";
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import { useStore } from "../store";
import Navigation from "../components/Navigation/Navigation";
import Restoration from "./Restoration";
import Login from "./Login";
import LoginInstance from "./LoginInstance"
import RestorationPassword from "./RestorationPassword";
import { langMap } from "../languages";

const RouteContainer = posed.div();
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      background: "#FFFFFF",
      display: "block",
    },
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default observer(({ history, location }) => {
  const classes = useStyles();
  const store = useStore();

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const languageParam = params.get("language");
    langMap.forEach((el) => el.code === languageParam && store.setLang(languageParam));
  }, [location.search]);

  useEffect(() => {
    if (store.token.access) {
      !location.search.length ? history.push("/portfolio") : history.push(location.search.replace(/^./, ""));
    }
  }, [store.token.access]);

  return (
    <Container className={classes.root}>
      <Navigation key="navigation" login location={location} />
      <PoseGroup>
        <RouteContainer key="loginTransition">
          <Switch>
            <Route key="restore" path="/login/restore" component={Restoration} />
            <Route key="password" path="/login/restore-password" component={RestorationPassword} />
            <Route key="login-instance" path="/login/instance" component={LoginInstance} />
            <Route key="login" path="/login" component={Login} />
          </Switch>
        </RouteContainer>
      </PoseGroup>
    </Container>
  );
});
