import React, { useState } from "react";
import { Dialog, DialogTitle, Box, IconButton, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { NotificationManager } from "react-notifications";
import useLanguage from "../languages/index";
import TextInput from "./TextInput";
import PopupPaper from "./PopupPaper";
import { REACT_APP_API_URL } from "../constants/selectConstants";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  dialog: {
    padding: "40px",
  },
  dialogTitle: {
    padding: 0,
    "& h2": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
  input: {
    minHeight: 96,
  },
  button: {
    width: 254,
    padding: "12px 0",
  },
  paper: {
    marginBottom: 8,
    padding: 10,
  },
}));

const initialState = {
  sharesAmount: "",
  numberMin: "",
  numberMax: "",
  errors: {},
};

export default function SecondaryKnowMorePopup({ onClose, open, modalStartup, purchase }) {
  const classes = useStyles();
  const getText = useLanguage();
  const [state, setState] = useState(initialState);

  const onCloseModal = () => {
    setState(initialState);
    onClose();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (JSON.stringify(state) !== JSON.stringify(initialState)) {
      const data = {
        min_deal_amount: state.numberMin || null,
        max_deal_amount: state.numberMax || null,
        shares_amount: state.sharesAmount || null,
        application_type: purchase ? "purchase" : "sale",
        startup: modalStartup.id,
      };
      const result = await fetch(`${REACT_APP_API_URL}/api/v1/deals/secondary/create/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      await onCloseModal();
    } else {
      NotificationManager.error(getText("errors.emptyFields"));
    }
  };

  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography color="primary" variant="inherit">
          {modalStartup ? modalStartup.startup_name || modalStartup.startup.name : "Unknown"}
        </Typography>
        <IconButton onClick={onClose} className={classes.closeButton} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box mt={3}>
        {!purchase && (
          <TextInput
            type="number"
            value={state.sharesAmount}
            onChange={(event) => setState({ ...state, sharesAmount: event.target.value })}
            caption={getText("pages.secondary.sharesAmount")}
          />
        )}
      </Box>
      <Box mt={3} display="flex">
        <TextInput
          type="number"
          value={state.numberMin}
          onChange={(event) => setState({ ...state, numberMin: event.target.value })}
          caption={getText("pages.secondary.min")}
        />
        <Box mr={3} />
        <TextInput
          type="number"
          value={state.numberMax}
          onChange={(event) => setState({ ...state, numberMax: event.target.value })}
          caption={getText("pages.secondary.max")}
        />
      </Box>
      <Box mt={3} display="flex" justifyContent="center">
        <Button onClick={onSubmit} className={classes.button} size="large" color="secondary" variant="contained">
          {getText("pages.secondary.sale")}
        </Button>
        <Box mr={2} />
        <Button onClick={onCloseModal} className={classes.button} size="large" color="secondary" variant="contained">
          {getText("pages.secondary.close")}
        </Button>
      </Box>
    </Dialog>
  );
}
