import { REACT_APP_API_URL } from "../constants/selectConstants";
import urls, { useAsyncEndpoint } from "../urls";

// https://altaclub.rogovsky.me/ --> use in package.json (proxy)

const useProfile = () =>
  useAsyncEndpoint(() => ({
    url: urls.profile(),
    method: "GET",
  }));

const useInfo = () =>
  useAsyncEndpoint(() => ({
    url: urls.info(),
    method: "GET",
  }));

const useNotifications = () =>
  useAsyncEndpoint(() => ({
    url: urls.notifications(),
    method: "GET",
  }));

const useNotificationsSummary = () =>
  useAsyncEndpoint(() => ({
    url: urls.notificationsSummary(),
    method: "GET",
  }));

const useAlertFlag = () =>
  useAsyncEndpoint((data) => ({
    url: urls.profile(),
    method: "PUT",
    data,
  }));

const useAlertById = (method = "GET") =>
  useAsyncEndpoint(({ id, data } = {}) => ({
    url: urls.alertsById(id),
    method,
    data,
  }));

const useSearch = () =>
  useAsyncEndpoint(({ params } = {}) => ({
    url: urls.notificationsParam(params),
    method: "GET",
  }));

const useTypesNotifications = () =>
  useAsyncEndpoint(() => ({
    url: urls.notificationsTypes(),
    method: "GET",
  }));

const postCreditionals = () =>
  useAsyncEndpoint((data) => ({
    url: urls.login(),
    method: "POST",
    data,
  }));

const postChangePassword = () =>
  useAsyncEndpoint((data) => ({
    url: urls.changePassword(),
    method: "POST",
    data,
  }));

const useLostPassword = (method = "GET") =>
  useAsyncEndpoint(({ data, token } = {}) => ({
    url: urls.lost(token),
    noAuth: true,
    method,
    data,
  }));

const registerUser = () =>
  useAsyncEndpoint((data) => ({
    url: urls.register(),
    noAuth: true,
    method: "POST",
    data,
  }));

const useRounds = () =>
  useAsyncEndpoint(() => ({
    url: urls.roundsReport(),
    method: "GET",
  }));

const useStartup = () =>
  useAsyncEndpoint(() => ({
    url: urls.startupReport(),
    method: "GET",
  }));

const useInvestor = () =>
  useAsyncEndpoint(() => ({
    url: urls.investorReport(),
    method: "GET",
  }));

const useTypeReport = () =>
  useAsyncEndpoint((id, type) => ({
    url: urls.typeReport(id, type),
    method: "GET",
  }));

const useSecondaryMarket = () =>
  useAsyncEndpoint(() => ({
    url: urls.secondaryMarket(),
    method: "GET",
  }));

const useDocusignDocuments = () =>
  useAsyncEndpoint(() => ({
    url: urls.docusignDocuments(),
    method: "GET",
  }));

const useNews = () =>
  useAsyncEndpoint((params) => ({
    url: urls.news(params),
    method: "GET",
  }));

const useMoreNews = () =>
  useAsyncEndpoint((startup) => ({
    url: urls.moreNews(startup),
    method: "GET",
  }));

const useReportNews = () =>
  useAsyncEndpoint((startup = null, filters) => ({
    url: urls.reportNews(startup, filters),
    method: "GET",
  }));

const useStartupOptions = () =>
  useAsyncEndpoint(() => ({
    url: urls.startupList(),
    method: "GET",
  }));

const mappingLogin = () =>
  fetch(`${REACT_APP_API_URL}${urls.mappingLogin()}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.access}`,
    },
  });

const obtainMappingTokens = (body) =>
  fetch(`${REACT_APP_API_URL}${urls.mappingObtain()}`, {
    method: "POST",
    body,
    headers: {
      "Content-type": "application/json",
    },
  });

const isFatfCountry = (countryCode) =>
  fetch(`${REACT_APP_API_URL}${urls.fatfCountry(countryCode)}`, {
    method: "GET",
  });

const sendFirebaseToken = (body) =>
  fetch(`${REACT_APP_API_URL}${urls.fcmToken()}`, {
    method: "POST",
    body,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.access}`,
    },
  });

const generatePdfReport = (body) =>
  fetch(`${REACT_APP_API_URL}${urls.generatePdfReport()}`, {
    method: "POST",
    body,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.access}`,
    },
  });

const pdfReportList = (reportType) =>
  fetch(`${REACT_APP_API_URL}${urls.pdfReportList(reportType)}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.access}`,
    },
  });

const reportInstances = () =>
  fetch(`${REACT_APP_API_URL}${urls.reportInstances()}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.access}`,
    },
  });

const reportByInstance = (instance) =>
  fetch(`${REACT_APP_API_URL}${urls.reportByInstance(instance)}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.access}`,
    },
  });

const startupListByInstances = () =>
  fetch(`${REACT_APP_API_URL}${urls.startupListByInstances()}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.access}`,
    },
  });

export {
  useMoreNews,
  useNews,
  useDocusignDocuments,
  useSecondaryMarket,
  useInfo,
  useSearch,
  useProfile,
  useRounds,
  useStartup,
  useInvestor,
  useTypeReport,
  registerUser,
  useAlertFlag,
  useAlertById,
  useLostPassword,
  postCreditionals,
  useNotifications,
  postChangePassword,
  useTypesNotifications,
  useNotificationsSummary,
  mappingLogin,
  obtainMappingTokens,
  isFatfCountry,
  useReportNews,
  useStartupOptions,
  sendFirebaseToken,
  generatePdfReport,
  pdfReportList,
  reportInstances,
  reportByInstance,
  startupListByInstances,
};
