/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { Table, TableHead, TableRow, TableCell, Typography, Box, TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import * as numeral from "numeraljs";
import PanelCompany from "./PanelCompany";
import { PdfIcon } from "./Icons";
import theme from "../theme";
import urls, { useAsyncEndpoint } from "../urls";

import { useStore } from "../store";
import useLanguage from "../languages/index";

const useStyles = makeStyles((theme) => ({
  table: {
    "& td": {
      maxWidth: 240,
    },
    "& #col_3": { width: 1 },
    "& #col_4": { width: 1 },
    "& #col_5": { width: 1 },
    "& tr:last-child td": {
      border: "none",
    },
  },
  fileLink: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

const useTables = () =>
  useAsyncEndpoint(
    () => ({
      url: urls.tablesPortfolio(),
      method: "GET",
    }),
    []
  );

export default function RoundTables() {
  const classes = useStyles();
  const [tablesData, fetchTables] = useTables([]);
  const getText = useLanguage();
  const store = useStore();

  useEffect(() => {
    fetchTables();
  }, []);

  return (
    <>
      {tablesData &&
        tablesData.data.length &&
        tablesData.data
          .sort(
            (a, b) =>
              (a.startup_name || "").localeCompare(b.startup_name || "") || moment(a.round_date).diff(moment(b.round_date))
          )
          .map((round) =>
            round.total_value_usd ? (
              <PanelCompany key={round.round_id} round={round}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{getText("tables.date")}</TableCell>
                      <TableCell>{getText("tables.event")}</TableCell>
                      <TableCell>{getText("tables.files")}</TableCell>
                      <TableCell>{getText("tables.cash")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {round.operations
                      .sort((a, b) => moment(b.date).diff(moment(a.date)))
                      .map((operation, index) => (
                        <TableRow key={index}>
                          <TableCell id="col_1">
                            <Typography variant="inherit" noWrap>
                              {operation.date}
                            </Typography>
                          </TableCell>
                          <TableCell id="col_2">
                            <Typography variant="inherit" noWrap>
                              {store.language === "en" ? operation.name_en : operation.name}
                            </Typography>
                          </TableCell>
                          <TableCell id="col_3">
                            {operation.files.map((f) => (
                              <a href={f.url} className={classes.fileLink}>
                                <Box mr={1.5} height={24}>
                                  <PdfIcon color="secondary" />
                                </Box>
                                <Typography variant="inherit" noWrap>
                                  {f.name}
                                </Typography>
                              </a>
                            ))}
                            {/* {operation.files.length === 0 ? <Typography variant="inherit" noWrap>{getText('tips.noTableFiles')}</Typography> : ''} */}
                          </TableCell>
                          <TableCell id="col_4">
                            <Box color={theme.palette.text[operation.value_usd > 0 ? "green" : "primary"]}>
                              <Typography variant="inherit" noWrap>
                                {numeral(operation.value_usd).format("$0,0.00")}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </PanelCompany>
            ) : (
              ""
            )
          )}
    </>
  );
}
