import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogTitle, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { isUndefined } from "util";
import validate from "validate.js";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../languages";
import PopupPaper from "../../components/PopupPaper";
import TextInput from "../../components/TextInput";
import { postChangePassword } from "../../api/api";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "75%",
    marginLeft: "50px",
    display: "flex",
    flexDirection: "column",
  },
  formContainer: {
    marginTop: "30px",
  },
  input: {
    minHeight: 50,
    marginBottom: 25,
  },
  button: {
    width: 254,
    padding: "12px 0",
  },
  divider: {
    background: "#0d7bff",
    width: 4,
    height: 105,
    display: "flex",
    borderRadius: 4,
    marginBottom: 15,
  },
  containerText: {
    display: "flex",
    marginTop: 30,
  },
  containerDivider: {
    marginRight: 17,
    display: "flex",
    alignItems: "flex-end",
  },
  ul: {
    "& > li": { color: "#0d7bff", "& > span": { color: "#374464" } },
  },
}));

const initialState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
  errors: {},
  remember: true,
};

const ChangePassword = () => {
  const classes = useStyles();
  const getText = useLanguage();

  const [state, setState] = useState(initialState);
  const [changePassword, fetchChangePassword] = postChangePassword();

  const loginConstraints = {
    currentPassword: {
      presence: { allowEmpty: false, message: `^${getText("errors.passwordEmpty")}` },
      equality: {
        attribute: "newPassword",
        message: `^${getText("pages.settings.password.messages.validation.1")}`,
        comparator(v1, v2) {
          return v1 !== v2;
        },
      },
    },
    newPassword: {
      presence: { allowEmpty: false, message: `^${getText("errors.passwordEmpty")}` },
      length: { minimum: 8, message: `^${getText("errors.passwordShort", 8)}` },
      format: {
        pattern: "^(?=\\S*[a-z])(?=\\S*[A-Z])(?=\\S*\\d)\\S*$",
        flags: "g",
        message: `^${getText("pages.settings.password.messages.validation.2")}`,
      },
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: `^${getText("errors.passwordEmpty")}` },
      equality: {
        attribute: "newPassword",
        message: `^${getText("pages.settings.password.messages.validation.3")}`,
        comparator(v1, v2) {
          return v1 === v2;
        },
      },
    },
  };

  const handleChange = (prop, value, _clearError) => (event) => {
    if (_clearError) {
      setState({ ...state, [prop]: isUndefined(value) ? event.target.value : value, errors: {} });
    } else if (!event.target.value.length) {
      setState({ ...state, [prop]: isUndefined(value) ? event.target.value : value });
    } else {
      const arr = { ...state, [prop]: isUndefined(value) ? event.target.value : value };
      const blurObject = { ...state, [prop]: event.target.value };
      const errors = validate(blurObject, loginConstraints);

      if (!errors) {
        setState({ ...arr, errors: {} });
      } else {
        errors[prop] && NotificationManager.error(errors[prop]);
        setState({ ...arr, errors: { [prop]: errors[prop] } || {} });
      }
    }
  };

  const handleFocus = (prop) => (event) => {
    if (event.target.value.length) {
      const blurObject = { ...state, [prop]: event.target.value };
      const errors = validate(blurObject, loginConstraints);
      if (!errors) {
        setState({ ...state, errors: {} });
      } else {
        // errors[prop] && NotificationManager.error(errors[prop]);
        setState({ ...state, errors: { [prop]: errors[prop] } || {} });
      }
    }
  };

  useEffect(() => {
    if (!changePassword.error && changePassword.complete) {
      NotificationManager.success(getText("pages.settings.password.messages.success"));
      openSecondary(true);
    }
    if (changePassword.error) {
      if (changePassword.data && changePassword.data.old_password) {
        NotificationManager.error(getText("pages.settings.password.messages.error"));
        setState({ ...state, errors: { currentPassword: [getText("pages.settings.password.messages.error")] } });
      } else {
        NotificationManager.error(getText("pages.settings.password.messages.errorFormat"));
      }
    }
  }, [changePassword]);

  const onSubmit = (event) => {
    event.preventDefault();
    const errors = validate(state, loginConstraints);
    if (!errors) {
      setState({ ...state, errors: {} });
      const { currentPassword, newPassword, confirmPassword } = state;
      const data = {
        old_password: currentPassword,
        new_password: newPassword,
        new_password2: confirmPassword,
      };
      fetchChangePassword(data);
    } else {
      NotificationManager.error(getText("pages.settings.password.messages.errorFormat"));
      setState({ ...state, errors: errors || {} });
    }
  };

  const [secondaryOpen, openSecondary] = useState();
  const onSecondaryClose = useCallback(() => {
    clearFields();
    openSecondary(false);
  });

  const clearFields = () => {
    setState({
      ...state,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <PopupPaper className={classes.container} inline>
      <PasswordPopup open={secondaryOpen} onClose={onSecondaryClose} />
      <Typography variant="h5">
        <strong>{getText("pages.settings.password.caption")}</strong>
      </Typography>
      <Typography variant="h7">
        <div className={classes.containerText}>
          <div className={classes.containerDivider}>
            <div className={classes.divider} />
          </div>
          <div>
            <strong>{getText("pages.settings.password.about")}</strong>
            <ul className={classes.ul}>
              <li>
                <span>{getText("pages.settings.password.rules.1")}</span>
              </li>
              <li>
                <span>{getText("pages.settings.password.rules.2")}</span>
              </li>
              <li>
                <span>{getText("pages.settings.password.rules.3")}</span>
              </li>
              <li>
                <span>{getText("pages.settings.password.rules.4")}</span>
              </li>
            </ul>
          </div>
        </div>
      </Typography>
      <form className={classes.formContainer}>
        <TextInput
          type="password"
          error={state.errors.currentPassword}
          className={classes.input}
          value={state.currentPassword}
          onChange={handleChange("currentPassword")}
          caption={getText("pages.settings.password.currentPassword")}
          onFocus={handleFocus("currentPassword")}
        />
        <TextInput
          type="password"
          error={state.errors.newPassword}
          className={classes.input}
          value={state.newPassword}
          onChange={handleChange("newPassword")}
          caption={getText("pages.settings.password.newPassword")}
          onFocus={handleFocus("newPassword")}
        />
        <TextInput
          type="password"
          error={state.errors.confirmPassword}
          className={classes.input}
          value={state.confirmPassword}
          onChange={handleChange("confirmPassword")}
          caption={getText("pages.settings.password.confirmPassword")}
          onFocus={handleFocus("confirmPassword")}
        />
        <Box mt={3} display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
          <Button
            type="submit"
            onClick={onSubmit}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {getText("pages.settings.password.save")}
          </Button>
          <Button onClick={clearFields} className={classes.button} size="large" color="secondary" variant="contained">
            {getText("pages.settings.password.cancel")}
          </Button>
        </Box>
      </form>
    </PopupPaper>
  );
};
export default ChangePassword;

const useStylesPopup = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  dialog: {
    padding: "40px",
    width: "45%",
    borderRadius: 4,
  },
  dialogTitle: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    "& h2": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
  input: {
    minHeight: 96,
  },
  button: {
    width: 254,
    padding: "12px 0",
  },
}));

const PasswordPopup = ({ onClose, open, ...other }) => {
  const classes = useStylesPopup();
  const getText = useLanguage();

  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth open={open} onClose={onClose} {...other}>
      <DialogTitle className={classes.dialogTitle}>{getText("pages.settings.password.messages.success")}</DialogTitle>
      <Box mt={3} display="flex" justifyContent="center">
        <Button onClick={onClose} className={classes.button} size="small" color="primary" variant="contained">
          {getText("pages.settings.password.close")}
        </Button>
      </Box>
    </Dialog>
  );
};

//  для валидации полей по отдельности (новый/подтвержденный пароль)
/*
useMemo(() => {
     if (state.currentPassword.length) {
         const errors = validate(state, loginConstraints);
         if (!errors) {
             setState({...state, errors: {}});
         } else {
             NotificationManager.error(getText('pages.settings.password.messages.errorFormat'))
             setState({...state, errors: {currentPassword: errors.currentPassword} || {}})
         }
     }
 }, [state.currentPassword]);
useMemo(() => {
     if (state.newPassword.length) {
         const errors = validate(state, loginConstraints);
         if (!errors) {
             setState({...state, errors: {}});
         } else {
            // NotificationManager.error(getText('pages.settings.password.messages.errorFormat'))
             setState({...state, errors: {newPassword: errors.newPassword} || {}})
         }
     }
 }, [state.newPassword]);

 useMemo(() => {
     if (state.confirmPassword.length) {
         const errors = validate(state, loginConstraints);
         if (!errors) {
             setState({...state, errors: {}});
         } else {
             NotificationManager.error(getText('pages.settings.password.messages.errorFormat'))
             setState({...state, errors: {confirmPassword: errors.confirmPassword} || {}})
         }
     }
 }, [state.confirmPassword]); */

//  валидация полей всех сразу при изменинии стейта

/*
useMemo(() => {
    if(state.currentPassword.length || state.newPassword.length || state.confirmPassword.length){
        const errors = validate(state, loginConstraints);
        if (!errors) {
            setState({...state, errors: {}});
        } else {
            NotificationManager.error(getText('pages.settings.password.messages.errorFormat'))
            setState({ ...state, errors: errors || {} })
        }
    }
}, [ state.currentPassword, state.newPassword, state.confirmPassword]); */
