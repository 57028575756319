import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import PopupPaper from "../../../../components/PopupPaper";
import useLanguage from "../../../../languages";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "39px 110px 56px",
  },
  dialogTitle: {
    padding: "16px 0",
    textAlign: "center",
    "& h2": {
      fontSize: 24,
      fontWeight: 600,
    },
    sandClockContainer: {
      paddingTop: "75%",
      position: "relative",
    },
    sandClock: {
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
}));

export const ApplicantSubmit = () => {
  const classes = useStyles();
  const getText = useLanguage();

  return (
    <PopupPaper style={{ margin: "50px auto 0 auto" }} popup>
      <div className={classes.sandClockContainer}>
        <iframe
          src="https://gifer.com/embed/fzTS"
          width="100%"
          height="100%"
          className={classes.sandClock}
          frameBorder="0"
          allowFullScreen
          title="sandClock"
        />
      </div>
      <Typography variant="h4" className={classes.dialogTitle}>
        Thank you!
      </Typography>
      <Typography variant="body1" align="justify">
        {getText("sumsub.emailInsructions")}
      </Typography>
    </PopupPaper>
  );
};
