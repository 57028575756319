import React, { useState, useRef, useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Box, Grid, Chip, Typography, Button, Hidden, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { NavLink, Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/styles";
import { NotificationManager } from "react-notifications";
import { useStore } from "../../store";
import urls, { useAsyncEndpoint } from "../../urls";
import PopupPaper from "../../components/PopupPaper";
import useLanguage from "../../languages";
import DeleteDialog from "../../components/DeleteDialog";
import { REACT_APP_API_URL } from "../../constants/selectConstants";
import SecondaryKnowMorePopup from "../../components/SecondaryKnowMorePopup";
import Empty from "../../components/Empty";

const ROUND_TYPES = {
  1: "DEBT",
  2: "EQUITY",
  3: "SAFE",
  4: "WARRANT",
  5: "CASH",
};

const Helper = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    height: 16,
    width: 16,
    borderColor: "rgba(126, 159, 193, .5)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    padding: 0,
    color: theme.palette.text.secondary,
    background: "white !important",
  },
  label: {
    fontSize: 10,
    lineHeight: 1,
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "0 56px",
    },
  },
  link: {
    color: "rgb(7, 109, 246)",
  },
  contact: { padding: "12px 0" },
  gridContainer: {
    maxWidth: 1600,
    justifyContent: "center",
  },
  gridItem: {
    width: "100%",
    "& > div": {
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        padding: "40px 35px",
      },
    },
  },
  gridItemExpanded: {
    zIndex: 2,
    "&:hover": {
      "& .expanded-container": {
        maxHeight: 500,
      },
    },
  },
  logo: {
    width: "100%",
    objectFit: "scale-down",
    height: 125,
  },
  evaluationTruncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // "& > div": {
    //   overflow: "hidden",
    //   "& > div": {
    //     overflow: "hidden",
    //     textOverflow: "ellipsis",
    //   },
    // },
  },
  descriptionContainer: {
    [theme.breakpoints.up("sm")]: {
      minHeight: 255,
      maxHeight: 300, // no height plz
      transition: "all .5s",
      position: "relative",
    },
  },
  description: {
    whiteSpace: "pre-line",
    marginBottom: "auto",
  },
  teaser: {
    zIndex: 2,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  dialog: {
    padding: "40px",
  },
  dialogTitle: {
    padding: 0,
    "& h2": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
  input: {
    minHeight: 96,
  },
  button: {
    width: 254,
    padding: "12px 0",
  },
  ModalMessage: {
    marginTop: 300,
    boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
    borderRadius: "4px",
    padding: "3rem",
  },
  buttonModal: {
    width: "30%",
    padding: "12px 0",
    top: "17%",
    left: "35%",
  },
  text: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "1px solid #000",
    color: "#ffffff",
  },
}));

const BlueChip = withStyles((theme) => ({
  root: {
    background: theme.palette.text.secondary,
    color: "#FFFFFF",
    maxWidth: "100%",
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "8px 6px",
    marginBottom: 14,
  },
  label: {
    display: "block",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}))(Chip);

const useRounds = () =>
  useAsyncEndpoint((id) => ({
    url: urls.rounds(id),
    method: "GET",
  }));

function kFormatter(num, m, k, prefix) {
  return Math.abs(num) > 999999
    ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + m
    : Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + k
    : Math.sign(num) * Math.abs(num);
}

export default observer(({ location, closed, secondary, onClose }) => {
  const classes = useStyles();
  const getText = useLanguage();
  const [rounds, fetchRounds] = useRounds();
  const [width, setWidth] = useState(window.innerWidth);
  const latestZ = useRef();
  const store = useStore();
  const [openInvest, openInvestDialog] = useState("");
  const [openTaxDocs, openTaxDialog] = useState(false);
  const [openId, setOpenId] = useState("");
  const [openInstance, setOpenInstance] = useState("");
  const [taxFiles, setTaxFiles] = useState([]); 

  const handleInvest = async (bool, id, textValue) => {
    if (!bool) {
      openInvestDialog(bool);
    } else if (textValue < 20000) {
      NotificationManager.error(getText("messages.amountExceed"));
    } else {
      // const data = { investment_round: id, investment_amout: textValue };
      // const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_non_us_booklet/`, {
      //   method: "POST",
      //   body: JSON.stringify(data),
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${localStorage.getItem("access")}`,
      //   },
      // });
      const data1 = { investment_round_instance_id: id, deal_amount: textValue };

      const isCurrentInstance = window.location.host.includes(openInstance);

      console.log('is curr instance', isCurrentInstance)

      const byInstance = !isCurrentInstance ? 'by_instance/' : ''

      if (!isCurrentInstance) {
        data1.instance = openInstance
      }

      const result1 = await fetch(`${REACT_APP_API_URL}/api/v1/deals/application/${byInstance}`, {
        method: "POST",
        body: JSON.stringify(data1),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }).then((response) => {
        if (response.status === 400) {
          NotificationManager.error(getText("messages.emptyInvest"));
        } else {
          openInvestDialog(false);
        }
        if (response.status === 200) {
          response.json().then((data) => {
            NotificationManager.success(getText("messages.completeInvest", store.profile.email));
          });
        }
      });
    }
  };

  const handleTax = async (bool, id, inputValue) => {
    if (!bool) {
      openTaxDialog(bool);

      return
    }

    try {
      await fetch(`${REACT_APP_API_URL}/api/v1/investors/tax_forms_pdf/`, {
        headers: {
          Authorization: `Bearer ${localStorage.access}`
        },
        method: 'POST',
        body: inputValue
      })

      NotificationManager.success(getText("messages.filesUploadSuccess"))
    } catch (error) {
      NotificationManager.error("An API error has occurred")
    }

    openTaxDialog(false)
  };

  const investorData = {
    is_member: store.profile.is_member,
    is_kyc_checked: store.profile.is_kyc_checked,
    is_accredited: store.profile.is_accredited,
    is_application_allowed: store.profile.is_application_allowed,
    is_income_checked: store.profile.is_income_checked,
    has_us_profile: store.profile.has_us_profile,
    has_bo_profile: store.profile.has_bo_profile,
    us_onboarded: store.profile.is_member && store.profile.is_kyc_checked && store.profile.is_accredited
  };

  const handleOpen = useCallback((id, instance) => {
    // await fetch(`${REACT_APP_API_URL}/api/v1/investors/tax_forms/`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("access")}`,
    //   },
    // });

    // if (investorData.is_income_checked) {
    openInvestDialog(true);
    setOpenId(id);
    setOpenInstance(instance);
    // } else {
    //   openTaxDialog(true)
    //   setOpenId(id)
    // }
  }, []);

  const expand = (event) => {
    const el = event.target.parentNode.parentNode.parentNode;
    if (latestZ.current) latestZ.current.classList.remove(classes.gridItemExpanded);

    latestZ.current = el;
    el.classList.add(classes.gridItemExpanded);
  };

  useEffect(() => {
    fetchRounds(closed ? "closed" : "ideas");

    fetch(`${REACT_APP_API_URL}/api/v1/investors/tax_forms_pdf/`, {
      headers: {
        Authorization: `Bearer ${localStorage.access}`
      }
    })
      .then((res) => res.json())
      .then((items) => setTaxFiles(items))
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const modalWindow = () => {
    let text = "";
    let href = "";
    if (!investorData.is_kyc_checked) {
      text = getText("pages.investments.modalText.kyc_signed");
      href = "/settings/profile";
    }
    if (investorData.is_kyc_checked && !investorData.is_accredited) {
      text = getText("pages.investments.modalText.accredited");
    }
    if (investorData.is_kyc_checked && investorData.is_accredited) {
      text = getText("pages.investments.modalText.accredited");
    }
    if (investorData.is_kyc_checked && investorData.is_accredited) {
      text = getText("pages.investments.modalText.moderate");
    }
    return <ModalText text={text} href={href} />;
  };

  const Valuation = ({ round }) => {
    let value = "";
    let pre = false;
    if (round.pre_money_evaluation && round.post_money_evaluation) {
      const currency = round.pre_money_evaluation_currency || "";
      pre = true;
      value = `${currency} ${kFormatter(round.pre_money_evaluation, getText("numbers.million"), getText("numbers.thousand"))}`;
    } else {
      const currency = round.pre_money_evaluation
        ? round.pre_money_evaluation_currency
        : round.post_money_evaluation_currency || "";
      let simbolCurrency = "";
      switch (currency) {
        case "USD":
          simbolCurrency = "$";
          break;
        case "EUR":
          simbolCurrency = "€";
          break;
        case "GBP":
          simbolCurrency = "£";
          break;
        default:
          simbolCurrency = currency;
      }
      pre = !!round.pre_money_evaluation;
      value = round.pre_money_evaluation
        ? `${simbolCurrency} ${kFormatter(round.pre_money_evaluation, getText("numbers.million"), getText("numbers.thousand"))}`
        : `${simbolCurrency} ${kFormatter(round.post_money_evaluation, getText("numbers.million"), getText("numbers.thousand"))}`;
    }

    if (!round.pre_money_evaluation && !round.post_money_evaluation) value = null;

    return (
      <Box fontSize={18} lineHeight="24px">
        <Typography variant="inherit">
          <strong>
            {value || (
              <>
                ‒&nbsp;
                <Tooltip placement="right" title={getText("tips.noValuation")}>
                  <Helper disableRipple>?</Helper>
                </Tooltip>
              </>
            )}
            {value && <span style={{ fontWeight: 400 }}> {pre ? "pre" : "post"}</span>}
          </strong>
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Hidden smUp>
        <Box mt={32} />
      </Hidden>
      <Box mt={2} className={classes.container}>
        <Grid container spacing={3} className={classes.gridContainer}>
          {investorData.is_member ? (
            rounds.data && rounds.data.length &&
            rounds.data
              .filter((round) =>
                (round.instance_type === 'bo' && investorData.has_bo_profile) ||
                (round.instance_type === 'us' && investorData.us_onboarded)
              ).length > 0 ?
              (rounds.data
                .filter((round) =>
                  (round.instance_type === 'bo' && investorData.has_bo_profile) ||
                  (round.instance_type === 'us' && investorData.us_onboarded)
                )
                .map((round, index) => (
                <Grid key={round.id} item sm={4} className={classes.gridItem}>
                  <PopupPaper wide>
                    <Box textAlign="center">
                      <Box mb={5}>
                        <a href={round.startup.site}>
                          <img alt="" className={classes.logo} src={round.startup.logo} />
                        </a>
                      </Box>
                      {width < (round.round_level && round.round_level.length * 51) ? (
                        <Tooltip placement="top" title={round.round_level}>
                          <BlueChip chip="chip" id={round.id} label={round.round_level || "..."} />
                        </Tooltip>
                      ) : (
                        <BlueChip chip="chip" id={round.id} label={round.round_level || "..."} />
                      )}
                    </Box>
                    <Box mt={3.5} display="flex" className={classes.evaluationTruncate}>
                      <Box mr={3.75}>
                        <Box fontSize={12}>
                          <Typography color="textSecondary" variant="inherit">
                            {getText("roundType")}
                          </Typography>
                        </Box>
                        <Box fontSize={18}>
                          {round.round_type && (
                            <Typography variant="inherit">
                              <strong>{ROUND_TYPES[round.round_type]}</strong>
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        <Box fontSize={12}>
                          <Typography color="textSecondary" variant="inherit">
                            {getText("valuation")}
                          </Typography>
                        </Box>
                        <Valuation round={round} />
                      </Box>
                      {round.handling_commission_amount && (
                        <Box>
                          <Box fontSize={12} ml={3}>
                            <Typography color="textSecondary" variant="inherit">
                              {getText("commissionAmount")}
                            </Typography>
                          </Box>
                          <Box ml={3}>
                            <Typography>
                              <strong>{round.commission_percentage}%</strong>
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box fontSize={18} mt={4.125}>
                      <Typography variant="inherit">
                        <strong>{round.startup.name}</strong>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      className={clsx(classes.descriptionContainer, "expanded-container")}
                    >
                      <Box onMouseEnter={expand} className={classes.description} fontSize={12} mt={1.375} mb={3}>
                        <Typography variant="inherit">{round.description_en || round.startup.description_en}</Typography>
                      </Box>
                      {!secondary ? (
                        <>
                          {location.pathname.includes("active") && (
                            <Box mt={2} style={{ margin: "auto 0 0 0" }}>
                              <Button
                                onClick={() => handleOpen(round.round_id, round.instance_type)}
                                fullWidth
                                size="large"
                                variant="contained"
                                color="secondary"
                              >
                                {getText("invest")}
                              </Button>
                            </Box>
                          )}
                          {round.show_teaser && round.teaser_en && !location.pathname.includes("closed") && (
                            <Box mt={2}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textAlign: "center" }}
                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge MuiButton-fullWidth"
                                href={round.teaser_en}
                                download
                              >
                                {getText("downloadTizer")}
                              </a>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box mt={2} style={{ margin: "auto 0 0 0" }}>
                          <Button onClick={() => handleOpen(round)} fullWidth size="large" variant="contained" color="secondary">
                            {getText("pages.secondary.buy")}
                          </Button>
                        </Box>
                      )}
                    </Box>
                    <Box mt={2} />
                  </PopupPaper>
                </Grid>
                ))
            ) : (
              <Empty text={getText("anotherDashboard")} />
            )
          ) : (
            <>{modalWindow()}</>
          )}
        </Grid>
        <DeleteDialog
          onClose={handleInvest} title={getText("investIn")}
          id={openId} open={openInvest} dialogType="invest" send
        />

        <DeleteDialog
          onClose={handleTax} title={getText("taxDocsTitle")}
          id={openId} open={openTaxDocs} dialogType="tax" send
          items={taxFiles}
        />

        {/* <SecondaryKnowMorePopup
          onClose={handleInvest}
          title={getText("pages.secondary.saleStocks")}
          open={openInvest}
          modalStartup={openId}
          buy={secondary}
        /> */}
      </Box>
    </>
  );
});

const ModalText = ({ text, href }) => {
  const classes = useStyles();
  return (
    <div className={classes.ModalMessage}>
      <p className={classes.text}>{text}</p>
      {href && (
        <NavLink component={Button} to={href} className={classes.buttonModal} size="large" color="primary" variant="contained">
          Ok
        </NavLink>
      )}
    </div>
  );
};
