import moment from "moment";

export const TEXT = "pages.notifications.search.text";
export const DATE = "pages.notifications.search.date";
export const TYPE = "pages.notifications.search.type";
export const STARTUP = "pages.notifications.search.startup";
export const CONTRACT = "pages.notifications.search.contract";
export const ATTACHMENT = "pages.notifications.search.attach";
export const WITH_ATTACHMENT = "pages.notifications.search.withAttach";
export const WITHOUT_ATTACHMENT = "pages.notifications.search.withoutAttach";

export const getSelectOptions = (getText) => [
  getText("pages.notifications.search.text"),
  getText("pages.notifications.search.date"),
  getText("pages.notifications.search.type"),
  getText("pages.notifications.search.startup"),
  getText("pages.notifications.search.contract"),
  getText("pages.notifications.search.attach"),
  getText("pages.notifications.search.withAttach"),
  getText("pages.notifications.search.withAttach"),
  getText("pages.notifications.search.withoutAttach"),
];

export const handleChangeCompanyType =
  (prop, index, companyType, setType) => (event) => {
    const newArray = companyType.slice(index, index + 1);
    newArray[0][prop] = event.target.value;
    const removed = companyType.splice(index, 1, ...newArray);
  };

export const handleChangeCompanyAutoCompleteType =
  (prop, index, companyType, setType) => (text, event) => {
    const newArray = companyType.slice(index, index + 1);
    newArray[0][prop] = event;
    const removed = companyType.splice(index, 1, ...newArray);
    setType([...companyType]);
  };

export const handleChangeCompanyTypeDate =
  (prop, index, companyType, setType) => (event) => {
    const momentProp = moment(event._d).format("YYYY-MM-DD");

    const newArray = companyType.slice(index, index + 1);
    newArray[0][prop] = momentProp;
    const removed = companyType.splice(index, 1, ...newArray);
    setType([...companyType]);
  };

export const handleChangeCompanySelectType =
  (prop, index, companyType, setType) => (text, array) => {
    const newArray = companyType.slice(index, index + 1);
    newArray[0][prop] = array;
    const removed = companyType.splice(index, 1, ...newArray);
    setType([...companyType]);
  };

export const handleChangeCompanyCheckedType =
  (prop, index, companyType, setType) => (text, event) => {
    const newArray = companyType.slice(index, index + 1);
    newArray[0][prop] = event ? "YES" : "NO";
    const removed = companyType.splice(index, 1, ...newArray);
    setType([...companyType]);
  };

export const handleChangeRadioCompanyType =
  (prop, index, companyType, setType) => (event) => {
    const newArray = companyType.slice(index, index + 1);
    newArray[0][prop] = event.target.value;
    const removed = companyType.splice(index, 1, ...newArray);
    setType([...companyType]);
  };
export const REACT_APP_API_URL = "https://ac2.altaclub.vc";

/* const selectOptions = useMemo(() => {
        getSelectOptions(getText)
    },[getText]); */

// const handleChangeChecked = (prop) => (event) => {
//   setProfile({ ...profile, [prop]: event.target.checked ? "YES" : "" });
//   profile.prop && store.setError(false);
// };
