import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  Box, Typography, Accordion,
  AccordionSummary, AccordionDetails,
  Button, Grid, RadioGroup,
  Radio, FormControlLabel
} from "@material-ui/core";
import { toJS } from "mobx";
import { isArray } from "lodash";
import uuid from "react-uuid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory, Link as RouterLink } from "react-router-dom";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { useStore } from "../../store";
import PopupPaper from "../../components/PopupPaper";
import { useStyles } from "./styles";
import { useNews, useMoreNews, useStartupOptions } from "../../api/api";
import { NewsPopupPaper } from "./Components/NewsPopupPaper";
import SexyLink from "../../components/SexyLink";
import TextInput from "../../components/TextInput";
import { Autocomplete } from "../../components/Autocomplete";
// import registerFcm from "../../utils/fcm";

const ReportNews = ({ newsItem }) => {
  const classes = useStyles();
  const getFormattedDate = (dateString) => new Date(dateString).toLocaleDateString('en-US')

  return (
      <PopupPaper wide className={classes.newsContainer}>
        {newsItem.news_preview &&
          <div className={classes.newsImageContainer}>
            <img src={newsItem.news_preview} alt="news" className={classes.newsImage} />
          </div>
        }
          <div className={classes.newsTextContainer}>
          <Typography variant="h6">
            {newsItem.title}
          </Typography>

          <div className={classes.newsDescription}>{newsItem.text}</div>

          <Box display="flex" justifyContent="flex-end">
            <span>
              {getFormattedDate(newsItem.publish_date)}
            </span>
          </Box>
        </div>
    </PopupPaper>
  )
}

export default observer(({ page = 1, newsType = "all", startDate, endDate, startup = "showAll" }) => {
  const store = useStore();
  const classes = useStyles();
  const history = useHistory();

  // utils
  const stringifyDate = (value) => value.format('YYYY-MM-DD')

  const todayDate = () => {
    const today = new Date()
    const todayString = today.toLocaleDateString('ru-RU')
    const todayParts = todayString.split('.')
    const [day, month, year] = todayParts

    return `${year}-${month}-${day}`
  }

  // state
  const [isLoaded, setIsLoaded] = useState(false);

  const [news, fetchNews] = useNews();
  const [profileNews, setNews] = useState([store.news]);

  const [moreNews, fetchMoreNews] = useMoreNews();
  const [profileMoreNews, setMoreNews] = useState([store.moreNews]);

  const [expanded, setExpanded] = useState(false);

  const [filterSet, setFilterset] = useState({
    start_date: startDate,
    end_date: endDate || todayDate(),
    startup, news_type: newsType,
    page
  })

  const [startupOptions, fetchStartupOptions] = useStartupOptions();
  const [optionsList, setOptionsList] = useState([store.startupOptions]);

  const buildFilterParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)

    for (const param of Object.keys(filterSet)) {
      if (filterSet[param] && filterSet[param] !== 'showAll') {
        urlSearchParams.set(param, filterSet[param])
      } else {
        urlSearchParams.delete(param)
      }
    }

    return urlSearchParams.toString()
  }

  // Массив с новостями, которые мы дозагружаем
  const filteredProfileMoreNews = useMemo(
    () => !isArray(profileMoreNews) && profileMoreNews.results.filter((f, index) => index > 2),
    [profileMoreNews]
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const downloadMoreNews = (startup) => {
    fetchMoreNews(startup);
  };

  const loadNews = () => {
    const urlParams = buildFilterParams()
    fetchNews(urlParams);
    fetchStartupOptions();
  }

  useEffect(() => loadNews(), [])

  // регистрируем девайс в файрбейзе
  // useEffect(() => registerFcm(), [])

  useEffect(() => {
    const urlParams = buildFilterParams()
    const { pathname } = window.location

    if (isLoaded) {
      history.push(`${pathname}?${urlParams}`)
    }

    setIsLoaded(false)
  }, [filterSet]);

  useEffect(() => {
    if (startupOptions.complete && startupOptions.data) {
      setOptionsList(startupOptions.data)
    }
  }, [startupOptions])

  useEffect(() => {
    if (news.complete) {
      store.setNews(news.data);
    }
  }, [news]);

  useEffect(() => {
    const data = toJS(store.moreNews);
    setMoreNews(data);
  }, [store.moreNews]);

  useEffect(() => {
    if (moreNews.complete) {
      store.setMoreNews(moreNews.data);
    }
  }, [moreNews]);

  useEffect(() => {
    if (news.complete) {
      const newsData = toJS(store.news);

      setNews(newsData)
      setIsLoaded(true)
    }
  }, [store.news]);

  useEffect(() => {
    expanded ? downloadMoreNews(expanded) : null;
  }, [expanded]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filterSet.page]);

  const setFilterValue = (key, value) => {
    const PARAMS_DEPENDENCIES = {
      page: {
        dependencies: ['startup', 'start_date', 'end_date', 'news_type'],
        defaultValue: 1
      }
    }

    for (const param of Object.keys(PARAMS_DEPENDENCIES)) {
      if (PARAMS_DEPENDENCIES[param] && PARAMS_DEPENDENCIES[param].dependencies.includes(key)) {
        setFilterset({
          ...filterSet,
          [param]: PARAMS_DEPENDENCIES[param].defaultValue,
          [key]: value
        })
      } else {
        setFilterset({ ...filterSet, [key]: value })
      }
    }
  }

  return (
    <>
      <PopupPaper className={classes.popup} inline>
        <button id="sendRegister" type="button" style={{display: 'none'}}>sendRegister</button>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextInput
              date
              caption="Start date"
              value={filterSet.start_date || null}
              onChange={(value) => setFilterValue("start_date", stringifyDate(value))}
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              date
              caption="End date"
              value={filterSet.end_date || null}
              onChange={(value) => setFilterValue("end_date", stringifyDate(value))}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              notflag
              showAll
              options={optionsList.map((label) => ({ label, value: label }))}
              caption="Choose startup"
              value={filterSet.startup || "showAll"}
              onChange={({ target }) => setFilterValue("startup", target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <RadioGroup
              className={classes.radioGroupTabs}
              onChange={({ target }) => setFilterValue("news_type", target.value)}
              defaultValue={filterSet.news_type}
            >
              <FormControlLabel
                value="all" control={<Radio color="primary" />}
                label="All" data-checked={filterSet.news_type === "all"}
                className={classes.radioGroupTabItem}
              />
              <FormControlLabel
                value="news" control={<Radio color="primary" />}
                label="News" data-checked={filterSet.news_type === "news"}
                className={classes.radioGroupTabItem}
              />
              <FormControlLabel
                value="article" control={<Radio color="primary" />}
                label="Articles" data-checked={filterSet.news_type === "article"}
                className={classes.radioGroupTabItem}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </PopupPaper>
      <Box mt={2} />
      <PopupPaper className={classes.popup} inline>
        <Box>
          {filterSet.startup && filterSet.startup !== 'showAll' &&
            <Box mb={5}>
              <SexyLink component={RouterLink} to='/news?page=1'>
                <Typography variant="h5">
                  &lt; Read all news
                </Typography>
              </SexyLink>
            </Box>
          }

          {!isArray(profileNews) &&
            profileNews.results.map((paperObj, index) => (
              <Box mt={2} key={uuid()}>
                <Typography variant="h5" align="left">
                  {paperObj.startup || ""}

                  {(!(paperObj.news && paperObj.news.length > 0) && ["news"].includes(filterSet.news_type) ||
                  !(paperObj.articles && paperObj.articles.length > 0) && ["article"].includes(filterSet.news_type)) &&
                    <small className={classes.noNewsText}>No news found...</small>
                  }
                </Typography>

                {(paperObj.news && paperObj.news.length > 0) && (["all", "news"].includes(filterSet.news_type)) &&
                  <>
                    <Box mt={2}>
                      <Typography variant="h6">
                        News:
                      </Typography>
                    </Box>

                    {paperObj.news.map((newsItem) => (
                      <ReportNews newsItem={newsItem} />
                    ))}
                  </>
                }
                
                {(paperObj.articles && paperObj.articles.length > 0) && (["all", "article"].includes(filterSet.news_type)) &&
                  <>
                    <Box mt={2}>
                      <Typography variant="h6">
                        Articles:
                      </Typography>
                    </Box>

                    {paperObj.articles.map((paper, index) => (
                      <NewsPopupPaper paper={paper} key={index} />
                    ))}

                    {paperObj.articles.length < paperObj.total_news_count && (
                      <Box>
                        <Accordion expanded={expanded === paperObj.startup} onChange={handleChange(paperObj.startup)}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="load-more" id="load-more">
                            <Typography variant="h6">Show more</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box display="flex" flexDirection="column">
                              {!isArray(profileMoreNews) && filteredProfileMoreNews.map((paper) => <NewsPopupPaper paper={paper} />)}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                  </>
                }

                {!(paperObj.news && paperObj.news.length > 0) &&
                  !(paperObj.articles && paperObj.articles.length) &&
                  filterSet.news_type === 'all' &&
                  <Box mt={2}>
                    <Typography>No news found...</Typography>
                  </Box>
                }
              </Box>
          ))}

          {!window.location.search.includes('startup') &&
          !isArray(profileNews) && (
            <>
              <Pagination
                current={profileNews.links.current}
                total={profileNews.count}
                onChange={(page) => setFilterValue('page', page)}
                className={classes.pagination}
                pageSize={3}
                locale={{
                  prev_page: 'Previous page',
                  next_page: 'Next page',
                }}
              />
            </>
          )}
        </Box>
      </PopupPaper>
    </>
  );
});
