import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import useLanguage from "../languages";
import PopupPaper from "../components/PopupPaper";

const useStyles = makeStyles(() => ({}));

export default observer(() => {
    const styles = useStyles()
    const getText = useLanguage();

    return (
        <PopupPaper popup caption="Risk Factors">
            <ol>
                <li>{getText("pages.cabinet.isRisksApproved.investmentCompanies")}</li>
                <li>{getText("pages.cabinet.isRisksApproved.investmentPrivately")}</li>
            </ol>
        </PopupPaper>
    )
})
