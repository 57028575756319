import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useStore } from "../../../../store/index";
import PopupPaper from "../../../../components/PopupPaper";
import useLanguage from "../../../../languages";
import TextInput from "../../../../components/TextInput";
import { Autocomplete } from "../../../../components/Autocomplete";
// import { NewCompanyData } from "./companyForm/NewCompanyData";

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28 },
  button: {
    width: "100%",
    padding: "12px 0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
  under: {
    textDecorationLine: "underline",
  },
  pickerContainerElement: {
    width: "100%",
    marginTop: 28,
  },
}));

export default observer(({ handleChange, profile }) => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();
  const [numberCompany, setNumberCompany] = useState(1);
  const [companys, setCompanys] = useState([]);

  // const addCompany = () => {
  //     setNumberCompany(numberCompany + 1)
  //     setCompanys(companys.concat(addNewcompany()))
  // }

  // const deleteCompany = () => {
  //     setCompanys(companys.filter(i => i !== numberCompany))
  // }

  // const addNewcompany = () => {
  //     return (<NewCompanyData
  //         key={numberCompany}
  //         getText={getText}
  //         classes={classes}
  //         profile={profile}
  //         handleChange={handleChange}
  //         store={store}
  //         numberCompany={numberCompany}
  //         deleteCompany={deleteCompany}
  //         companys={companys}
  //         setCompanys={setCompanys}
  //     />)
  // }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box mt={2} />
      <PopupPaper inline>
        <Box mt={5}>
          <Typography variant="h5">
            <strong>{getText("Company data")}</strong>
          </Typography>
        </Box>
        {/* <Box style={{ marginTop: "20px" }}>
                    <Typography><strong>{getText('CompanyNumber')} № 1</strong></Typography>
                </Box> */}
        <TextInput
          type="text"
          className={classes.input}
          value={profile?.company_name}
          onChange={handleChange("company_name")}
          caption={getText("company_name")}
        />
        <TextInput
          type="text"
          className={classes.input}
          value={profile?.company_number}
          onChange={handleChange("company_number")}
          caption={getText("company_number")}
        />
        <Box id="company_country" mt={3}>
          <Autocomplete
            options={store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
            caption={getText("company_country")}
            value={profile?.company_country}
            onChange={handleChange("company_country")}
          />
        </Box>
        <TextInput
          type="text"
          className={classes.input}
          value={profile?.company_address}
          onChange={handleChange("company_address")}
          caption={getText("company_address")}
        />
        {/* <TextInput
                    type="number"
                    className={classes.input}
                    value={profile?.company_delegate_position}
                    onChange={handleChange('company_delegate_position')}
                    caption={getText('company_delegate_position')}
                /> */}
        {/* <Box style={{ marginTop: '40px' }}>
                    <Button
                        type="button" fullWidth size="large" onClick={addCompany}
                        variant="contained" color="primary" className={classes.button}
                    >{getText('add_company')}</Button>
                </Box> */}
      </PopupPaper>
    </div>
  );
});
