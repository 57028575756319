import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import PopupPaper from "../../../components/PopupPaper";
import useLanguage from "../../../languages";
import TextInput from "../../../components/TextInput";
import { useStore } from "../../../store";
import { REACT_APP_API_URL } from "../../../constants/selectConstants";

const fillIndividualProfile = async (data) => {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_individual_profile/`, {
    method: "POST",
    body: JSON.stringify({ email: `${data}` }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
};

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28 },
  button: {
    width: 190,
    padding: "12px 0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
  under: {
    textDecorationLine: "underline",
  },
}));

export default observer(({ history, location, handleChange, profile }) => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box mt={2} />
      <PopupPaper inline>
        <Box mt={5}>
          <Typography variant="h5">
            <strong>{getText("General information")}</strong>
          </Typography>
        </Box>
        {profile.individual_profile_has_error && (
          <>
            <Alert severity="warning">{profile.individual_profile_error}!</Alert>
            <Button
              type="button"
              onClick={fillIndividualProfile}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              style={{ marginBottom: 28 }}
            >
              {getText("fix")}
            </Button>
          </>
        )}
        <TextInput
          type="text"
          className={classes.input}
          value={profile?.first_name_en}
          onChange={handleChange("first_name_en")}
          caption={getText("name_en")}
        />
        <TextInput
          type="text"
          className={classes.input}
          value={profile?.last_name_en}
          onChange={handleChange("last_name_en")}
          caption={getText("surname_en")}
        />
        <TextInput
          type="number"
          className={classes.input}
          value={profile?.phone}
          onChange={handleChange("phone")}
          caption={getText("phone")}
        />
        <TextInput
          type="email"
          className={classes.input}
          value={profile?.email}
          onChange={handleChange("email")}
          caption={getText("email")}
        />
        <TextInput
          type="number"
          className={classes.input}
          value={profile?.telegram}
          onChange={handleChange("telegram")}
          caption={getText("telegram")}
        />
      </PopupPaper>
    </div>
  );
});
