import React, { useEffect } from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import useLanguage from "../../../languages";
import PopupPaper from "../../../components/PopupPaper";
import { useStore } from "../../../store";

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    justifyContent: "center",
  },
  input: { marginTop: 24 },
  button: {
    width: 190,
    padding: "12px 0",
  },
  link: {
    color: "rgb(7, 109, 246)",
  },
  font: {
    fontSize: 14,
    lineHeight: 1.7,
  },
});

export const FinishPage = observer(({ history }) => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();

  useEffect(() => {
    if (store.language === "ru") {
      history.push("?language=ru");
    } else {
      history.push("?language=en");
    }
  }, [store.language]);

  const handleClick = () => history.push("/login");

  return (
    <PopupPaper popup caption={getText("pages.signUp.finishPage.caption")}>
      <Box whiteSpace="pre-wrap" mt={2} className={classes.formContainer}>
        <div>{getText("pages.signUp.finishPage.subtitle")}</div>
      </Box>
      <div className={classes.font}>
        <Box whiteSpace="pre-wrap" textAlign="left">
          <subtitle1>
            {getText("pages.signUp.finishPage.text1")}
            <a className={classes.link} href="https://t.me/AltaClub">
              {getText("pages.signUp.finishPage.maintg")}
            </a>
            {getText("pages.signUp.finishPage.text2")}
          </subtitle1>
        </Box>
        <Box>
          <div>
            {getText("pages.signUp.finishPage.contact1.name")}{" "}
            <a className={classes.link} href="tel:+972547372845">
              {getText("pages.signUp.finishPage.contact1.phone")}{" "}
            </a>
            <a className={classes.link} href="https://t.me/ANovosartov">
              {getText("pages.signUp.finishPage.contact1.tg")}{" "}
            </a>
          </div>
          <div>
            {getText("pages.signUp.finishPage.contact2.name")}{" "}
            <a className={classes.link} href="tel:+79152988198">
              {getText("pages.signUp.finishPage.contact2.phone")}{" "}
            </a>
            <a className={classes.link} href="https://t.me/igor_taymurzin">
              {getText("pages.signUp.finishPage.contact2.tg")}{" "}
            </a>
          </div>
        </Box>
      </div>
      <Box mt={3} display="flex" justifyContent="center" style={{ marginTop: 50 }}>
        <Button onClick={handleClick} className={classes.button} size="large" color="secondary" variant="contained">
          {getText("pages.signUp.finishPage.login")}
        </Button>
      </Box>
    </PopupPaper>
  );
});
