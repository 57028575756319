import React from "react";
import { Button, Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import PopupPaper from "../../../components/PopupPaper";
import useLanguage from "../../../languages";
import { documentsState } from "./Documents";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      background: "#FFFFFF",
      display: "block",
    },
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
  },
  button: { margin: "25px 0" },
  under: {
    textDecoration: "underline",
  },
}));

export const SignUpType = () => {
  const classes = useStyles();
  const getText = useLanguage();

  const handleClick = (type) => {
    localStorage.setItem("userType", type);
  };

  return (
    <Container className={classes.root}>
      <PopupPaper popup>
        <Box display="flex" flexDirection="column" justifyContent="space-between" mt={2}>
          <Link to="/sign-up/country/individual">
            <Button
              type="submit"
              onClick={() => handleClick("Individual")}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Create Personal account
            </Button>
          </Link>
          <Link to="/sign-up/country/entity">
            <Button
              type="submit"
              onClick={() => handleClick("Entity")}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Create Entity account
            </Button>
          </Link>
        </Box>
        <Typography variant="caption" display="block" gutterBottom style={{ "text-align": "justify", color: "rgb(161 161 161)" }}>
          <span>By creating an account you agree to our </span>
          <a className={classes.under} href={getText("pages.signUp.linkPrivacy")} target="_blank" rel="noreferrer">
            {getText("pages.signUp.documents.privacyPolicy2")}
          </a>
          <span> and </span>
          <a className={classes.under} href={getText("pages.signUp.linkTerms")} target="_blank" rel="noreferrer">
            {getText("pages.signUp.documents.termsFirst3")}
          </a>
          <span> and the </span>
          <a className={classes.under} href={getText("pages.signUp.linkPersonalData")} target="_blank" rel="noreferrer">
            {getText("pages.signUp.documents.personalData2")}
          </a>
        </Typography>
      </PopupPaper>
    </Container>
  );
};
