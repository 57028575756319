import React, { useContext, createContext } from "react";
import { useLocalStore } from "mobx-react-lite";
import { createStore } from "./createStore";

const storeContext = createContext(null);

export const StoreProvider = ({ children }) => {
  const store = useLocalStore(createStore);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useStore = () => {
  const store = useContext(storeContext);
  return store;
};
