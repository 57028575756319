import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { NotificationManager } from "react-notifications";
import validate from "validate.js";
import { isUndefined } from "util";
import { useLocation } from "react-router-dom";
import TextInput from "../components/TextInput";
import useLanguage from "../languages";
import PopupPaper from "../components/PopupPaper";
import { useLostPassword } from "../api/api";

const useStyles = makeStyles({
  input: { marginTop: 24 },
  button: {
    width: 190,
    padding: "12px 0",
  },
  divider: {
    background: "#0d7bff",
    width: 4,
    height: 105,
    display: "flex",
    borderRadius: 4,
    marginBottom: 15,
  },
  containerText: {
    display: "flex",
    marginTop: 30,
  },
  containerDivider: {
    marginRight: 17,
    display: "flex",
    alignItems: "flex-end",
  },
  ul: {
    marginBottom: 30,
    marginLeft: -20,
    "& > li": { color: "#0d7bff", "& > span": { color: "#374464" } },
  },
});
// {color: '#374464'}

const initialState = {
  password: "",
  confirmPassword: "",
  errors: {},
  remember: true,
};

export default observer(({ history }) => {
  const getText = useLanguage();
  const desktop = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const [tokenParam, setTokenParam] = useState();

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");
    setTokenParam(tokenParam);
    checkToken({ token: tokenParam });
  }, []);

  const [token, checkToken] = useLostPassword();
  const [password, changePassword] = useLostPassword("POST");

  const [state, setState] = useState(initialState);

  const loginConstraints = {
    password: {
      presence: { allowEmpty: false, message: `^${getText("errors.passwordEmpty")}` },
      length: { minimum: 8, message: `^${getText("errors.passwordShort", 8)}` },
      format: {
        pattern: "^(?=\\S*[a-z])(?=\\S*[A-Z])(?=\\S*\\d)\\S*$",
        flags: "g",
        message: `^${getText("pages.settings.password.messages.validation.2")}`,
      },
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: `^${getText("errors.passwordEmpty")}` },
      equality: {
        attribute: "password",
        message: `^${getText("pages.settings.password.messages.validation.3")}`,
        comparator(v1, v2) {
          return v1 === v2;
        },
      },
    },
  };

  const handleChange = (prop, value, _clearError) => (event) => {
    if (_clearError) {
      setState({ ...state, [prop]: isUndefined(value) ? event.target.value : value, errors: {} });
    } else {
      setState({ ...state, [prop]: isUndefined(value) ? event.target.value : value });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const errors = validate(state, loginConstraints);

    if (!errors) {
      setState({ ...state, errors: {} });

      const { password, confirmPassword } = state;
      const data = {
        new_password: password,
        new_password2: confirmPassword,
      };
      changePassword({ data, token: tokenParam });
    } else {
      NotificationManager.error(getText("pages.settings.password.messages.errorFormat"));
      setState({ ...state, errors: errors || {} });
    }
  };

  useEffect(() => {
    if (token.complete && token.error) {
      NotificationManager.error(getText("pages.settings.password.messages.errorFormat"));
      history.push("/login");
    }
  }, [token]);

  useEffect(() => {
    if (password.complete) {
      if (!password.error) {
        NotificationManager.success(getText("pages.settings.password.messages.success"));
        history.push("/login");
      } else {
        NotificationManager.error(getText("messages.somethingWrong"));
      }
    }
  }, [password]);

  const clearFields = () => {
    setState({
      ...state,
      password: "",
      confirmPassword: "",
    });
    history.push("/login");
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <PopupPaper popup caption={getText("pages.restoration.caption")}>
        <Box mt={2} mb={4}>
          <Typography color="textSecondary" align={desktop ? "center" : "left"} variant="subtitle1">
            {getText("pages.restoration.enterPassword")}
          </Typography>
        </Box>
        <Typography variant="h7">
          <ul className={classes.ul}>
            <li>
              <span>{getText("pages.settings.password.rules.1")}</span>
            </li>
            <li>
              <span>{getText("pages.settings.password.rules.2")}</span>
            </li>
            <li>
              <span>{getText("pages.settings.password.rules.3")}</span>
            </li>
            <li>
              <span>{getText("pages.settings.password.rules.4")}</span>
            </li>
          </ul>
        </Typography>
        <form className={classes.formContainer}>
          <TextInput
            type="password"
            error={state.errors.password}
            className={classes.input}
            value={state.password}
            onChange={handleChange("password")}
            caption={getText("pages.settings.password.newPassword")}
          />
          <TextInput
            type="password"
            error={state.errors.confirmPassword}
            className={classes.input}
            value={state.confirmPassword}
            onChange={handleChange("confirmPassword")}
            caption={getText("pages.settings.password.confirmPassword")}
          />
          <Box mt={3} display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
            <Button
              type="submit"
              onClick={onSubmit}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {getText("pages.settings.password.save")}
            </Button>
            <Button onClick={clearFields} className={classes.button} size="large" color="secondary" variant="contained">
              {getText("pages.settings.password.cancel")}
            </Button>
          </Box>
        </form>
      </PopupPaper>
    </form>
  );
});
