import { observer } from "mobx-react-lite";
import filter from "lodash/filter";
import { Box, Button, Chip, Accordion, AccordionDetails, AccordionSummary, Hidden, Tooltip, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router-dom";
import DocumentsTable from "./DocumentsTable";
import useLanguage from "../../../languages";
import { useStore } from "../../../store";
import DocumentAddMember from "./DocumentAddMember";

const Helper = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    height: 16,
    width: 16,
    borderColor: "rgba(126, 159, 193, .5)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    padding: 0,
    color: theme.palette.text.secondary,
    background: "white !important",
  },
  label: {
    fontSize: 10,
    lineHeight: 1,
  },
}))(Button);

const BlueChip = withStyles((theme) => ({
  root: {
    background: theme.palette.text.secondary,
    color: "#FFFFFF",
    fontWeight: 400,
    textTransform: "uppercase",
    padding: "8px",
  },
}))(Chip);

const allowedPages = ["Upload", "UBO", "Director", "Shareholder"];

export const Panel = observer(
  ({
    disableExpansion = false,
    header,
    defaultOpen,
    documentsTypes,
    documents,
    profile,
    useStyles,
    border,
    documentsRequest,
    handleChangeDoc,
  }) => {
    const classes = useStyles();
    const store = useStore();
    const getText = useLanguage();
    const location = useLocation();
    const history = useHistory();

    const [successDocs, setSuccessDocs] = useState(0);
    const [uboModal, setUboModal] = useState(false);
    const [directorModal, setDirectorModal] = useState(false);
    const [shareholderModal, setShareholderModal] = useState(false);
    const [tab, setTab] = useState(0);
    const [expanded, setExpanded] = useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const progress = filter(documents, (d) => d.is_active && d.document_file).length;

    const setQueryParam = (key, value) => {
      const params = new URLSearchParams(location.search);
      params.set(key, value);
      history.push({
        search: params.toString(),
      });
    };

    const setPageTab = (value) => {
      setTab(value);
      setQueryParam("page", allowedPages[value]);
    };

    const getQueryParam = (key) => {
      const params = new URLSearchParams(location.search);
      return params.get(key);
    };

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const page = getQueryParam("page");
      if (!page || !allowedPages.includes(page)) {
        setQueryParam("page", allowedPages[0]);
      }
      setTab(allowedPages.indexOf(page));
    }, [tab]);

    useEffect(() => {
      if (documents && documents.length) {
        const filesNull = documents.filter((el) => el.document_file !== null);
        setSuccessDocs(filesNull.length);
      }
    }, [documents]);

    return (
      <>
        {profile.entity_type === "Legal" && (
          <>
            <Box display="flex" justifyContent="space-around" mb={2}>
              <a href="#" onClick={() => setPageTab(0)} style={{ color: tab === 0 ? "#2356B2" : "inherit" }}>
                {getText("documentMember.add-UBO")}
              </a>
              <a href="#" onClick={() => setPageTab(1)} style={{ color: tab === 1 ? "#2356B2" : "inherit" }}>
                {getText("documentMember.add-director")}
              </a>
              <a href="#" onClick={() => setPageTab(2)} style={{ color: tab === 2 ? "#2356B2" : "inherit" }}>
                {getText("documentMember.add-shareholder")}
              </a>
            </Box>
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    width: "100%",
                    color: "#7E9FC1",
                    padding: "8px 0",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>{getText("subjectType")}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {tab === 0 && (
                  <DocumentAddMember
                    profile={profile}
                    handleChangeDoc={handleChangeDoc}
                    modalOpen={uboModal}
                    setModalOpen={setUboModal}
                    subject="UBO"
                  />
                )}
                {tab === 1 && (
                  <DocumentAddMember
                    profile={profile}
                    handleChangeDoc={handleChangeDoc}
                    modalOpen={directorModal}
                    setModalOpen={setDirectorModal}
                    subject="director"
                  />
                )}
                {tab === 2 && (
                  <DocumentAddMember
                    profile={profile}
                    handleChangeDoc={handleChangeDoc}
                    modalOpen={shareholderModal}
                    setModalOpen={setShareholderModal}
                    subject="shareholder"
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}

        <div key={profile.id}>
          {documents ? (
            <>
              <Hidden xsDown>
                <Accordion
                  disabled={disableExpansion}
                  defaultExpanded={defaultOpen || progress < documents.length}
                  style={{
                    border: border ? "1px solid red" : "",
                    background: disableExpansion ? "white" : "",
                    boxShadow: "none",
                  }}
                >
                  {header && (
                    <AccordionSummary>
                      <Box fontSize={18} fontWeight={600} display="flex" width="100%" flexDirection="row" alignItems="center">
                        {profile.investor_legal_type !== 2 ? (
                          store.language === "ru" && profile.name_ru ? (
                            <Typography variant="inherit">
                              {profile.name_ru} {profile.surname}
                            </Typography>
                          ) : (
                            store.language === "en" && (
                              <Typography variant="inherit">
                                {profile.name_en} {profile.surname_en}
                              </Typography>
                            )
                          )
                        ) : store.language === "ru" && profile.name_ru ? (
                          <Typography variant="inherit">{profile.organization_name}</Typography>
                        ) : (
                          <Typography variant="inherit">{profile.organization_name_en}</Typography>
                        )}
                        <Hidden xsDown>
                          <Tooltip placement="right" title="Tooltip">
                            <Box ml={1} mt={-0.2}>
                              <Helper disableRipple>?</Helper>
                            </Box>
                          </Tooltip>
                        </Hidden>
                        <Box marginLeft="auto">
                          <BlueChip label={`${getText("downloaded")} ${successDocs}/${documents.length}`} />
                        </Box>
                      </Box>
                    </AccordionSummary>
                  )}
                  <AccordionDetails className={classes.expansionPanel}>
                    {documents && (
                      <DocumentsTable
                        documentsTypes={documentsTypes}
                        documents={documents}
                        useStyles={useStyles}
                        documentsRequest={documentsRequest}
                        handleChangeDoc={handleChangeDoc}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Hidden>
              <Hidden smUp>
                <Accordion defaultExpanded={defaultOpen || progress < documents.length}>
                  {header && (
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box fontSize={18} fontWeight={600} display="flex" width="100%" flexDirection="column">
                        {store.language === "ru" ? (
                          <Typography variant="inherit">
                            {profile.first_name} {profile.last_name}
                          </Typography>
                        ) : (
                          <Typography variant="inherit">
                            {profile.first_name_en} {profile.last_name_en}
                          </Typography>
                        )}
                        <Hidden xsDown>
                          <Tooltip placement="right" title="Tooltip">
                            <Box ml={1}>
                              <Helper disableRipple>?</Helper>
                            </Box>
                          </Tooltip>
                        </Hidden>
                        <Box mt={3}>
                          <BlueChip label={`${getText("downloaded")} ${successDocs}/${documents.length}`} />
                        </Box>
                      </Box>
                    </AccordionSummary>
                  )}
                  <AccordionDetails className={classes.expansionPanel}>
                    <Box pr={2.5}>
                      {documents ? (
                        <DocumentsTable
                          documentsTypes={documentsTypes}
                          documents={documents}
                          useStyles={useStyles}
                          documentsRequest={documentsRequest}
                          handleChangeDoc={handleChangeDoc}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Hidden>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
);
