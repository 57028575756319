import React, { useEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { Box, Button } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { useStore } from "../../store";
import urls, { useAsyncEndpoint } from "../../urls";
import { useStyles } from "./styles";
import { generatePdfReport } from '../../api/api'
import { REACT_APP_API_URL } from "../../constants/selectConstants";

const usePDF = () =>
  useAsyncEndpoint((instance, reportType, endDate, startDate, split) => ({
    url: urls.cachedReportByInstance(instance, reportType, endDate, startDate, split),
    method: "GET",
  }));

export default observer((params) => {
  const store = useStore();
  const classes = useStyles();

  const { instance, reportType, startDate, endDate, split } = params;

  const [pdf, fetchPDF] = usePDF();
  const [profilePDF, setPDF] = useState([...store.pdf]);

  useEffect(() => {
    fetchPDF(instance, reportType, endDate, startDate, split);
  }, []);

  useEffect(() => {
    const data = toJS(store.pdf);
    setPDF(data);
  }, [store.pdf]);

  useEffect(() => {
    if (pdf.complete) {
      store.setPDF(pdf.data);
    }
  }, [pdf]);

  const REPORT_MAPPING = {
    investor: 'quarter',
    ahs: 'ahs',
    transactional: 'transaction'
  }

  const downloadPdfDocument = async () => {
    const pdfResponse = await generatePdfReport(JSON.stringify({
      access_token: localStorage.access,
      refresh_token: localStorage.refresh,
      report_uri: `${REACT_APP_API_URL}${urls.cachedReportByInstance(instance, reportType, endDate, startDate, split)}`,
      report_type: REPORT_MAPPING[reportType]
    })).then((response) => response.json())

    NotificationManager.info('Report file generating...')

    const pdfInterval = setInterval(() => {
      const { link } = pdfResponse
      fetch(link).then((res) => {
        if (res.status === 200) {
          NotificationManager.info('Report file is generated now')

          window.clearInterval(pdfInterval)
          window.open(link, "_blank")
        }
      })
    }, 500)
  };

  const changeInstance = (targetInstance) => {
    window.location.href = `/pdfReport?instance=${targetInstance}&reportType=${reportType}&endDate=${endDate}&startDate=${startDate}&split=${split}`
  }

  const userHasOneInstance = () => {
    const { has_bo_profile, has_us_profile, has_ae_profile } = store.profile;

    const instanceArray = [has_bo_profile, has_us_profile, has_ae_profile];

    const hasOneInstance = instanceArray.filter((instance) => instance).length === 1;

    return hasOneInstance;
  };

  return (
    <div>
      {profilePDF.length > 0 && (
        <>
          <Box display="flex" flexDirection="row-reverse" width="100%">
            <Button
              type="button"
              onClick={downloadPdfDocument}
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Download Pdf
            </Button>
            {!userHasOneInstance() && <>
              {store.profile.has_us_profile ?
              <Button
                type="button"
                onClick={() => changeInstance('us')}
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={instance === 'us'}
              >
                US report
              </Button> : ''
              }
              {store.profile.has_ae_profile ?
                <Button
                  type="button"
                  onClick={() => changeInstance('ac2')}
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={instance === 'ac2'}
                >
                  AC II report
                </Button> : ''
              }
              {store.profile.has_bo_profile ?
                <Button
                  type="button"
                  onClick={() => changeInstance('bo')}
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={instance === 'bo'}
                >
                  BVI report
                </Button> : ''
              }
            </>}
          </Box>
          <div id="testId" className={classes.pageToPdf}>
            {ReactHtmlParser(profilePDF)}
          </div>
          <style>{`          
            @media print {
              header, .MuiToolbar-root, .MuiToolBar-root, .MuiButton-root {
                display: none !important;
                width: 0 !imporant;
                height: 0 !important;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1000;
              }

              @page {
                size: 210mm 297mm;
                margin: 1.5cm;
              }

              body {
                min-width: 210mm !important;
                padding: 1rem;
              }

              .container {
                min-width: 210mm !important;
              }

              .MuiBox-root {
                position: unset;
              }

              .${classes.pageToPdf} .container {
                position: absolute;
                width: 100% !important;
                margin: 0;
                padding: 0 !important;
                top: 0;
                left: 0;
                z-index: 1000;
                max-width: unset !important;
              }
            }
          `}
          </style>

        </>
      )}
    </div>
  );
});
