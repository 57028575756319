import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import useLanguage from "../languages";
import PopupPaper from "../components/PopupPaper";

const useStyles = makeStyles(() => ({}));

export default observer(() => {
    const styles = useStyles()
    const getText = useLanguage();

    return (
        <PopupPaper popup caption={getText("pages.signUp.documents.agreementCaption")}>
            <Box whiteSpace="pre-wrap" mt={3}>
                <h4>{getText("pages.signUp.documents.agreementP1")}</h4>
            </Box>
            <Box whiteSpace="pre-wrap" mt={3}>
                <h4>{getText("pages.signUp.documents.agreementP2")}</h4>
            </Box>
        </PopupPaper>
    )
})
