import React, { useState, useCallback, useEffect } from "react";
import { Button, Box, useMediaQuery, Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import validate from "validate.js";
import { isUndefined } from "util";
import { Link } from "react-router-dom";
import { useStore } from "../store";
import TextInput from "../components/TextInput";
import useLanguage from "../languages";
import SexyLink from "../components/SexyLink";
import PopupPaper from "../components/PopupPaper";
import { postCreditionals } from "../api/api";

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 24 },
  button: { marginTop: 40 },
  buttonLink: {
    color: "#2356B2",
  },
}));

const initialState = {
  email: "",
  password: "",
  errors: {},
  remember: true,
};

export default observer(({ location, history }) => {
  const classes = useStyles();
  const store = useStore();
  const getText = useLanguage();
  const desktop = useMediaQuery("(min-width:600px)");

  const [state, setState] = useState(initialState);
  const [token, login] = postCreditionals();

  const loginConstraints = {
    email: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.emailEmpty")}`,
      },
      email: { message: `^${getText("errors.emailInvalid")}` },
    },
    password: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.passwordEmpty")}`,
      },
      length: { minimum: 6, message: `^${getText("errors.passwordShort", 6)}` },
    },
  };

  useEffect(() => {
    if (!token.error && token.complete) {
      store.setToken(token.data, state.remember);
    }
  }, [token]);

  const getInputData = (key) => state[key] || document.querySelector(`[name="${key}"]`).value;

  const submit = (event) => {
    event.preventDefault();

    const authData = {
      email: getInputData("email").toLowerCase().trim(),
      password: getInputData("password"),
    };

    const errors = validate(authData, loginConstraints);

    const { email, password } = authData;

    if (!errors) {
      login({ email, password });
    }

    setState({ ...state, ...authData, errors: errors || {} });
  };

  const handleChange = (prop, value, _clearError) => (event) => {
    if (_clearError)
      setState({
        ...state,
        [prop]: isUndefined(value) ? event.target.value : value,
        errors: {},
      });
    else
      setState({
        ...state,
        [prop]: isUndefined(value) ? event.target.value : value,
      });
  };

  const inputEl = useCallback((node) => node && node.focus(), []);

  return (
    <PopupPaper popup caption="Authorization">
      <Box mt={2}>
        No account?{" "}
        <SexyLink to="/sign-up/type" color="textSecondary">
          Register now
        </SexyLink>
      </Box>
      <form onSubmit={submit}>
        <TextInput
          autoFocus
          type="email"
          InputProps={{ name: "email" }}
          error={token.error ? [token.data.detail] : state.errors.email}
          className={classes.input}
          onChange={handleChange("email")}
          value={state.email}
          inputRef={inputEl}
          caption={getText("email")}
        />
        <TextInput
          type="password"
          InputProps={{ name: "password" }}
          error={state.errors.password}
          className={classes.input}
          value={state.password}
          onChange={handleChange("password")}
          caption="Password"
        />
        <Button type="submit" fullWidth size="large" variant="contained" color="primary" className={classes.button}>
          Sign in
        </Button>
      </form>
      <Box display="flex" flexDirection={desktop ? "row" : "column"} justifyContent="space-between" mt={1}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.remember}
              onChange={(event) => handleChange("remember", event.target.checked)()}
              color="primary"
            />
          }
          label="Remember me"
        />
        {/* {getText("pages.login.remember")} */}
        <Box display="flex" alignItems="center">
          <SexyLink to="/login/restore" color="textSecondary">
            Forgot Password?
            {/* {getText("pages.login.forgotPassword")} */}
          </SexyLink>
        </Box>
      </Box>
    </PopupPaper>
  );
});
