import React from "react";
import { observer } from "mobx-react-lite";
import { DatePicker } from "@material-ui/pickers";
import {
  Box, FormControlLabel,
  Checkbox, FormLabel,
  Typography, RadioGroup,
  Radio, Tooltip,
  Hidden, Button
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/styles";
import useLanguage from "../../../languages";
import PopupPaper from "../../../components/PopupPaper";
import TextInput from "../../../components/TextInput";
import { useStore } from "../../../store";
import { AutocompleteMaterial } from "../../../components/AutocompleteMaterial";
import { SELECTS_OPTIONS_CABINET } from "../Cabinet/CabinetAutoComplete";
import MultiSelect from "../../../components/MultiSelect";

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28 },
  autoComplete: theme.autoComplete,
  button: {
    width: 190,
    padding: "12px 0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
  under: {
    textDecorationLine: "underline",
  },
  pickerContainerElement: {
    width: "100%",
  },
  datePickerLabel: {
    fontSize: 14,
    color: "#7E9FC1",
    marginTop: 28,
    display: "block",
  },
  radioGroup: {
    flexDirection: "row !important",
  },
  checkbox: {
    marginRight: 10,
  },
  checkboxError: {
    marginRight: 10,
    color: "#f36363",
  },
  checkboxTitle: {
    textAlign: "justify",
    fontSize: "13px",
  },
  checkboxTitleSmall: {
    fontSize: "12px !important",
  },
  labelInput: {
    "text-align": "justify",
    display: "flex",
  },
  linkText: {
    color: "#7E9FC1",
    textDecorationLine: "underline",
  },
  bottomLine: {
    border: "1px solid #DBE5EA",
    margin: "32px 0 24px",
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px 0px 12px",
    "&:last-child": {
      display: "block",
    },
  },
  paginationBlock: {
    display: "flex",
    "&:last-child > div": {
      display: "none",
    },
  },
  paginationItem: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    padding: "8px 12px",
    border: "1px solid #2356B2",
    borderRadius: "50%",
    background: "#FFFFFF",
    color: "#2356B2",
    transition: "all 0.3s",
    cursor: "pointer",
  },
  paginationItemCompleted: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    padding: "8px 12px",
    border: "1px solid #2356B2",
    borderRadius: "50%",
    background: "#2356B2",
    color: "#ffffff",
    transition: "all 0.3s",
  },
  paginationLineContainer: {
    display: "flex",
    alignItems: "center",
  },
  paginationLine: {
    width: "80px",
    height: "3px",
    background: "#2356B2",
    border: "none",
  },
  paginationTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationText: {
    fontSize: "12px",
    maxWidth: "61px",
    marginLeft: "47px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  fontSmall: {
    fontSize: "12px"
  }
}));

const Helper = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    height: 16,
    width: 16,
    borderColor: "rgba(126, 159, 193, .5)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    padding: 0,
    color: theme.palette.text.secondary,
    background: "white !important",
  },
  label: {
    fontSize: 10,
    lineHeight: 1,
  },
}))(Button);

export default observer(({
  history, location,
  handleChange, profile,
  handleChangeDate, handleChangeAutoComplete,
  handleChangeSelect
}) => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();

  const BLOCKED_COUNTRIES = ['RU', 'BY'] // РФ, Белоруссия
  const isBlockedCountry = (country) => BLOCKED_COUNTRIES.includes(country)

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box mt={2} />
      <PopupPaper inline>
        <Box mt={5}>
          <Typography variant="h5">
            <strong>{profile.entity_type === "Entity" ? getText("Company data") : getText("Personal data")}</strong>
          </Typography>
        </Box>

        {profile.entity_type === "Entity" && 
          <Box id="company">
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.company_name}
                onChange={handleChange("company_name")}
                caption={getText("pages.cabinet.companyName")}
                dataattribute="company_name"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <AutocompleteMaterial
                options={SELECTS_OPTIONS_CABINET.nonInterestOwnershipTypes.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                text="non_individual_interest_ownership"
                title="pages.cabinet.nonInterestOwnershipTypes.title"
                value={profile?.non_individual_interest_ownership}
                onChange={handleChangeAutoComplete}
                dataattribute="non_individual_interest_ownership"
                required InputProps={{required: true}}
              />
            </Box>
            {profile?.non_individual_interest_ownership === "TRUST" && (
              <>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={profile?.type_of_trust}
                    onChange={handleChange("type_of_trust")}
                    caption={getText("pages.cabinet.typeOfTrust")}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={profile?.name_of_all_beneficiaries}
                    onChange={handleChange("name_of_all_beneficiaries")}
                    caption={getText("pages.cabinet.nameOfAllBeneficiaries")}
                  />
                </Box>
              </>
            )}
            {profile.non_individual_interest_ownership === "OT" && (
              <Box mt={2}>
                <TextInput
                  type="text"
                  className={classes.input}
                  value={profile?.non_individual_interest_ownership_other}
                  onChange={handleChange("non_individual_interest_ownership_other")}
                  caption={getText("pages.cabinet.nonInterestOwnershipTypes.titleOther")}
                  data-castil="non_individual_interest_ownership_other"
                  helperMessage="Empty input"
                  required InputProps={{required: true}}
                />
              </Box>
            )}
            <Box mt={2}>
              <AutocompleteMaterial
                flag
                options={
                  store.citizenships_en
                    .filter(((code) => !isBlockedCountry(code)))
                    .map((code) => ({ label: getText(`countries.${code}`), value: code }))
                }
                text="jurisdiction_of_organizaton"
                title="Jurisdiction of organizaton"
                value={profile?.jurisdiction_of_organizaton}
                onChange={handleChangeAutoComplete}
                dataattribute="jurisdiction_of_organizaton"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <AutocompleteMaterial
                flag
                options={
                  store.citizenships_en
                    .filter(((code) => !isBlockedCountry(code)))
                    .map((code) => ({ label: getText(`countries.${code}`), value: code }))
                }
                text="company_country"
                title="Country of incorporation"
                value={profile?.company_country}
                onChange={handleChangeAutoComplete}
                dataattribute="company_country"
                required InputProps={{required: true}}
              />
            </Box>

            <Box mt={2}>
              <span className={classes.datePickerLabel}>{getText("pages.cabinet.organizationDate")}*</span>
              <DatePicker
                className={classes.pickerContainerElement}
                onChange={handleChangeDate("organization_formation_date")}
                format="DD/MM/YYYY"
                animateYearScrolling
                value={profile?.organization_formation_date}
                data-castil="organization_formation_date"
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.passport_number}
                onChange={handleChange("passport_number")}
                caption={getText("pages.cabinet.registrationnumber")}
                data-castil="passport_number"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.registration_address_en}
                onChange={handleChange("registration_address_en")}
                caption={getText("pages.cabinet.registeredAddress")}
                data-castil="registration_address_en"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <FormControlLabel
                className={classes.checkboxTitle}
                label={<p className={classes.checkboxTitle}>{getText("pages.cabinet.electronicSigning")}</p>}
                control={
                  <Checkbox
                    checked={profile.agree_to_electronic_signing === "YES"}
                    onChange={handleChange("agree_to_electronic_signing")}
                    color="primary"
                    className={classes.checkbox}
                    data-castil="agree_to_electronic_signing"
                  />
                }
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.investment_planned_amount}
                onChange={handleChange("investment_planned_amount")}
                caption={getText("pages.cabinet.investmentPlanned")}
                data-castil="investment_planned_amount"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.salary}
                onChange={handleChange("salary")}
                caption={getText("pages.cabinet.salary")}
                data-castil="salary"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>

            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.source_of_wealth}
                onChange={handleChange("source_of_wealth")}
                caption={getText("pages.cabinet.sourceOfWealth")}
                data-castil="source_of_wealth"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>

            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.source_of_funds}
                onChange={handleChange("source_of_funds")}
                caption={getText("pages.cabinet.sourceOfFunds")}
                data-castil="source_of_funds"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>

            <Box className={classes.bottomLine} />

            <Box mt={2} />

            <Typography variant="h5">Bank information</Typography>

            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.bank_name}
                onChange={handleChange("bank_name")}
                caption={getText("pages.cabinet.bankName")}
                data-castil="bank_name"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <AutocompleteMaterial
                flag
                options={store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
                text="bank_country"
                title={getText("pages.cabinet.bankCountry")}
                value={profile?.bank_country}
                onChange={handleChangeAutoComplete}
                dataattribute="bank_country"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.swift}
                onChange={handleChange("swift")}
                caption={getText("pages.cabinet.swiftNo")}
                data-castil="swift"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>

            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.account_number}
                onChange={handleChange("account_number")}
                caption={getText("pages.cabinet.acctNo")}
                data-castil="account_number"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>

            <Box mt={2}>
              <FormLabel className={classes.input} component="legend">
                <Typography className={classes.labelInput}>
                  <span>{getText("pages.cabinet.antiMoneyInfo.wiringBank")} /</span>
                  <a href="https://www.fatf-gafi.org/countries" target="_blank" rel="noreferrer" className={classes.linkText}>
                    FATF Countries
                  </a>
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-label="is_wiring_bank_fatf_approved"
                value={profile.is_wiring_bank_fatf_approved || null}
                className={classes.radioGroup}
                onChange={handleChange("is_wiring_bank_fatf_approved")}
              >
                <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
              <Box mt={2}>
                <Typography variant="body2" className={classes.fontSmall}>{getText("pages.cabinet.antiMoneyInfo.yes")}</Typography>
                <Typography variant="body2" className={classes.fontSmall}>{getText("pages.cabinet.antiMoneyInfo.no")}</Typography>
              </Box>
            </Box>

            <Box mt={2}>
              <FormLabel className={classes.input} component="legend">
                <Typography className={classes.labelInput}>{getText("pages.cabinet.antiMoneyInfo.investorCostumer")}</Typography>
              </FormLabel>
              <RadioGroup
                aria-label="is_wiring_bank_customer"
                value={profile.is_wiring_bank_customer || null}
                className={classes.radioGroup}
                onChange={handleChange("is_wiring_bank_customer")}
              >
                <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Box>

            <Box className={classes.bottomLine} />

            <Box mt={2}>
              <MultiSelect
                select={SELECTS_OPTIONS_CABINET}
                moreText
                handleChangeSelect={handleChangeSelect}
                profile={profile.accredited_investor_representation_points}
                type="accredited_investor_representation_points"
                dataattribute="accredited_investor_representation_points"
                required InputProps={{required: true}}
              />
            </Box>
            {profile?.accredited_investor_representation_points &&
              profile?.accredited_investor_representation_points.includes("OTHER_ENTITIES") && (
                <Box mt={2}>
                  <MultiSelect
                    select={SELECTS_OPTIONS_CABINET}
                    moreText
                    handleChangeSelect={handleChangeSelect}
                    profile={profile.accredited_investor_representation_points_other}
                    type="accredited_investor_representation_points_other"
                    dataattribute="accredited_investor_representation_points_other"
                    required InputProps={{required: true}}
                  />
                </Box>
              )}

            <Box className={classes.bottomLine} />

            <Box mt={2}>
              <style>{`
                .${classes.checkboxTitleSmall} > span {
                  font-size: 12px;
                }
              `}</style>
              <FormControlLabel
                className={`${classes.checkboxTitle} ${classes.checkboxTitleSmall}`}
                label={`
                  ${getText("pages.cabinet.isRisksApproved.investorQuestionnaire")}\n
                  ${getText("pages.cabinet.isRisksApproved.acceptedAltaclub")}\n
                  ${getText("pages.cabinet.isRisksApproved.dataProvided")}
                `}
                control={
                  <Checkbox
                    checked={profile.is_risks_approved}
                    onChange={handleChange("is_risks_approved")}
                    color="primary"
                    className={classes.checkbox}
                    data-castil="is_risks_approved"
                  />
                }
              />
            </Box>

            <Box className={classes.bottomLine} />

            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.last_name_en}
                onChange={handleChange("last_name_en")}
                caption={getText("pages.cabinet.memberInformation.surname")}
                data-castil="last_name_en"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>

            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.first_name_en}
                onChange={handleChange("first_name_en")}
                caption={getText("pages.cabinet.memberInformation.forename")}
                data-castil="first_name_en"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>

            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.middle_name_en}
                onChange={handleChange("middle_name_en")}
                caption={getText("pages.cabinet.memberInformation.patronymic")}
              />
            </Box>
          </Box>
        }

        {profile.entity_type === "Individual" && 
          <Box id="individual">
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.last_name_en}
                onChange={handleChange("last_name_en")}
                caption={getText("pages.cabinet.memberInformation.surname")}
                data-castil="last_name_en"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.first_name_en}
                onChange={handleChange("first_name_en")}
                caption={getText("pages.cabinet.memberInformation.forename")}
                data-castil="first_name_en"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.middle_name_en}
                onChange={handleChange("middle_name_en")}
                caption={getText("pages.cabinet.memberInformation.patronymic")}
              />
            </Box>
            <Box mt={2}>
              <span className={classes.datePickerLabel}>{getText("pages.cabinet.memberInformation.dateOfBirth")}*</span>
              <DatePicker
                className={classes.pickerContainerElement}
                onChange={handleChangeDate("date_of_birth")}
                format="DD/MM/YYYY"
                animateYearScrolling
                value={profile?.date_of_birth}
                data-castil="date_of_birth"
              />
            </Box>
            <Box mt={2}>
              {profile.citizenship && (
                <AutocompleteMaterial
                  flag
                  options={
                    store.citizenships_en
                      // .filter(((code) => !isBlockedCountry(code)))
                      .map((code) => ({ label: getText(`countries.${code}`), value: code }))
                  }
                  text="citizenship"
                  title="Citizenship"
                  value={profile.citizenship}
                  onChange={handleChangeAutoComplete}
                  dataattribute="citizenship"
                  required InputProps={{required: true}}
                />
              )}
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.passport_number}
                onChange={handleChange("passport_number")}
                caption={getText("pages.cabinet.memberInformation.passportNo")}
                data-castil="passport_number"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <span className={classes.datePickerLabel}>{getText("pages.cabinet.memberInformation.expiryDate")}*</span>
              <DatePicker
                className={classes.pickerContainerElement}
                onChange={handleChangeDate("date_of_passport_issue")}
                format="DD/MM/YYYY"
                animateYearScrolling
                value={profile?.date_of_passport_issue}
                data-castil="date_of_passport_issue"
              />
            </Box>
            <Box mt={2}>
              {profile.residence_country && (
                <AutocompleteMaterial
                  flag
                  options={
                    store.citizenships_en
                      .filter(((code) => !isBlockedCountry(code)))
                      .map((code) => ({ label: getText(`countries.${code}`), value: code }))
                  }
                  text="residence_country"
                  title="Residence country"
                  value={profile.residence_country}
                  onChange={handleChangeAutoComplete}
                  dataattribute="residence_country"
                  required InputProps={{required: true}}
                />
              )}
            </Box>
            <Box className={classes.bottomLine} />
            <Box mt={2}>
              <FormLabel className={classes.input} component="legend">
                <Typography className={classes.labelInput}>
                  {getText("pages.cabinet.usPerson.usPersonTitle")}
                </Typography>
              </FormLabel>
    
              <RadioGroup
                aria-label="is_us_person"
                className={classes.radioGroup}
                value={profile?.is_us_person}
                dataattribute="is_us_person"
                onChange={handleChange("is_us_person")}
                required InputProps={{required: true}}
              >
                <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
    
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Tooltip placement="right" title={getText("pages.cabinet.tooltips.usPerson")}>
                  <Box mt={2}>{getText("pages.cabinet.tooltips.titleUsPerson")}</Box>
                </Tooltip>
                <Tooltip placement="right" title={getText("pages.cabinet.tooltips.notUsPerson")}>
                  <Box mt={2}>{getText("pages.cabinet.tooltips.titleNonUsperson")}</Box>
                </Tooltip>
              </Box>
            </Box>
            {profile.is_us_person === "YES" && (
              <>
                <Box className={classes.bottomLine} />
                <Box mt={2}>
                  <AutocompleteMaterial
                    options={SELECTS_OPTIONS_CABINET.is_us_federal_taxes.map((el) => ({
                      label: getText(`${el.label}`),
                      value: el.value,
                    }))}
                    text="is_us_federal_taxes"
                    title="pages.cabinet.usIncomeTax.usIncomeTaxTitle"
                    value={profile?.is_us_federal_taxes}
                    onChange={handleChangeAutoComplete}
                    dataattribute="is_us_federal_taxes"
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={profile?.social_security_number}
                    onChange={handleChange("social_security_number")}
                    caption={getText("pages.cabinet.usIncomeTax.securityNumber")}
                    data-castil="social_security_number"
                    helperMessage="Empty input"
                    required InputProps={{required: true}}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={profile?.green_card_number}
                    onChange={handleChange("green_card_number")}
                    caption={getText("pages.cabinet.usIncomeTax.greenCard")}
                  />
                </Box>
              </>
            )}
            {profile.is_us_person === "NO" && (
              <>
                <Box mt={2}>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={profile?.tingin}
                    onChange={handleChange("tingin")}
                    caption={getText("pages.cabinet.tingin")}
                    data-castil="tingin"
                    helperMessage="Empty input"
                    required InputProps={{required: true}}
                  />
                </Box>
              </>
            )}
            <Box className={classes.bottomLine} />
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.residence_address}
                onChange={handleChange("residence_address")}
                caption={getText("pages.cabinet.residenceAddress")}
                data-castil="residence_address"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.mailing_address}
                onChange={handleChange("mailing_address")}
                caption={getText("pages.cabinet.mailingAddress")}
              />
            </Box>
            <Box className={classes.bottomLine} />
            <Box mt={2}>
              <FormControlLabel
                className={classes.checkboxTitle}
                label={<p className={classes.checkboxTitle}>{getText("pages.cabinet.electronicSigning")}</p>}
                control={
                  <Checkbox
                    checked={profile.agree_to_electronic_signing === "YES"}
                    onChange={handleChange("agree_to_electronic_signing")}
                    color="primary"
                    className={classes.checkbox}
                    data-castil="agree_to_electronic_signing"
                  />
                }
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.investment_planned_amount}
                onChange={handleChange("investment_planned_amount")}
                caption={getText("pages.cabinet.investmentPlanned")}
                data-castil="investment_planned_amount"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.salary}
                onChange={handleChange("salary")}
                caption={getText("pages.cabinet.salary")}
                data-castil="salary"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box className={classes.bottomLine} />
            <Typography variant="h5">Bank information</Typography>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.bank_name}
                onChange={handleChange("bank_name")}
                caption={getText("pages.cabinet.bankName")}
                data-castil="bank_name"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <AutocompleteMaterial
                flag
                options={store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
                text="bank_country"
                title={getText("pages.cabinet.bankCountry")}
                value={profile?.bank_country}
                onChange={handleChangeAutoComplete}
                dataattribute="bank_country"
                required InputProps={{required: true}}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.swift}
                onChange={handleChange("swift")}
                caption={getText("pages.cabinet.swiftNo")}
                data-castil="swift"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
    
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.account_number}
                onChange={handleChange("account_number")}
                caption={getText("pages.cabinet.acctNo")}
                data-castil="account_number"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
    
            <Box mt={2}>
              <FormLabel className={classes.input} component="legend">
                <Typography className={classes.labelInput}>
                  {getText("pages.cabinet.antiMoneyInfo.wiringBank")} /
                  <a href="https://www.fatf-gafi.org/countries" target="_blank" rel="noreferrer" className={classes.linkText}>
                    FATF Countries
                  </a>
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-label="is_wiring_bank_fatf_approved"
                value={profile.is_wiring_bank_fatf_approved || null}
                className={classes.radioGroup}
                onChange={handleChange("is_wiring_bank_fatf_approved")}
              >
                <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
              <Box mt={2}>
                <Typography variant="body2" className={classes.fontSmall}>{getText("pages.cabinet.antiMoneyInfo.yes")}</Typography>
                <Typography variant="body2" className={classes.fontSmall}>{getText("pages.cabinet.antiMoneyInfo.no")}</Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <FormLabel className={classes.input} component="legend">
                <Typography className={classes.labelInput}>{getText("pages.cabinet.antiMoneyInfo.investorCostumer")}</Typography>
              </FormLabel>
              <RadioGroup
                aria-label="is_wiring_bank_customer"
                value={profile.is_wiring_bank_customer || null}
                className={classes.radioGroup}
                onChange={handleChange("is_wiring_bank_customer")}
              >
                <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Box>
    
            <Box className={classes.bottomLine} />
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.total_estimated_net_wealth}
                onChange={handleChange("total_estimated_net_wealth")}
                caption={getText("pages.cabinet.estimatedIncluding.totalEstimatedWealth")}
                data-castil="total_estimated_net_wealth"
                helperMessage="Empty input"
                required InputProps={{required: true}}
              />
            </Box>
            <Box className={classes.bottomLine} />
            <span style={{ fontSize: "20px" }}>Source of Total Wealth </span>
            <p>
              (Please state company name(s). Describe the activities which have generated your total net worth both within and outside
              of the business relationship)
            </p>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.employment_wealth}
                onChange={handleChange("employment_wealth")}
                caption={getText("pages.cabinet.sourceTotalWealth.employment")}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.dividens_wealth}
                onChange={handleChange("dividens_wealth")}
                caption={getText("pages.cabinet.sourceTotalWealth.dividends")}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.paternal_inheritance}
                onChange={handleChange("paternal_inheritance")}
                caption={getText("pages.cabinet.sourceTotalWealth.inheritance")}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.property_sales_wealth}
                onChange={handleChange("property_sales_wealth")}
                caption={getText("pages.cabinet.sourceTotalWealth.propertySales")}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.investments_sales_wealth}
                onChange={handleChange("investments_sales_wealth")}
                caption={getText("pages.cabinet.sourceTotalWealth.saleOfInvestments")}
              />
            </Box>
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.other_sales_wealth}
                onChange={handleChange("other_sales_wealth")}
                caption={getText("pages.cabinet.sourceTotalWealth.other")}
              />
            </Box>
            <Box className={classes.bottomLine} />
            <Box mt={2}>
              <Box id="individual_interest_ownership" mt={2}>
                <AutocompleteMaterial
                  options={SELECTS_OPTIONS_CABINET.interestOwnershipTypes.map((el) => ({
                    label: getText(`${el.label}`),
                    value: el.value,
                  }))}
                  text="individual_interest_ownership"
                  title="pages.cabinet.interestOwnershipTypes.title"
                  value={profile?.individual_interest_ownership}
                  onChange={handleChangeAutoComplete}
                />
                {profile.individual_interest_ownership === "OT" && (
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={profile?.individual_interest_ownership_other}
                    onChange={handleChange("individual_interest_ownership_other")}
                    caption={getText("pages.cabinet.interestOwnershipTypes.titleOther")}
                  />
                )}
              </Box>
            </Box>

            <Box className={classes.bottomLine} />

            <Box mt={2}>
              <FormLabel className={classes.input} component="legend">
                <Typography className={classes.labelInput}>
                  {getText("pages.cabinet.seniorForeignPolitical")}
                  <Hidden xsDown>
                    <Tooltip placement="right" title={getText("pages.cabinet.seniorForeignPoliticalTooltip")}>
                      <Box ml={1} mt={-0.2}>
                        <Helper disableRipple>?</Helper>
                      </Box>
                    </Tooltip>
                  </Hidden>
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-label="is_senior_foreign_political_figure"
                value={profile.is_senior_foreign_political_figure || null}
                className={classes.radioGroup}
                onChange={handleChange("is_senior_foreign_political_figure")}
              >
                <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Box>
            <Box className={classes.bottomLine} />
            <Box mt={2}>
              <FormLabel className={classes.input} component="legend">
                <Typography className={classes.labelInput}>{getText("pages.cabinet.criminalVictions")} </Typography>
              </FormLabel>
              <RadioGroup
                aria-label="convictions"
                value={profile.convictions || null}
                className={classes.radioGroup}
                onChange={handleChange("convictions")}
              >
                <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Box>
            {profile.convictions === "YES" && (
              <Box mt={2}>
                <TextInput
                  type="text"
                  caption=" "
                  className={classes.input}
                  value={profile?.criminal_descriptions}
                  onChange={handleChange("criminal_descriptions")}
                  data-castil="criminal_descriptions"
                  helperMessage="Empty input"
                  required InputProps={{required: true}}
                />
              </Box>
            )}
            <Box className={classes.bottomLine} />
            <Box mt={2}>
              <FormLabel className={classes.input} component="legend">
                <Typography className={classes.labelInput}>{getText("pages.cabinet.underInvestigation")} </Typography>
              </FormLabel>
              <RadioGroup
                aria-label="under_investigation"
                value={profile.under_investigation || null}
                className={classes.radioGroup}
                onChange={handleChange("under_investigation")}
              >
                <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Box>
            {profile.under_investigation === "YES" && (
              <Box mt={2}>
                <TextInput
                  type="text"
                  caption=" "
                  className={classes.input}
                  value={profile?.under_investigation_text}
                  onChange={handleChange("under_investigation_text")}
                  data-castil="under_investigation_text"
                  helperMessage="Empty input"
                  required InputProps={{required: true}}
                />
              </Box>
            )}
            <Box className={classes.bottomLine} />
            
            <Box mt={2}>
              <MultiSelect
                select={SELECTS_OPTIONS_CABINET}
                moreText
                handleChangeSelect={handleChangeSelect}
                profile={profile.accredited_investor_representation_points}
                type="accredited_investor_representation_points"
                dataattribute="accredited_investor_representation_points"
                required InputProps={{required: true}}
              />
            </Box>
            {profile?.accredited_investor_representation_points &&
              profile?.accredited_investor_representation_points.includes("OTHER_ENTITIES") && (
                <Box mt={2}>
                  <MultiSelect
                    select={SELECTS_OPTIONS_CABINET}
                    moreText
                    handleChangeSelect={handleChangeSelect}
                    profile={profile.accredited_investor_representation_points_other}
                    type="accredited_investor_representation_points_other"
                    dataattribute="accredited_investor_representation_points_other"
                    required InputProps={{required: true}}
                  />
                </Box>
              )}
            <Box className={classes.bottomLine} />
    
            <Box mt={2}>
              <style>{`
                .${classes.checkboxTitleSmall} > span {
                  font-size: 12px;
                }
              `}</style>
              <FormControlLabel
                className={`${classes.checkboxTitle} ${classes.checkboxTitleSmall}`}
                label={`
                  ${getText("pages.cabinet.isRisksApproved.investorQuestionnaire")}\n
                  ${getText("pages.cabinet.isRisksApproved.acceptedAltaclub")}\n
                  ${getText("pages.cabinet.isRisksApproved.dataProvided")}
                `}
                control={
                  <Checkbox
                    checked={profile.is_risks_approved}
                    onChange={handleChange("is_risks_approved")}
                    color="primary"
                    className={classes.checkbox}
                    data-castil="is_risks_approved"
                  />
                }
              />
            </Box>
          </Box>
        }
      </PopupPaper>
    </div>
  );
});
