/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Typography, Box, FormControlLabel, Divider, Button, Grid, Checkbox } from "@material-ui/core";
import remove from "lodash/remove";
import { NotificationManager } from "react-notifications";
import validate from "validate.js";
import PopupPaper from "../../components/PopupPaper";
import useLanguage from "../../languages/index";
import TextInput from "../../components/TextInput";
import urls, { useAsyncEndpoint } from "../../urls";
import { useStore } from "../../store";
import { AddIcon } from "../../components/Icons";
import DeleteDialog from "../../components/DeleteDialog";

// style
import { PaperButton, Hover, HoverItem } from "./style";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      background: "#FFFFFF",
      display: "block",
    },
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
  },
  radioLabel: {
    height: 35,
  },
  forms: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

// const Hover = posed.div({
//     hoverable: true,
//     hover: {
//         staggerChildren: 100
//     }
// })

// const HoverItem = posed.div({
//     hover: {
//         y: 0,
//         transition: ({ from, to }) => ({
//             type: 'keyframes',
//             duration: 1000,
//             values: [0, 100, -300, 0],
//             times: [0, .25, 0, .5]
//         })
//     },
// })

// const PaperButton = withStyles(theme => ({
//     root: {
//         fontSize: 18,
//         padding: '40px 40px 40px',
//         width: 620,
//         borderRadius: 20,
//         overflow: 'hidden',
//         [theme.breakpoints.down('xs')]: {
//             width: '100%'
//         }
//     },
//     label: {
//         '& > div': {
//             width: '100%',
//             justifyContent: 'space-between'
//         }
//     }
// }))(Button)

const useAccounts = (method = "GET") =>
  useAsyncEndpoint(({ data, id } = {}) => ({
    url: urls.accounts(id),
    method,
    data,
  }));

export default observer(({ location }) => {
  const classes = useStyles();
  const getText = useLanguage();
  const store = useStore();

  const previousAccounts = useRef();
  const processedAccount = useRef();

  const [accountsData, fetchAccounts] = useAccounts();
  const [newAccountData, newAccount] = useAccounts("POST");
  const [changeAccountData, editAccount] = useAccounts("PATCH");
  const [removeAccountData, removeAccount] = useAccounts("DELETE");

  const [accounts, setAccounts] = useState([]);
  const [errors, setErrors] = useState({});

  const [openDelete, openDeleteDialog] = useState();
  const [deleteTitle, setDeleteTitle] = useState();
  const [deleteIndex, setDeleteIndex] = useState();

  const handleDelete = useCallback((confirm) => {
    if (confirm) {
      previousAccounts.current = [...accounts];
      processedAccount.current = { ...accounts[deleteIndex] };

      if (processedAccount.current.id) {
        removeAccount({ id: processedAccount.current.id });
        setAccounts(remove(accounts, (value, i) => i !== deleteIndex));
      } else setAccounts(remove(accounts, (value, i) => i !== deleteIndex));
    }

    openDeleteDialog(false);
  });

  const handleChange = (key, index) => (event) => {
    accounts[index][key] = event.target.value;
    setAccounts([...accounts]);
  };

  const accountConstraints = {
    email: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.emailEmpty")}`,
      },
      email: { message: `^${getText("errors.emailInvalid")}` },
    },
    phone: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.phoneEmpty")}`,
      },
    },
  };

  const saveAccount = (index) => (event) => {
    event.preventDefault();
    processedAccount.current = { ...accounts[index] };

    const {
      first_name_en,
      last_name_en,
      middle_name_en,
      position_en,
      is_subscribed_to_newsletter,
      is_subscribed_to_portfolio_changes,
      phone,
      email,
    } = processedAccount.current;

    let data = {
      first_name_en,
      last_name_en,
      middle_name_en,
      position_en,
      is_subscribed_to_newsletter,
      is_subscribed_to_portfolio_changes,
      phone,
      email,
    };

    if (processedAccount.current.id) {
      const _errors = validate(processedAccount.current, accountConstraints);

      if (_errors) {
        setErrors({ ...errors, [index]: _errors });
        return;
      }

      editAccount({
        data,
        id: processedAccount.current.id,
      });
    } else {
      accountConstraints.password = {
        equality: {
          attribute: "repeat_password",
          message: `^${getText("errors.passwordsNotMatch")}`,
        },
        presence: {
          allowEmpty: false,
          message: `^${getText("errors.passwordEmpty")}`,
        },
        length: {
          minimum: 6,
          message: `^${getText("errors.passwordShort", 6)}`,
        },
      };

      const _errors = validate(processedAccount.current, accountConstraints);
      if (_errors) {
        setErrors({ ...errors, [index]: _errors });
        return;
      }

      data = {
        ...data,
        password: processedAccount.current.password,
      };

      newAccount({ data });
    }
  };

  const deleteAccount = (index) => () => {
    setDeleteTitle(getText("tips.removeAccount", index + 1));
    setDeleteIndex(index);
    openDeleteDialog(true);
  };

  useEffect(() => {
    if (newAccountData.complete) {
      if (!newAccountData.error) {
        NotificationManager.success(getText("messages.accountCreated", processedAccount.current.email));
        setErrors({});
      } else {
        NotificationManager.error(getText("messages.somethingWrongWith", processedAccount.current.email));
      }
    }
  }, [newAccountData]);
  useEffect(() => {
    if (changeAccountData.complete) {
      if (!changeAccountData.error) {
        NotificationManager.success(getText("messages.accountChanged", processedAccount.current.email));
        setErrors({});
      } else {
        NotificationManager.error(getText("messages.somethingWrongWith", processedAccount.current.email));
      }
    }
  }, [changeAccountData]);
  useEffect(() => {
    if (removeAccountData.complete) {
      if (!removeAccountData.error) {
        NotificationManager.success(getText("messages.accountRemoved", processedAccount.current.email));
      } else {
        NotificationManager.error(getText("messages.somethingWrongWith", processedAccount.current.email));
        setAccounts(previousAccounts.current);
      }
    }
  }, [removeAccountData]);
  useEffect(fetchAccounts, []);
  useEffect(() => {
    if (accountsData.complete && !accountsData.error) {
      setAccounts(accountsData.data);
    }
  }, [accountsData]);

  return (
    <>
      <Box mt={2} />
      <DeleteDialog onClose={handleDelete} title={deleteTitle} open={openDelete} />
      {accounts.map((account, index) => (
        <form className={classes.forms} onSubmit={saveAccount(index)} key={index}>
          <PopupPaper inline>
            <Box display="flex">
              <Typography variant="h5">
                <strong>
                  {getText("account")} №{index + 1}
                </strong>
              </Typography>
              {!store.profile.isExtraAccount ? (
                <Box ml="auto">
                  <Button variant="contained" color="secondary" className={classes.button} onClick={deleteAccount(index)}>
                    {getText("delete")}
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box mt={3.2}>
              <TextInput
                value={account.first_name_en}
                onChange={handleChange("first_name_en", index)}
                caption={`${getText("firstName")}${store.profile.country === "RU" ? ` - ${getText("latin")}` : ""}`}
              />
            </Box>
            <Box mt={3}>
              <TextInput
                value={account.last_name_en}
                onChange={handleChange("last_name_en", index)}
                caption={`${getText("lastName")}${store.profile.country === "RU" ? ` - ${getText("latin")}` : ""}`}
              />
            </Box>
            <Box mt={3}>
              <TextInput
                value={account.middle_name_en}
                onChange={handleChange("middle_name_en", index)}
                caption={`${getText("patronymyc")}${store.profile.country === "RU" ? ` - ${getText("latin")}` : ""}`}
              />
            </Box>
            <Box mt={4}>
              <Divider className={classes.divider} />
            </Box>
            <Box mt={3.2}>
              <TextInput value={account.position} onChange={handleChange("position", index)} caption={getText("position")} />
            </Box>
            <Box mt={3.2}>
              <TextInput
                value={account.phone}
                onChange={handleChange("phone", index)}
                error={errors[index] && errors[index].phone}
                caption={getText("phone")}
              />
            </Box>
            <Box mt={3.2}>
              <TextInput
                value={account.email}
                error={errors[index] && errors[index].email}
                onChange={handleChange("email", index)}
                caption={getText("email")}
              />
            </Box>
            <Box mt={4}>
              <Divider className={classes.divider} />
            </Box>
            <Box mt={2}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                value={account.is_subscribed_to_newsletter}
                label={getText("subscribed.newsletters")}
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                value={account.is_subscribed_to_portfolio_changes}
                label={getText("subscribed.notifications")}
              />
            </Box>
            <Box mt={2}>
              <Divider className={classes.divider} />
            </Box>
            {!account.id ? (
              <>
                <Box mt={3.2}>
                  <TextInput
                    value={account.password}
                    error={errors[index] && errors[index].password}
                    type="password"
                    onChange={handleChange("password", index)}
                    caption={getText("newPassword")}
                  />
                </Box>
                <Box mt={3.2}>
                  <TextInput
                    value={account.repeat_password}
                    type="password"
                    onChange={handleChange("repeat_password", index)}
                    caption={getText("repeatPassword")}
                  />
                </Box>
              </>
            ) : (
              ""
            )}
            <Box mt={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button type="submit" fullWidth size="large" variant="contained" color="primary" className={classes.button}>
                    {getText("save")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </PopupPaper>
          <Box mt={2} />
        </form>
      ))}
      {!store.profile.isExtraAccount ? (
        <>
          <Hover className={classes.forms}>
            <PaperButton fullWidth color="secondary" variant="contained" onClick={() => setAccounts([...accounts, []])}>
              <Box display="flex" alignItems="center">
                <HoverItem>
                  <Typography color="textSecondary" variant="inherit">
                    <strong>{getText("addAccount")}</strong>
                  </Typography>
                </HoverItem>
                <HoverItem>
                  <Box fontSize={40} lineHeight={0} ml="auto">
                    <AddIcon fontSize="inherit" />
                  </Box>
                </HoverItem>
              </Box>
            </PaperButton>
          </Hover>
          <Box mt={2} />
        </>
      ) : (
        ""
      )}
    </>
  );
});
