import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28, fontSize: "14px" },
  radioGroup: {
    flexDirection: "row !important",
  },
  button: {
    margin: "28px 0",
  },
  under: {
    textDecorationLine: "underline",
  },
  checkbox: {
    marginRight: 10,
  },
  checkboxError: {
    marginRight: 10,
    color: "#f36363",
  },
  checkboxTitle: {
    textAlign: "justify",
    fontSize: "13px",
  },
  checkboxTitleSmall: {
    fontSize: "12px !important",
  },
  pickerContainerElement: {
    width: "100%",
    "& > div": {
      border: "none",
    },
  },
  labelInput: {
    "text-align": "justify",
    display: "flex",
  },
  linkText: {
    color: "#7E9FC1",
    textDecorationLine: "underline",
  },
  datePickerLabel: {
    fontSize: 14,
    color: "#7E9FC1",
    display: "block",
  },
  bottomLine: {
    border: "1px solid #DBE5EA",
    margin: "32px 0 24px",
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px 0px 12px",
    "&:last-child": {
      display: "block",
    },
  },
  paginationBlock: {
    display: "flex",
    "&:last-child > div": {
      display: "none",
    },
  },
  paginationItem: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    padding: "8px 12px",
    border: "1px solid #2356B2",
    borderRadius: "50%",
    background: "#FFFFFF",
    color: "#2356B2",
    transition: "all 0.3s",
    cursor: "pointer",
  },
  paginationItemCompleted: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    padding: "8px 12px",
    border: "1px solid #2356B2",
    borderRadius: "50%",
    background: "#2356B2",
    color: "#ffffff",
    transition: "all 0.3s",
  },
  paginationLineContainer: {
    display: "flex",
    alignItems: "center",
  },
  paginationLine: {
    width: "80px",
    height: "3px",
    background: "#2356B2",
    border: "none",
  },
  paginationTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationText: {
    fontSize: "12px",
    maxWidth: "61px",
    marginLeft: "47px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  fontSmall: {
    fontSize: "12px"
  }
}));
