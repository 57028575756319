import React, { useState, useEffect } from "react";
import { Redirect, useLocation, useHistory, Prompt } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "./style.css";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Button, Link, Typography, Tooltip } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import values from "lodash/values";
import keys from "lodash/keys";
import validate from "validate.js";
import { toJS } from "mobx";
import moment from "moment";
import { profileForm } from "../../utils/generatePdf";
import { useStore } from "../../store";
import urls, { useAsyncEndpoint } from "../../urls";
import useLanguage from "../../languages";
import PopupPaper from "../../components/PopupPaper";
import GeneralInformation from "./ProfileComponents/GeneralInformation";
import PersonalData from "./ProfileComponents/PersonalData";
import InvestmentsInformation from "./ProfileComponents/InvestmentsInformation";
import UBO from "./ProfileComponents/UBO";
import Accreditation from "./ProfileComponents/Accreditation";
import CompanyData from "./ProfileComponents/companyData/CompanyData";
import Shareholders from "./ProfileComponents/Shareholders";
import Directors from "./ProfileComponents/Directors";
import { REACT_APP_API_URL } from "../../constants/selectConstants";

const useStyles = makeStyles((theme) => ({
  radioLabel: {
    [theme.breakpoints.up("sn")]: { height: 35 },
  },
  forms: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  label: { fontSize: 14 },
  linkDisabled: {
    color: "#C6CAD3",
  },
  links: {
    color: "#2356B2",
  },
}));

// Запросы на выгрузку данных

const useProfile = () =>
  useAsyncEndpoint((data) => ({
    url: urls.kyc(),
    method: "POST",
    data,
  }));

const useKyc = () =>
  useAsyncEndpoint(() => ({
    url: urls.kyc(),
    method: "GET",
  }));

const useUBO = () =>
  useAsyncEndpoint(() => ({
    url: urls.ubo(),
    method: "GET",
  }));

const useShareholder = () =>
  useAsyncEndpoint((data) => ({
    url: urls.shareholder(),
    method: "GET",
  }));

const useDirector = () =>
  useAsyncEndpoint((data) => ({
    url: urls.director(),
    method: "GET",
  }));

validate.validators.presenceLight = function validateFunc(value, options, key, attributes) {
  if (value === null) return options.message;
  return null;
};

// Запросы на загрузку данных

async function fillGeneralData(data) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_general_data/`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

async function fillPersonalData(data) {
  const body = {  ...data }

  delete body.investment_size
  delete body.investment_type
  delete body.investment_industry
  delete body.reports_language
  delete body.individual_profile_error_fields
  delete body.signature_photo

  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_personal_data/`, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}
async function fillCompanyData(data) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_company_data/`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

async function fillInvestmentData(data) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_investment_data/`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

async function fillUBO(data, profileUBO) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_ubo/`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  const resultUBO = await profileUBO.map((obj) =>
    fetch(`${REACT_APP_API_URL}/api/v1/investors/update_ubo/${obj.id}/`, {
      method: "PATCH",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
  );
  if (result.status !== 200 || resultUBO.status !== 200) {
    // TODO обработать ошибки
  }
}

async function fillShareholder(data, profileShareholder) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_shareholder/`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  const resultShareholder = await profileShareholder.map((obj) =>
    fetch(`${REACT_APP_API_URL}/api/v1/investors/update_shareholder/${obj.id}/`, {
      method: "PATCH",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
  );
  if (result.status !== 200 || resultShareholder.status !== 200) {
    // TODO обработать ошибки
  }
}

async function fillDirector(data, profileDirector) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_director/`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  const resultDirector = await profileDirector.map((obj) =>
    fetch(`${REACT_APP_API_URL}/api/v1/investors/update_director/${obj.id}/`, {
      method: "PATCH",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
  );
  if (result.status !== 200 || resultDirector.status !== 200) {
    // TODO обработать ошибки
  }
}

async function fillAccreditation(data) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_accreditation/`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });

  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

const allowedPages = ["general", "personal", "companys", "investments", "ubo", "shareholders", "directors", "accreditation"];

export default observer(() => {
  const classes = useStyles();
  const store = useStore();
  const getText = useLanguage();
  const location = useLocation();
  const history = useHistory();

  const [profile, setProfile] = useState({ ...store.profile });
  const [profileCopy, setProfileCopy] = useState({});
  const [kyc, fetchKyc] = useKyc();
  const [profileId, setProfileId] = useState();
  const [isBlocking, setIsBlocking] = useState(false);
  const [tab, setTab] = useState(0);
  const [profileResponse, postProfile] = useProfile();

  const [ubo, fetchUBO] = useUBO();
  const [profileUBO, setProfileUBO] = useState([...store.ubo]);

  const [shareholder, fetchShareholder] = useShareholder();
  const [profileShareholder, setProfileShareholder] = useState([...store.shareholder]);

  const [director, fetchDirector] = useDirector();
  const [profileDirector, setProfileDirector] = useState([...store.director]);

  const [edited, setEdited] = useState(false);
  const [allDocsKys, setAllDocsKys] = useState(false);
  const [showDocs, setShowDocs] = useState(false);

  // Handle change

  const handleChangeDoc = (prop) => (event) => {
    setProfile({ ...profile, [prop]: event.target.checked });
  };

  const handleChangeSelect = (prop, array) => {
    setProfile({ ...profile, [prop]: array });
  };

  const handleChange = (prop) => (event) => {
    setProfile({ ...profile, [prop]: event.target.value });
  };

  const handleChangeAutoComplete = (prop, value) => {
    setProfile({ ...profile, [prop]: value });
  };

  const handleChangeDate = (prop) => (event) => {
    const date = new Date();
    const year = date.getFullYear() - 18;
    const momentProp = moment(event._d).format("YYYY-MM-DD");
    const momentPropYear = moment(event._d).format("YYYY");
    setProfile({ ...profile, [prop]: momentProp });
    if (prop === "date_of_birth") {
      if (momentPropYear > year) {
        NotificationManager.error(getText("messages.adulthood"));
      }
    }
  };

  const generatePdf = () => {
    profileForm(kyc.data);
  };

  const getQueryParam = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };

  const setQueryParam = (key, value) => {
    const params = new URLSearchParams(location.search);
    params.set(key, value);
    history.push({
      search: params.toString(),
    });
  };

  const setPageTab = (value) => {
    setTab(value);
    setQueryParam("page", allowedPages[value]);
  };

  useEffect(() => {
    const docs = toJS(store.documents);
    if (docs.length) {
      const emptyDoc = docs.find((el) => el.document_file === null);
      emptyDoc ? setAllDocsKys(false) : setAllDocsKys(true);
      setShowDocs(true);
    }
  }, [store.documents]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = getQueryParam("page");
    if (!page || !allowedPages.includes(page)) {
      setQueryParam("page", allowedPages[0]);
    }
    setTab(allowedPages.indexOf(page));
  }, [kyc, tab]);

  const constraints = {
    email: {
      presenceLight: {
        message: `^${getText("errors.emailEmpty")}`,
      },
      email: { message: `^${getText("errors.emailInvalid")}` },
    },
    phone: {
      length: { maximum: 24, message: `^${getText("errors.phoneMax", 24)}` },
    },
    swift: {
      format: { pattern: "^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$", message: `^${getText("errors.swiftInvalid")}` },
    },
    /*
        date_of_birth: {
            presenceLight: {
                message: `^${getText('errors.dateEmpty')}`
            }
        },
        date_of_passport_issue: {
            presenceLight: {
                message: `^${getText('errors.dateEmpty')}`
            }
        }
        */
  };

  useEffect(() => {
    const data = toJS(store.profile);
    setProfile(data);
    setProfileCopy(data);
  }, [store.profile]);

  useEffect(() => {
    const data = toJS(store.ubo);
    data.map((obj) => [obj]);
    setProfileUBO([...data]);
  }, [store.ubo]);

  useEffect(() => {
    if (ubo.complete) {
      store.setProfileUBO(ubo.data);
    }
  }, [ubo]);

  useEffect(() => {
    const data = toJS(store.shareholder);
    data.map((obj) => [obj]);
    setProfileShareholder([...data]);
  }, [store.shareholder]);

  useEffect(() => {
    if (shareholder.complete) {
      store.setProfileShareholder(shareholder.data);
    }
  }, [shareholder]);

  useEffect(() => {
    const data = toJS(store.director);
    data.map((obj) => [obj]);
    setProfileDirector([...data]);
  }, [store.director]);

  useEffect(() => {
    if (director.complete) {
      store.setProfileDirector(director.data);
    }
  }, [director]);

  useEffect(() => {
    fetchKyc();
  }, []);

  useEffect(() => {
    if (kyc.complete) {
      setProfileId(kyc.data);
    }
  }, [kyc]);

  useEffect(() => {
    if (profileResponse.complete) {
      fetchKyc();
      setEdited(false);
      if (profileResponse.error) {
        const message = values(profileResponse.data).length
          ? `${keys(profileResponse.data)[0]}: ${values(profileResponse.data)[0]}`
          : getText("messages.somethingWrong");
        NotificationManager.error(message);
      } else {
        if (document.getElementById("inputWarningElement")) {
          setTimeout(
            () => document.getElementById("inputWarningElement").scrollIntoView({ behavior: "smooth", block: "center" }),
            200
          );
          NotificationManager.warning(getText("messages.profileUnfilled"));
        }
        store.setProfile(profileResponse.data.profile);
      }
    }
  }, [profileResponse]);

  const onSubmit = async (ev) => {
    // ev.preventDefault();
    store.setLoading(true);
    try {
      switch (tab) {
        case 0:
          await fillGeneralData({
            first_name: profile.first_name,
            first_name_en: profile.first_name_en,
            last_name: profile.last_name,
            last_name_en: profile.last_name_en,
            phone: profile.phone,
            email: profile.email,
            telegram: profile.telegram,
          });
          break;

        case 1:
          await fillPersonalData({
            ...profile
          });
          break;

        case 2:
          await fillCompanyData({
            company_name: profile.company_name,
            company_number: profile.company_number,
            company_country: profile.company_country,
            company_address: profile.company_address,
          });
          break;

        case 3:
          await fillInvestmentData({
            investment_size: profile.investment_size,
            investment_type: profile.investment_type,
            investment_industry: profile.investment_industry,
          });
          break;

        case 4:
          await fillUBO(
            {
              source_of_wealth: profile.source_of_wealth,
              postal_address: profile.postal_address,
              political_refugee_text: profile.political_refugee_text,
              convictions_text: profile.convictions_text,
              under_investigation_text: profile.under_investigation_text,
              power_of_attorney: profile.power_of_attorney,
              attorney_full_name: profile.attorney_full_name,
              attorney_phone: profile.attorney_phone,
              attorney_email: profile.attorney_email,
              bank_name: profile.bank_name,
              bank_address: profile.bank_address,
              iban: profile.iban,
              swift: profile.swift,
              distributions_account: profile.distributions_account,
              distributions_address: profile.distributions_address,
              distributions_bank_name: profile.distributions_bank_name,
              distributions_bank_address: profile.distributions_bank_address,
              distributions_swift: profile.distributions_swift,
              distributions_account_number: profile.distributions_account_number,
              distributions_instructions: profile.distributions_instructions,
            },
            profileUBO
          );
          break;
        case 5:
          await fillShareholder(
            {
              source_of_wealth: profile.source_of_wealth,
              postal_address: profile.postal_address,
              political_refugee_text: profile.political_refugee_text,
              convictions_text: profile.convictions_text,
              under_investigation_text: profile.under_investigation_text,
              power_of_attorney: profile.power_of_attorney,
              attorney_full_name: profile.attorney_full_name,
              attorney_phone: profile.attorney_phone,
              attorney_email: profile.attorney_email,
              bank_name: profile.bank_name,
              bank_address: profile.bank_address,
              iban: profile.iban,
              swift: profile.swift,
              distributions_account: profile.distributions_account,
              distributions_address: profile.distributions_address,
              distributions_bank_name: profile.distributions_bank_name,
              distributions_bank_address: profile.distributions_bank_address,
              distributions_swift: profile.distributions_swift,
              distributions_account_number: profile.distributions_account_number,
              distributions_instructions: profile.distributions_instructions,
            },
            profileShareholder
          );
          break;
        case 6:
          await fillDirector(
            {
              source_of_wealth: profile.source_of_wealth,
              postal_address: profile.postal_address,
              political_refugee_text: profile.political_refugee_text,
              convictions_text: profile.convictions_text,
              under_investigation_text: profile.under_investigation_text,
              power_of_attorney: profile.power_of_attorney,
              attorney_full_name: profile.attorney_full_name,
              attorney_phone: profile.attorney_phone,
              attorney_email: profile.attorney_email,
              bank_name: profile.bank_name,
              bank_address: profile.bank_address,
              iban: profile.iban,
              swift: profile.swift,
              distributions_account: profile.distributions_account,
              distributions_address: profile.distributions_address,
              distributions_bank_name: profile.distributions_bank_name,
              distributions_bank_address: profile.distributions_bank_address,
              distributions_swift: profile.distributions_swift,
              distributions_account_number: profile.distributions_account_number,
              distributions_instructions: profile.distributions_instructions,
            },
            profileDirector
          );
          break;

        case 7:
          await fillAccreditation({
            legal_entity_type: profile.legal_entity_type,
            other_legal_entity: profile.other_legal_entity,
            russian_legal_details: profile.russian_legal_details,
            israel_legal_details: profile.israel_legal_details,
            usa_legal_details: profile.usa_legal_details,
            russian_individual_person_select: profile.russian_individual_person_select,
            russian_legal_person_select: profile.russian_legal_person_select,
            russian_legal_qualified_investor_select: profile.russian_legal_qualified_investor_select,
            russian_legal_other_select: profile.russian_legal_other_select,
            israel_individual_person_select: profile.israel_individual_person_select,
            israel_legal_person_select: profile.israel_legal_person_select,
            usa_individual_person_select: profile.usa_individual_person_select,
            usa_legal_person_select: profile.usa_legal_person_select,
          });
          break;

        default:
          break;
      }
      NotificationManager.success(getText("messages.profileSaved"));
      window.scrollTo(0, 0);

      // if (tab < 5) {
      //   setPageTab(tab + 1);
      //   window.scrollTo(0, 0);
      // }
    } catch (err) {
      NotificationManager.error("There's an error. Please, check your input data.");
    } finally {
      postProfile();
      store.setLoading(false);
    }
  };

  const onSumbitAutoSaved = (index) => {
    setPageTab(index);
    if (profileCopy !== profile) {
      onSubmit();
    }
  };

  const sendKYCRequest = async (ev) => {
    const result = await fetch("/api/v1/investors/create_kyc_request/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });

    if (result.status !== 200) {
      // TODO обработать ошибки
    }
  };
  const sendAccreditationRequest = async (ev) => {
    const result = await fetch("/api/v1/investors/create_accreditation_request/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });

    if (result.status !== 200) {
      // TODO обработать ошибки
    }
  };

  return (
    <>
      {store.profile.isExtraAccount && <Redirect to="/settings/accounts" />}
      {profile.id && (
        <form className={classes.forms}>
          <Box style={{ display: "flex" }}>
            <a
              style={{ display: "flex", margin: "8px" }}
              href="#"
              onClick={() => onSumbitAutoSaved(0)}
              className={tab === 0 ? classes.links : ""}
            >
              {getText("General information")}
            </a>
            <a
              style={{ display: "flex", margin: "8px" }}
              href="#"
              onClick={() => onSumbitAutoSaved(1)}
              className={tab === 1 ? classes.links : ""}
            >
              {profile.entity_type === "Entity" ?
                getText("Company data") :
                getText("Personal data")}
            </a>
            {profile.entity_type === "Legal" && (
              <a
                style={{ display: "flex", margin: "8px" }}
                href="#"
                onClick={() => onSumbitAutoSaved(2)}
                className={tab === 2 ? classes.links : classes.link}
              >
                {getText("Company data")}
              </a>
            )}
            <a
              style={{ display: "flex", margin: "8px" }}
              href="#"
              onClick={() => onSumbitAutoSaved(3)}
              className={tab === 3 ? classes.links : ""}
            >
              {getText("Investments information")}
            </a>
            {profile.entity_type === "Legal" && (
              <>
                <a
                  style={{ display: "flex", margin: "8px" }}
                  href="#"
                  onClick={() => onSumbitAutoSaved(4)}
                  className={tab === 4 ? classes.links : classes.link}
                >
                  {getText("UBO")}
                </a>
                <a
                  style={{ display: "flex", margin: "8px" }}
                  href="#"
                  onClick={() => onSumbitAutoSaved(5)}
                  className={tab === 5 ? classes.links : classes.link}
                >
                  {getText("Shareholders")}
                </a>
                <a
                  style={{ display: "flex", margin: "8px" }}
                  href="#"
                  onClick={() => onSumbitAutoSaved(6)}
                  className={tab === 6 ? classes.links : classes.link}
                >
                  {getText("Directors")}
                </a>
              </>
            )}
            {/* <a
              style={{ display: "flex", margin: "8px" }}
              href="#"
              onClick={() => onSumbitAutoSaved(7)}
              className={tab === 7 ? classes.links : ""}
            >
              {getText("Accreditation")}
            </a> */}
          </Box>
          {tab === 0 && <GeneralInformation handleChange={handleChange} profile={profile} />}
          {tab === 1 && (
            <PersonalData
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              profile={profile}
              handleChangeAutoComplete={handleChangeAutoComplete}
              handleChangeSelect={handleChangeSelect}
            />
          )}
          {tab === 2 &&
            (profile.entity_type !== "Legal" ? setTab(tab + 1) : <CompanyData handleChange={handleChange} profile={profile} />)}
          {tab === 3 && (
            <InvestmentsInformation handleChange={handleChange} profile={profile} handleChangeSelect={handleChangeSelect} />
          )}
          {tab === 4 &&
            (profile.entity_type !== "Legal" ? (
              setTab(tab + 1)
            ) : (
              <UBO handleChange={handleChange} profileUBO={profileUBO} profile={profile} fetchUBO={fetchUBO} />
            ))}
          {tab === 5 &&
            (profile.entity_type !== "Legal" ? (
              setTab(tab + 1)
            ) : (
              <Shareholders
                handleChange={handleChange}
                profile={profile}
                profileShareholder={profileShareholder}
                fetchShareholder={fetchShareholder}
              />
            ))}
          {tab === 6 &&
            (profile.entity_type !== "Legal" ? (
              setTab(tab + 1)
            ) : (
              <Directors
                handleChange={handleChange}
                profile={profile}
                profileDirector={profileDirector}
                fetchDirector={fetchDirector}
              />
            ))}
          {tab === 7 && <Accreditation handleChange={handleChange} profile={profile} kyc={store.profile} />}
          <Box mt={2} />
          <PopupPaper inline>
            <Box mt={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    type="button"
                    onClick={onSubmit}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    {getText("save")}
                  </Button>
                  {tab === 7 && profile.is_kyc_checked && !profile.accreditation_envelope_created && !profile.is_accredited && (
                    <Box style={{ marginTop: "16px" }}>
                      <Button
                        type="button"
                        fullWidth
                        size="large"
                        onClick={sendAccreditationRequest}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {getText("send_accreditation_request")}
                      </Button>
                    </Box>
                  )}
                  {tab === 7 && profile.is_kyc_checked && profile.accreditation_envelope_created && !profile.is_accredited && (
                    <Box style={{ marginTop: "16px" }}>{getText("send_email")}</Box>
                  )}
                  {tab === 7 && profile.is_kyc_checked && profile.is_accredited && (
                    <Box style={{ marginTop: "16px" }}>{getText("accredited_done")}</Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </PopupPaper>
        </form>
      )}
    </>
  );
});
