import React, { useState, useEffect } from "react";
import { Select, Checkbox, Input, MenuItem, ListItemText, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useLanguage from "../languages";

const useStyles = makeStyles({
  label: {
    fontSize: 14,
  },
  text: {
    whiteSpace: "break-spaces",
  },
});

const menuSelectProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxWidth: 600,
      maxHeight: 600,
    },
  },
  getContentAnchorEl: () => null,
};
export default function MultiSelect({ handleChangeSelect, profile, type, moreText, select, error, dataattribute, required }) {
  const classes = useStyles();
  const getText = useLanguage();

  const [investment, setInvestment] = useState([...profile]);

  useEffect(() => {
    handleChangeSelect(type, investment);
  }, [investment]);

  return (
    <>
      <InputLabel className={classes.label} required={required}>
        {getText(`${type}__label`)}
      </InputLabel>
      <Select
        multiple
        value={investment}
        onChange={(event) => setInvestment(event.target.value)}
        input={<Input data-castil={dataattribute} />}
        renderValue={(selected) =>
          moreText
            ? `${selected.length}/${select[type].length}`
            : selected.map((item, i, arr) =>
                arr.length - 1 === i ? <>{getText(`${type}.${item}`)}</> : <>{getText(`${type}.${item}`)}, </>
              )
        }
        fullWidth
        error={error}
        MenuProps={menuSelectProps}
      >
        <MenuItem />
        {select[type].map((el) => (
          <MenuItem key={el.value} value={el.value}>
            <Checkbox checked={investment && investment.indexOf(el.value) > -1} color="primary" />
            <ListItemText className={classes.text} primary={getText(`${el.label}`)} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
