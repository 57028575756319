import React, { useState } from "react";
import { Button, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import PopupPaper from "../../../components/PopupPaper";
import { useStore } from "../../../store/index";
import useLanguage from "../../../languages";
import { AutocompleteMaterial } from "../../../components/AutocompleteMaterial";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      background: "#FFFFFF",
      display: "block",
    },
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
  },
  button: { margin: "25px 0" },
}));

const initialState = {
  residence_country: "",
  citizenship: "",
};

const BLOCKED_COUNTRIES = ['RU', 'BY'] // РФ, Белоруссия

const US_COUNTRIES = [
  'GB', // United Kingdom
  'AU', // Australia
  'IL', // Israel
  'US', // USA
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
]

export const SignUpCountryEntity = () => {
  const classes = useStyles();
  const store = useStore();
  const getText = useLanguage();
  const history = useHistory();

  const [storage, setStorage] = useState(initialState);

  const handleChange = (type, data) => {
    setStorage({ ...storage, [type]: data });
  };

  const handleLClick = () => {
    // constants
    const LOCAL_HOSTS = ['localhost', '127.0.0.1']

    const DOMAIN_PREFIX = window.location.hostname.split('.')[0]

    const DEV_DOMAIN_PREFIX = DOMAIN_PREFIX === 'sandbox'
      || LOCAL_HOSTS.includes(DOMAIN_PREFIX)

    const US_DOMAIN_PREFIX = DEV_DOMAIN_PREFIX ? 'sandbox' : 'us'
    const AE_DOMAIN_PREFIX = DEV_DOMAIN_PREFIX ? 'sandbox' : 'ae'

    // utils
    const constructRedirectUrl = (_prefix, params) => {
      const isLocalhost = LOCAL_HOSTS.includes(window.location.hostname)

      const host = isLocalhost ? window.location.host : '.altaclub.vc'
      const prefix = isLocalhost ? '' : _prefix

      const urlParts = [
        `${window.location.protocol}//`,
        prefix,
        host,
        '/sign-up',
        '?language=en'
      ]

      for (const param in params) {
        if (params[param]) {
          urlParts.push(`&${param}=${params[param]}`)
        }
      }

      const url = urlParts.join('')

      localStorage.clear()

      return url
    }

    // common variables

    const { company_country, jurisdiction_of_organizaton } = storage
    const params = { company_country, jurisdiction_of_organizaton, entity_type: localStorage.getItem('userType') }

    // conditional variables

    // const IS_US_INSTANCE = DOMAIN_PREFIX === 'us' || DOMAIN_PREFIX === 'sandbox'
    // const IS_AE_INSTANCE = DOMAIN_PREFIX === 'ae' || DOMAIN_PREFIX === 'sandbox'

    // const IS_US_COUNTRY = US_COUNTRIES.includes(company_country) &&
    //   US_COUNTRIES.includes(jurisdiction_of_organizaton)

    const IS_BLOCKED_COUNTRY = BLOCKED_COUNTRIES.includes(company_country) ||
      BLOCKED_COUNTRIES.includes(jurisdiction_of_organizaton)

    const IS_COUNTRY_FILLED = company_country && jurisdiction_of_organizaton

    // business logic
    if (IS_BLOCKED_COUNTRY) {
      return
    }

    if (!IS_COUNTRY_FILLED) {
      return
    }

    // if (IS_US_COUNTRY) {

    //   if (!IS_US_INSTANCE) {
    //     window.location.href = constructRedirectUrl(US_DOMAIN_PREFIX, params)

    //     return
    //   }

    // } else if (!IS_AE_INSTANCE) {
    //   // window.location.href = constructRedirectUrl(AE_DOMAIN_PREFIX, params)

    //   return
    // }

    // continue
    localStorage.setItem("userCountry", JSON.stringify(storage));
    history.push("/sign-up");
  };

  return (
    <Container className={classes.root}>
      <PopupPaper popup caption="Please provide current information">
        <Box display="flex" flexDirection="column" justifyContent="space-between" mt={2}>
          <Box mt={2}>
            <AutocompleteMaterial
              flag
              options={
                store.citizenships_en
                  .map((code) => ({ label: getText(`countries.${code}`), value: code }))
                  .filter((item) => !BLOCKED_COUNTRIES.includes(item.value))
              }
              text="jurisdiction_of_organizaton"
              title="Jurisdiction of organisation"
              value={storage?.jurisdiction_of_organizaton}
              onChange={handleChange}
            />
          </Box>
          <Box mt={2}>
            <AutocompleteMaterial
              flag
              options={
                store.citizenships_en
                  .map((code) => ({ label: getText(`countries.${code}`), value: code }))
                  .filter((item) => !BLOCKED_COUNTRIES.includes(item.value))
              }
              text="company_country"
              title="Country of formation"
              value={storage?.company_country}
              onChange={handleChange}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleLClick}
            disabled={
              !storage.company_country ||
              !storage.jurisdiction_of_organizaton
            }
          >
            Next
          </Button>
        </Box>
      </PopupPaper>
    </Container>
  );
};
