import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { makeStyles } from "@material-ui/styles";
import get from "lodash/get";
import { transparent } from "material-ui/styles/colors";
import {
  Table, TableHead, TableRow, TableCell, TableBody,
  Box, IconButton, Collapse, Typography, RadioGroup,
  Radio, FormControlLabel, Grid
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { NotificationManager } from "react-notifications";
import { Link as RouterLink } from "react-router-dom";
import urls, { useAsyncEndpoint } from "../urls";
import PopupPaper from "./PopupPaper";
import SexyLink from "./SexyLink";


const useReportsInstances = () =>
  useAsyncEndpoint(() => ({
    url: urls.reportInstances(),
    method: "GET"
  }))

const useReportByInstance = () =>
  useAsyncEndpoint(() => ({
    url: urls.fullChartReport(),
    method: "GET"
  }))

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: "#a6d591",
  },
  roiUp: {
    color: "#00a152",
  },
  roiDown: {
    color: "#f44336",
  },
  exitRow: {
    color: "#2356B2",
  },
  tableWrapper: {
    maxWidth: "100vw",
    overflowX: "auto"
  },
  chartWrapper: {
    margin: "50px auto 0 auto",
  },
  "@media screen and (max-width: 1000px)": {
    chartWrapper: {
      margin: "0",
    }
  }
}));

const formatMoney = (amount) => {
  if (amount) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return '0'
}

const Row = (props) => {
    const { item, grossProceed } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const getRoiClassName = (amount) => amount > 0 ? classes.roiUp : classes.roiDown
    const getRowClassName = (item) => item.totalExit > 0 ? classes.exitRow : ""

    if (grossProceed) {
      return (
        <>
          <TableRow
            sx={{ '& > *': { borderBottom: 'unset' } }}
          >
            <TableCell>
              {item.history.length > 0 && item.history.some(item => item.is_exit) &&
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              }
            </TableCell>
            <TableCell className={getRowClassName(item)}>{ item.startup }</TableCell>
            <TableCell className={getRowClassName(item)}>$ {`${formatMoney(item.totalInvestment)}`}</TableCell>
            <TableCell className={getRowClassName(item)}>$ {`${formatMoney(item.totalExit)}`}</TableCell>
            <TableCell className={getRoiClassName(item.totalPps)}>{`${formatMoney(item.totalPps)}`}%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    History
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Investment</TableCell>
                        <TableCell>Estimation</TableCell>
                        <TableCell>ROI</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.history
                        .filter((historyRow) => !historyRow.is_dividends)
                        .filter((historyRow) => historyRow.is_exit)
                        .map((historyRow) => (
                          <TableRow>
                            <TableCell>{new Date(historyRow.op_date).toLocaleDateString('en-US')}</TableCell>
                            <TableCell>$ {`${formatMoney(historyRow.investment)}`}</TableCell>
                            <TableCell>$ {`${formatMoney(historyRow.estimation)}`}</TableCell>
                            <TableCell className={getRoiClassName(historyRow.pps * 100)}>{`${formatMoney(historyRow.pps * 100)}`} %</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )
    }

    return (
      <>
        <TableRow
          sx={{ '& > *': { borderBottom: 'unset' } }}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell className={getRowClassName(item)}>
            { item.startup }

            {(item.hasNews || item.hasArticle) &&
              <SexyLink style={{"margin-left": "0.5rem"}} component={RouterLink} to={`/news?page=1&startup=${item.startup}`}>
                Startup news
              </SexyLink>
            }
          </TableCell>
          <TableCell className={getRowClassName(item)}>$ {`${formatMoney(item.totalInvestment)}`}</TableCell>
          <TableCell className={getRowClassName(item)}>$ {`${formatMoney(item.totalEstimation)}`}</TableCell>
          <TableCell className={getRowClassName(item)}>$ {`${formatMoney(item.totalExit)}`}</TableCell>
          <TableCell className={getRoiClassName(item.totalPps)}>{`${formatMoney(item.totalPps)}`}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Investment</TableCell>
                      <TableCell>Estimation</TableCell>
                      <TableCell>ROI</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.history
                      .filter((historyRow) => !historyRow.is_exit)
                      .filter((historyRow) => !historyRow.is_dividends)
                      .map((historyRow) => (
                        <TableRow>
                          <TableCell>{new Date(historyRow.op_date).toLocaleDateString('en-US')}</TableCell>
                          <TableCell>$ {`${formatMoney(historyRow.investment)}`}</TableCell>
                          <TableCell>$ {`${formatMoney(historyRow.estimation)}`}</TableCell>
                          <TableCell className={getRoiClassName(historyRow.pps * 100)}>{`${formatMoney(historyRow.pps * 100)}`} %</TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
}

const Echarts = () => {
  const classes = useStyles();

  const [reportInstances, fetchReportInstances] = useReportsInstances();
  const [reportByInstance, fetchReportByInstance] = useReportByInstance();

  const [exitsSum, setExitsSum] = useState();
  const [selectedVolume, selectVolume] = useState();
  const [selectedReport, selectReport] = useState();
  const [currentVolume, setCurrentVolume] = useState('all');
  const [currentInstance, setCurrentInstance] = useState('all');
  const [series, setSeries] = useState();
  const [value, setValue] = useState([]);
  const [roi, setRoi] = useState();
  const [portfolio, setPortfolio] = useState();
  const [exits, setExits] = useState();

  const [operations, setOperations] = useState();

  const [cashOperations, setCashOperations] = useState();

  const [portfolioInvestment, setPortfolioInvestment] = useState();

  const [portfolioEstimation, setPortfolioEstimation] = useState();

  const [cashAssets, setCashAssets] = useState();

  const [category, setCategory] = useState();

  const [detailData, setDetailData] = useState({
    zoomStart: 0,
    zoomEnd: 100,
    items: [],
    cashAssets: 0,
    cashOperations: {
      pending_transactions: [],
      pending_transactions_total: 0,
      prepaid_expenses: 0
    },
    exits: []
  });

  useEffect(() => {
    const series = get(selectedVolume, "data");
    setSeries(series);
  }, [selectedVolume]);

  const data = useCallback(() => {
    const category = [];
    const chartPoints = [];
    const roiArr = [];
    const portfolioArr = [];
    const operationsArr = [];
    const cashOperationsArr = [];
    const portfolioInvestmentArr = [];
    const portfolioEstimationArr = [];
    const cashAssetsArr = [];
    const exitsArr = [];
    const exitSumArr = [];

    if (series) {
      for (const [year, quartal] of Object.entries(series)) {
        for (const [quartalNumber, info] of Object.entries(quartal)) {
          const {
            total_investment,
            total_estimation,
            startup_estimation,
            cash_operations,
            investment_in_portfolio,
            estimation_in_portfolio,
            cash_assets,
            exits,
            exits_sum
          } = info;
          portfolioArr.push(total_estimation);
          category.push(`${year}\n${quartalNumber}`);
          operationsArr.push(startup_estimation);
          cashOperationsArr.push(cash_operations)
          portfolioInvestmentArr.push(investment_in_portfolio)
          portfolioEstimationArr.push(estimation_in_portfolio)
          cashAssetsArr.push(cash_assets)

          let exitInvestments = 0

          const _exits = exits.map((item) => {
            const newItem = { ...item }
            const [startupData] = startup_estimation.filter((startupItem) => startupItem.startup === item.startup)

            if (startupData) {
              newItem.investment = startupData.total_investment
              exitInvestments += startupData.total_investment
              newItem.pps = startupData.pps
            }

            return newItem
          })

          const isCurrentVolumeExits = currentVolume === 'exits'

          let chartPointValue = total_investment

          if (!isCurrentVolumeExits) {
            chartPointValue = chartPointValue - exits_sum + exitInvestments
          }
          
          chartPoints.push(chartPointValue);
          roiArr.push((total_estimation / chartPointValue - 1) * 100);

          exitsArr.push(_exits)
          exitSumArr.push(exits_sum)
        }
      }
    }

    category[category.length - 1] = "Today"

    setRoi(roiArr);
    setValue(chartPoints);
    setCategory(category);
    setPortfolio(portfolioArr);
    setOperations(operationsArr);
    setCashOperations(cashOperationsArr)
    setPortfolioInvestment(portfolioInvestmentArr)
    setPortfolioEstimation(portfolioEstimationArr)
    setCashAssets(cashAssetsArr)
    setExits(exitsArr)
    setExitsSum(exitSumArr)
  }, [series]);

  useEffect(() => {
    data();
  }, [series]);

  useEffect(() => {
    const isPointDisplayed = document
      .querySelector(`[data-detail-point-id="point-detail-wrapper"]`)
      .style.display !== "none"
    
    const isDataLoaded = roi && roi.length > 0

    if (isPointDisplayed) {
      const quarterName = document
        .querySelector(`[data-detail-point-id="quarter-name"]`)
        .innerText
      
      const quarterDataIndex = document
        .querySelector(`[data-detail-point-id="dataIndex"]`)
        .value
      
      setTimeout(() => renderDetailPoint(quarterDataIndex, quarterName), 100)
    } else if (isDataLoaded) {
      renderDetailPoint(roi.length - 1, "Today")
    }
  }, [roi, value, portfolio, portfolioInvestment, portfolioEstimation])

  useEffect(() => {
    fetchReportInstances();
    fetchReportByInstance();
  }, []);

  useEffect(() => {
    if (!reportByInstance.complete) {
      return
    }

    if (!reportByInstance.error) {
      changeReportInstance({ target: { value: currentInstance } })
    } else {
      selectVolume({
        data: [],
        error: true,
        error_message: "Will be availible soon. Please, check Investment Portfolio Summary for current details."
      })

      NotificationManager.error(
        "Will be availible soon. Please, check Investment Portfolio Summary for current details."
      )
    }
  }, [reportInstances, reportByInstance])

  let echartsInstance = useRef()

  useEffect(() => {
    const instance = echartsInstance.getEchartsInstance()

    if (detailData.zoomStart >= 0 && detailData.zoomEnd > 0) {
      instance.dispatchAction({
        type: 'dataZoom',
        // percentage of starting position; 0 - 100
        start: detailData.zoomStart,
        // percentage of ending position; 0 - 100
        end: detailData.zoomEnd,
        disableTrigger: true
      }, true)
    }
  }, [detailData])

  const getInvestmentMaxValue = (array) => {
    const amount = Math.max.apply(null, array)

    const digitsNumber = amount.toLocaleString('en-US').split(',').length + 2

    return Math.ceil(amount / 10**digitsNumber) * 10**digitsNumber + 10**5
  }

  const getDataZoomOption = () => {
    const isMobileWidth = window.innerWidth < 1000

    if (isMobileWidth) {
      return [
        {
          type: 'slider',
          show: true,
          xAxisIndex: [0],
          start: 65,
          end: 100
        },
        {
          type: 'inside',
          xAxisIndex: [0],
          start: 65,
          end: 100
        }
      ]
    }

    return [
      {
        type: 'slider',
        show: true,
        realtime: false
      }
    ]
  }

  const getTooltipTriggerOption = () => {
    const isMobileWidth = window.innerWidth < 1000

    if (isMobileWidth) {
      return "none"
    }

    return "axis"
  }

  const showTableByOperations = (sliceIndexes, start = 0, end = 0) => {
    const operationsSlice = operations.slice(...sliceIndexes)
    const cashOperationsSlice = cashOperations.slice(...sliceIndexes)

    const operationsObj = {
      // startupName: {
      //   history: [], // operations arr
      //   totalInvestment: 123,
      //   totalPortfolio: 123,
      //   totalRoi: 123,
      //   percentage: 123
      // }
    }

    const cashOperationsObj = {
      pending_transactions: [],
      pending_transactions_total: 0,
      prepaid_expenses: 0
    }

    for (const operation of operationsSlice) {
      for (const operationItem of operation) {
        if (!operationsObj[operationItem.startup]) {
          operationsObj[operationItem.startup] = {
            history: [],
            totalInvestment: 0,
            totalEstimation: 0,
            totalPps: 0,
            percentage: 0,
            totalExit: 0,
            isLiquidated: false,
            isFullExit: false,
            hasNews: false,
            hasArticle: false
          } 
        }

        for (const op of operationItem.operations) {
          const hasDuplicate = operationsObj[operationItem.startup].history.some(
            (item) => JSON.stringify(item) === JSON.stringify(op)
          )

          if (!hasDuplicate) {
            operationsObj[operationItem.startup].history.push(op)
          }
        }

        operationsObj[operationItem.startup].totalInvestment = operationItem.total_investment
        operationsObj[operationItem.startup].totalEstimation = operationItem.total_estimation
        operationsObj[operationItem.startup].totalExit = operationItem.total_exit
        operationsObj[operationItem.startup].totalPps = operationItem.pps * 100
        operationsObj[operationItem.startup].isLiquidated = operationItem.is_liquidated
        operationsObj[operationItem.startup].isFullExit = operationItem.is_full_exit
        operationsObj[operationItem.startup].hasNews = operationItem.has_news
        operationsObj[operationItem.startup].hasArticle = operationItem.has_article
      }
    }

    const preparedOperationsArr = []

    // eslint-disable-next-line guard-for-in
    for (const operation in operationsObj) {
      preparedOperationsArr.push({...operationsObj[operation], startup: operation})
    }

    for (const cashOperation of cashOperationsSlice) {
      for (const pendingTransaction of cashOperation.pending_transactions) {
        cashOperationsObj.pending_transactions_total += pendingTransaction.value_usd
      }

      cashOperationsObj.pending_transactions.push(...cashOperation.pending_transactions)
      cashOperationsObj.prepaid_expenses += cashOperation.prepaid_expenses
    }

    const [_cashAssets] = cashAssets.slice(...sliceIndexes)
    const exitsArr = exits.slice(...sliceIndexes)
    const exitSumArr = exitsSum.slice(...sliceIndexes)

    setDetailData({
      zoomStart: start,
      zoomEnd: end,
      cashAssets: _cashAssets,
      items: preparedOperationsArr,
      cashOperations: cashOperationsObj,
      exits: exitsArr,
      exitsSum: exitSumArr
    })
  }

  const detailChart = (data, component) => {
    const { start, end, disableTrigger } = data

    if (disableTrigger) return

    const { endValue } = component.getOption().dataZoom[0]

    const sliceIndexes = [endValue, endValue + 1]

    showTableByOperations(sliceIndexes, start, end)
  }

  const renderDetailPoint = (dataIndex, name) => {
    const pointObj = {
      estimation: portfolio[dataIndex],
      investedAmount: portfolioInvestment[dataIndex],
      portfolioValuation: portfolioEstimation[dataIndex],
      investment: value[dataIndex],
      roi: roi[dataIndex]
    }

    // eslint-disable-next-line
    for (const key in pointObj) {
      document
        .querySelector(`[data-detail-point-id="${key}"]`)
        .innerHTML = formatMoney(pointObj[key])
    }

    document
      .querySelector(`[data-detail-point-id="quarter-name"]`)
      .innerHTML = name
    
    document
      .querySelector(`[data-detail-point-id="dataIndex"]`)
      .value = dataIndex

    document
      .querySelector(`[data-detail-point-id="point-detail-wrapper"]`)
      .style.display = ""
  }

  const drawDetailPoint = (data) => {
    const { dataIndex, name } = data

    renderDetailPoint(dataIndex, name)
  }

  useEffect(() => {
    if (operations && operations.length) {
      showTableByOperations([operations.length - 1, operations.length])
    }
  }, [operations])

  const changeVolume = (data) => {
    const volumes = {
      without: selectedReport.use_none,
      pending: selectedReport.use_pending,
      all: selectedReport.use_both,
      exits: {}
    }

    const isExits = data.target.value === 'exits'

    if (isExits) {
      const _newVolume = JSON.parse(JSON.stringify(volumes.all))

      const { data } = _newVolume

      for (const year of Object.keys(data)) {
        for (const quarter of Object.keys(data[year])) {
          const info = data[year][quarter]

          const {
            exits_sum,
          } = info

          _newVolume.data[year][quarter].total_investment -= exits_sum
          _newVolume.data[year][quarter].total_estimation -= exits_sum

          const { total_investment, total_estimation } = _newVolume.data[year][quarter]

          _newVolume.data[year][quarter].pps = total_estimation / total_investment - 1
        }
      }

      volumes.exits = JSON.parse(JSON.stringify({ data: _newVolume.data }))
    }

    const newVolume = volumes[data.target.value]

    setCurrentVolume(data.target.value)
    selectVolume({ data: newVolume })
  }

  const changeReportInstance = (data) => {
    const instance = data.target.value

    const isAll = instance === 'all'

    const isDataUndefined = Array.isArray(reportByInstance.data) && !reportByInstance.data.length

    if (isDataUndefined) {
      selectReport({
        use_pending: {},
        use_both: {},
        use_none: {}
      })

      return
    }

    let newSelectedReport = reportByInstance.data.report[instance]

    if (isAll) {
      const _report = {
        use_pending: {},
        use_both: {},
        use_none: {}
      }

      const reportByInstanceData = JSON.parse(JSON.stringify(reportByInstance.data))

      // идём по каждому из инстансов
      for (const reportForInstance in reportByInstanceData) {
        // проверяем, что отчёт по нему -- существует
        if (reportByInstanceData[reportForInstance]) {
          // идём по каждому волюму внутри инстанса
          for (const reportVolume in reportByInstanceData[reportForInstance]) {
            // если отчёт внутри волюма существует, то пойдём дальше
            if (reportByInstanceData[reportForInstance][reportVolume]) {
              // запишем его в отдельную переменную, чтобы было удобнее к ней обращаться
              const reportData = JSON.parse(JSON.stringify(reportByInstanceData[reportForInstance][reportVolume]))

              // вытаскиваем ключи волюмов - use_pending, use_both, use_none
              for (const volumeType in reportData) {
                // если внутри ключа есть данные, то пойдём дальше
                if (reportData[volumeType]) {
                  // двигаемся по каждому году внутри волюма
                  for (const year in reportData[volumeType]) {
                    if (reportData[volumeType][year]) {
                      // проходимся по каждому кварталу внутри года
                      for (const quarter in reportData[volumeType][year]) {
                        // если по кварталу есть данные, то пойдём дальше
                        if (reportData[volumeType][year][quarter]) {
                          // выпишем данные по году в отдельную переменную
                          const yearData = reportData[volumeType][year]
                          // выпишем данные по кварталу в отдельную переменную
                          const quarterData = yearData[quarter]

                          // если в формирующемся отчёте нет данных по году
                          if (!_report[volumeType][year]) {
                            // то полностью их туда перепишем
                            _report[volumeType][year] = yearData
                          // если же они есть, то будем проверять каждый квартал по той же логике
                          // проверяем есть ли данные по кварталу
                          } else if (!_report[volumeType][year][quarter]) {
                            // если их нет, то полностью записываем
                            _report[volumeType][year][quarter] = quarterData
                          } else if (_report[volumeType][year][quarter].total_estimation !== quarterData.total_estimation) {
                            // если они есть, то будем дополнять
                            _report[volumeType][year][quarter].cash_assets += quarterData.cash_assets
                            _report[volumeType][year][quarter].estimation_in_portfolio += quarterData.estimation_in_portfolio
                            _report[volumeType][year][quarter].exits_sum += quarterData.exits_sum
                            _report[volumeType][year][quarter].investment_in_portfolio += quarterData.investment_in_portfolio
                            _report[volumeType][year][quarter].total_estimation += quarterData.total_estimation
                            _report[volumeType][year][quarter].total_investment += quarterData.total_investment
                            _report[volumeType][year][quarter].startup_estimation = [
                              ..._report[volumeType][year][quarter].startup_estimation,
                              ...quarterData.startup_estimation
                            ]
                            _report[volumeType][year][quarter].exits = [
                              ..._report[volumeType][year][quarter].exits,
                              ...quarterData.exits
                            ]
                            _report[volumeType][year][quarter].cash_operations = {
                              obligations: _report[volumeType][year][quarter].cash_operations.obligations + quarterData.obligations,
                              prepaid_expenses: _report[volumeType][year][quarter].cash_operations.prepaid_expenses + quarterData.prepaid_expenses,
                              pending_transactions: [
                                ..._report[volumeType][year][quarter].cash_operations.pending_transactions,
                                ...quarterData.cash_operations.pending_transactions
                              ]
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      newSelectedReport = JSON.parse(JSON.stringify(_report))

      _report.use_pending = {}
      _report.use_both = {}
      _report.use_none = {}
    }

    setCurrentInstance(instance)
    selectReport(newSelectedReport)

    document
      .querySelector(`[data-detail-point-id="point-detail-wrapper"]`)
      .style.display = 'none'
  }

  useEffect(() => {
    if (selectedReport) {
      changeVolume({ target: { value: currentVolume } })
    }
  }, [selectedReport])

  useEffect(() => {
    const labels = ['estimation', 'investedAmount', 'portfolioValuation', 'investment', 'roi']

    const labelsByVolume = {
      "all": [
        "Valuation of current portfolio and exits (including pending deals and cash)",
        "Current portfolio invested amount (including pending deals and cash)",
        "Portfolio valuation (including pending deals and cash)",
        "Total invested amount incl. exits and write-offs of securities (including pending deals and cash)",
        "ROI (Return on investment including pending deals and cash)"
      ],
      "pending": [
        "Valuation of current portfolio and exits (including pending deals)",
        "Current portfolio invested amount (including pending deals)",
        "Portfolio valuation (including pending deals)",
        "Total invested amount incl. exits and write-offs of securities (including pending deals)",
        "ROI (Return on investment including pending deals)"
      ],
      "without": [
        "Valuation of current portfolio and exits (without cash and pending deals)",
        "Current portfolio invested amount (without cash and pending deals)",
        "Portfolio valuation (without cash and pending deals)",
        "Total invested amount incl. exits and write-offs of securities (without cash and pending deals)",
        "ROI (Return on investment without cash and pending deals)"
      ],
      "exits": [
        "Valuation of current portfolio and exits (including pending deals and cash, excluding exits)",
        "Current portfolio invested amount (including pending deals and cash, excluding exits)",
        "Portfolio valuation (including pending deals and cash, excluding exits)",
        "Total invested amount incl. exits and write-offs of securities (including pending deals and cash, excluding exits)",
        "ROI (Return on investment including pending deals and cash, excluding exits)"
      ]
    }

    for (const label of labels) {
      const index = labels.indexOf(label)
      document.querySelector(`[data-detail-point-id="${label}-title"]`).innerHTML = labelsByVolume[currentVolume][index]
    }

  }, [currentVolume])

  const options = {
    grid: { top: 40, right: 45, bottom: 45, left: 70 },
    xAxis: {
      type: "category",
      data: category,
      axisLabel: {
        interval: 0
      },
      axisLine: { onZero: false },
      axisTick: {
        inside: true,
        alignWithLabel: true,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "Investment, $",
        nameTextStyle: {
          color: "#374464",
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            color: "#374464",
          },
        },
        min: 0,
        max: () => {
          if (!value.length) {
            return 10**6
          }

          return getInvestmentMaxValue(value)
        }
      },
      {
        type: "value",
        name: "ROI, %",
        nameTextStyle: {
          color: "#374464",
        },
        axisLabel: {
          interval: "0",
          textStyle: {
            color: "#374464",
          },
        },
        splitLine: {
          lineStyle: {
            color: ["#b9c2c9"],
          },
        },
        splitNumber: 5,
      },
    ],
    series: [
      {
        name: "investment",
        data: value,
        type: "line",
        smooth: true,
        color: "#2356B2",
        // portfolio + exits investment
      },
      {
        name: "roi",
        data: roi,
        type: "line",
        smooth: true,
        color: "#1a497b",
        yAxisIndex: 1,
        // roi
      },
      {
        name: "portfolio",
        data: portfolio,
        type: "line",
        showSymbol: false,
        color: transparent
        // portfolio + exits valuation
      },
      {
        name: "portfolioInvestment",
        data: portfolioInvestment,
        type: "line",
        showSymbol: false,
        color: transparent
        // investment in portfolio
      },
      {
        name: "portfolioEstimation",
        data: portfolioEstimation,
        type: "line",
        showSymbol: false,
        color: transparent
        // estimation in portfolio
      },
    ],
    tooltip: {
      trigger: getTooltipTriggerOption(),
      formatter(params) {
        return `
          ${params[0].axisValueLabel}
          <br/>
          <div>${params[2].marker}</div> Portfolio+Exits. Valuation: $ ${formatMoney(params[2].value)}
          <br/>${params[0].marker} Portfolio+Exits. Investment: $ ${formatMoney(params[0].value)}
          <br/>${params[1].marker} ROI: ${params[1].value.toFixed(2)} %
          <br/>Portfolio. Investment: $ ${formatMoney(params[3].value)}
          <br/>Portfolio. Valuation: $ ${formatMoney(params[4].value)}`;
      },
    },
    dataZoom: getDataZoomOption()
  };

  function getPortfolioSummaryLink() {
    const [instance] = window.location.host.split('.')

    const [currDate] = new Date().toISOString().split('T')

    return `/pdfReport?instance=${instance}&reportType=investor&endDate=${currDate}`
  }

  return (
    <div>
      <Box style={{"position": "relative"}}>
        <ReactECharts
          option={options}
          className={classes.chartWrapper}
          theme="calendar"
          onEvents={{ dataZoom: detailChart, click: drawDetailPoint, mouseMove: drawDetailPoint }}
          ref={(e) => { echartsInstance = e }}
        />

        {selectedVolume && selectedVolume.error &&
          <Box style={{
            "position": "absolute",
            "top": "0",
            "background": "rgba(0, 0, 0, 0.4)",
            "color": "white",
            "font-size": "22px",
            "width": "calc(100% + 15px)",
            "height": "calc(100% + 15px)",
            "display": "flex",
            "justify-content": "center",
            "align-items": "center",
            "border-radius": "20px",
          }}>
            <p>
              Will be availible soon. Please, check Investment Portfolio Summary for current details.
            </p>
          </Box>
      }
      </Box>

      <Box mt={3.5} className={classes.tableWrapper}>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item sm={12} lg={6}>
              <PopupPaper>
                <Box p={2.5}>
                  <RadioGroup
                    className={classes.radioGroup}
                    onChange={changeVolume}
                    defaultValue="all"
                  >
                    <FormControlLabel
                      value="without" control={<Radio color="primary" />}
                      label="Calculate ROI without cash and pending deals"
                      disabled={selectedVolume && selectedVolume.error}
                    />
                    <FormControlLabel
                      value="pending" control={<Radio color="primary" />}
                      label="Calculate ROI including pending deals"
                      disabled={selectedVolume && selectedVolume.error}
                    />
                    <FormControlLabel
                      value="exits" control={<Radio color="primary" />}
                      label="Calculate ROI without exits"
                      disabled={selectedVolume && selectedVolume.error}
                    />
                    <FormControlLabel
                      value="all" control={<Radio color="primary" />}
                      label="Calculate ROI including pending deals and cash"
                      disabled={selectedVolume && selectedVolume.error}
                    />
                  </RadioGroup>
                </Box>
              </PopupPaper>
            </Grid>

            <Grid item sm={12} lg={6}>
              <PopupPaper>
                <Box p={2.5}>
                  <RadioGroup
                    className={classes.radioGroup}
                    onChange={changeReportInstance}
                    defaultValue={currentInstance}
                  >
                    <FormControlLabel
                      value="bo" control={<Radio color="primary" />}
                      label="Show BVI portfolio"
                      // disabled={reportInstances.data.includes('bo')}
                    />
                    <FormControlLabel
                      value="us" control={<Radio color="primary" />}
                      label="Show USA portfolio"
                      // disabled={reportInstances.data.includes('us')}
                    />
                    <FormControlLabel
                      value="ac2" control={<Radio color="primary" />}
                      label="Show AC II portfolio"
                      // disabled={reportInstances.data.includes('ac2')}
                    />
                    <FormControlLabel
                      value="all" control={<Radio color="primary" />}
                      label="Show all"
                      // disabled
                    />
                  </RadioGroup>
                </Box>
              </PopupPaper>
            </Grid>
          </Grid>
        </Box>
        <PopupPaper data-detail-point-id="point-detail-wrapper" style={{ display: "none" }}>
          <Box p={2.5}>
            <input type="hidden" data-detail-point-id="dataIndex" />
            <h3><span data-detail-point-id="quarter-name">Quarter</span>:</h3>
            <p><span data-detail-point-id="estimation-title">Valuation of current portfolio and exits</span>: $ <span data-detail-point-id="estimation">0</span></p>
            <p style={{ display: "flex" }}>
              <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "#2356B2", margin: "auto 1rem auto 0" }} />
              <span data-detail-point-id="investment-title">Total invested amount incl. exits and write-offs of securities</span>: $&nbsp;<span data-detail-point-id="investment">0</span></p>
            <p style={{ display: "flex" }}>
              <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "#1a497b", margin: "auto 1rem auto 0" }} />
              <span data-detail-point-id="roi-title">ROI (Return on investment)</span>:&nbsp; <span data-detail-point-id="roi">0</span>%</p>
            <p><span data-detail-point-id="investedAmount-title">Current portfolio invested amount</span>: $ <span data-detail-point-id="investedAmount">0</span></p>
            <p><span data-detail-point-id="portfolioValuation-title">Current portfolio valuation</span>: $ <span data-detail-point-id="portfolioValuation">0</span></p>
          </Box>
        </PopupPaper>
        <h3>Detail Data:</h3>
        {/* FIXME: временное выключение */}
        {/* {selectedVolume && selectedVolume.error && */}
          {/* <div>Will be availible soon. Please, check Investment Portfolio Summary for current details.</div>  */}
        {/* FIXME: временное выключение */}
        {/* } */}

        <div style={{marginBottom: '1.5rem'}}>
          For details please check your&nbsp;
          <SexyLink component={RouterLink} to={getPortfolioSummaryLink()}>portfolio summary</SexyLink>
        </div>
  
        {/* FIXME: временное выключение */}
        {/* {(
          detailData.items.length > 0 ||
          detailData.cashOperations.pending_transactions.length > 0 ||
          detailData.cashOperations.prepaid_expenses !== 0 ||
          detailData.cashAssets !== 0
        ) &&
          <>
            {detailData.items.some(item => !item.isLiquidated && !item.isFullExit) &&
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Startup</TableCell>
                    <TableCell>Invested in period</TableCell>
                    <TableCell>Estimated value</TableCell>
                    <TableCell>Received from exit</TableCell>
                    <TableCell>ROI</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailData.items
                    .filter(item => !item.isLiquidated && !item.isFullExit)
                    .map((item) => (
                      <Row item={item}/>
                    ))}
                </TableBody>
              </Table>
            }

            {detailData.items.some(
              (item) => item.isFullExit ||
              item.isLiquidated ||
              item.totalExit > 0
            ) &&
              <>
                <h3>Exits and dissolutions:</h3>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Startup</TableCell>
                      <TableCell>Invested in period, USD</TableCell>
                      <TableCell>Exit sum, USD</TableCell>
                      <TableCell>ROI</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailData.items
                      // .sort((a, b) => a.totalExit - b.totalExit)
                      .filter(
                        (item) => item.isFullExit ||
                        item.isLiquidated ||
                        item.totalExit > 0
                      )
                      .map((item) => (
                        <Row item={item} grossProceed/>
                      ))
                    }
                  </TableBody>
                </Table>
              </>
            }

            {detailData.items.some(
              _item => _item.history.some(_divItem => _divItem.is_dividends)
            ) &&
            <>
              <h3>
                Dividends
              </h3>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Startup</TableCell>
                    <TableCell>Dividends</TableCell>
                    <TableCell>Payment date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailData.items
                    .filter(item => item.history.some(_divItem => _divItem.is_dividends))
                    .map(item => (
                      item.history
                      .filter(historyRow => historyRow.is_dividends)
                      .map(historyRow => (
                        <TableRow>
                          <TableCell>{ item.startup }</TableCell>
                          <TableCell>$ {`${formatMoney(historyRow.investment)}`}</TableCell>
                          <TableCell>{new Date(historyRow.op_date).toLocaleDateString('en-US')}</TableCell>
                        </TableRow>
                      ))
                    ))
                  }
                </TableBody>
              </Table>
            </>}

            {detailData.cashOperations.pending_transactions.length > 0 &&
              <>
                <Box display="flex">
                  <h3>Pending Transactions</h3>
                  <Box marginLeft="auto">
                    <h3>
                      $ {formatMoney(detailData.cashOperations.pending_transactions_total)}
                    </h3>
                  </Box>
                </Box>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Portfolio Company</TableCell>
                      <TableCell>Principal, USD</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailData.cashOperations.pending_transactions.map((item) => (
                      <TableRow>
                        <TableCell>{item.startup}</TableCell>
                        <TableCell>$ {formatMoney(item.value_usd)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            }
            
            {detailData.cashOperations.prepaid_expenses !== 0 &&
              <Box display="flex">
                <h3>Prepaid expenses</h3>
                <Box marginLeft="auto">
                  <h3>
                    $ {formatMoney(detailData.cashOperations.prepaid_expenses)}
                  </h3>
                </Box>
              </Box>
            }

            {detailData.cashAssets !== 0 &&
              <Box display="flex">
                <h3>Cash assets</h3>
                <Box marginLeft="auto">
                  <h3>
                    $ {formatMoney(detailData.cashAssets)}
                  </h3>
                </Box>
              </Box>
            }
          </>
        }
        {(
          detailData.items.length === 0 &&
          detailData.cashOperations.pending_transactions.length === 0 &&
          detailData.cashOperations.prepaid_expenses === 0 &&
          detailData.cashAssets === 0
        ) && (selectedVolume && !selectedVolume.error) &&
          <div>To get detail data you should zoom on chart</div> 
        } */}
      </Box>
    </div>
  )
  
};

export default Echarts;
