import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import posed from "react-pose";

export const PaperButton = withStyles((theme) => ({
  root: {
    fontSize: 18,
    padding: "40px 40px 40px",
    width: 620,
    borderRadius: 20,
    overflow: "hidden",
    margin: "0 auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  label: {
    "& > div": {
      width: "100%",
      justifyContent: "space-between",
    },
  },
}))(Button);

export const Hover = posed.div({
  hoverable: true,
  hover: {
    staggerChildren: 100,
  },
});

export const HoverItem = posed.div({
  hover: {
    y: 0,
    transition: ({ from, to }) => ({
      type: "keyframes",
      duration: 1000,
      values: [0, 100, -300, 0],
      times: [0, 0.25, 0, 0.5],
    }),
  },
});
