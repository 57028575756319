import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { Accordion, Box, Typography, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PopupPaper from "../../components/PopupPaper";

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    "& iframe": {
      [theme.breakpoints.down("xs")]: {
        width: 210,
        height: 150,
      },
    },
  },
}));
export default observer(() => {
  const classes = useStyles();

  const videoLibrary = [
    {
      video: "https://www.youtube.com/embed/iXLzQZeiIAQ",
      title: "1. Запуск и развитие стартапа: обзор от экспертов AltaClub",
    },
    {
      video: "https://www.youtube.com/embed/M67MOX-yVT8",
      title: "2. Внутренняя кухня процедуры Due Diligence",
    },
    {
      video: "https://www.youtube.com/embed/bvP9jmC-Cr8",
      title: "3. Как фонд работает с портфельным проектом?",
    },
    {
      video: "https://www.youtube.com/embed/ZLKk1Koxs8s",
      title: "4. На выход: как правильно выйти из стартапа и остаться в плюсе",
    },
  ];

  return (
    <PopupPaper clickable>
      {videoLibrary.map((videoItem) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>{videoItem.title}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ justifyContent: "center" }}>
            <Box className={classes.videoContainer}>
              <iframe title={videoItem.title} width="1280" height="720" src={videoItem.video} frameBorder="0" allowFullScreen />
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </PopupPaper>
  );
});
