import React, { useState, useEffect, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { Box, Button, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { REACT_APP_API_URL } from "../../../constants/selectConstants";
import useLanguage from "../../../languages";
import PopupPaper from "../../../components/PopupPaper";
import { useStore } from "../../../store";
import urls, { useAsyncEndpoint } from "../../../urls";
import { useStyles } from "./style";
import UBO from "../ProfileComponents/UBO";
import Sumsub from "./Sumsub/Sumsub";
import PaginationBar from "./PaginationBar";
import FirstStepIndividual from "./FirstStepIndividual";
import FirstStepEntity from "./FirstStepEntity";
import SexyLink from "../../../components/SexyLink"

const useProfile = () =>
  useAsyncEndpoint((data) => ({
    url: urls.kyc(),
    method: "POST",
    data,
  }));

const useUBO = () =>
  useAsyncEndpoint(() => ({
    url: urls.ubo(),
    method: "GET",
  }));

async function saveCompanyType(type, profileArray, initial = false) {
  const result = await profileArray.map((obj) => {
    const requestBody = {}



    for (const key in obj) {
      if (key === 'signature_photo') {
        // eslint-disable-next-line no-continue
        continue
      }

      requestBody[key] = obj [key]
    }

    return fetch(`${REACT_APP_API_URL}/api/v1/investors/update_${type}/${obj.id}/`, {
      method: "PATCH",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
    }
  );

  if (!initial) {
    NotificationManager.success("Beneficiary profile successfully saved");
  }

  // if (result.status !== 200) {
  //   NotificationManager.error("An error occurred");
  // } else {
  //   NotificationManager.success("Beneficiary profile successfully saved");
  // }
}

export default observer(({ history }) => {
  const classes = useStyles();
  const getText = useLanguage();
  const store = useStore();
  const [profile, setProfile] = useState({ ...store.profile });
  const [profileResponse, postProfile] = useProfile();

  const [ubo, fetchUBO] = useUBO();
  const [profileUBO, setProfileUBO] = useState([...store.ubo]);

  const [stepNow, setStepNow] = useState(1);
  const [isValidation, setIsValidation] = useState({});

  const [currentStep, setCurrentStep] = useState(1);

  const [isLoaded, toggleLoaded] = useState(false);

  const handleChangeClick = () => {
    if (currentStep < 4) {
      setCurrentStep((currentStep) => currentStep + 1);
    }
  };

  const handleChange = (prop) => (event) => {
    setProfile({ ...profile, [prop]: event.target.value });
    profile.prop && store.setError(false);
  };

  const handleChangeChecked = (prop) => (event) => {
    setProfile({ ...profile, [prop]: event.target.checked ? "YES" : "" });
    profile.prop && store.setError(false);
  };

  const handleChangeFollows = (prop, bool) => {
    setProfile({ ...profile, [prop]: bool });
  };

  const handleChangeSelect = (prop, array) => {
    setProfile({ ...profile, [prop]: array });
    profile.prop && store.setError(false);
  };

  const handleChangeDate = (prop) => (event) => {
    const date = new Date();
    const year = date.getFullYear() - 18;
    const momentProp = moment(event._d).format("YYYY-MM-DD");
    const momentPropYear = moment(event._d).format("YYYY");
    setProfile({ ...profile, [prop]: momentProp });
    if (prop === "date_of_birth") {
      if (momentPropYear > year) {
        NotificationManager.error(getText("messages.adulthood"));
      }
    }
  };

  const handleChangeAutoComplete = (prop, value) => {
    setProfile({ ...profile, [prop]: value });
  };

  const handleChangeProfileProp = (prop, value) => {
    setProfile({ ...profile, [prop]: value });
  }

  useEffect(() => {
    postProfile();
  }, []);

  useEffect(() => {
    if (profileResponse.complete && isLoaded) {

      preSumbitCheck('', false, false)
    }
  }, [profile, profileResponse]);

  useEffect(() => {
    const data = toJS(store.profile);

    setProfile(data);
    setTimeout(() => toggleLoaded(true), 2000);
  }, [store.profile]);

  useEffect(() => {
    if (profileResponse.complete) {
      profileResponse.data.profile.profile_signed_date = null
      store.setProfile(profileResponse.data.profile);
    }
  }, [profileResponse]);

  async function createUBO(data) {
    const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/create_ubo/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    if (result.status !== 200) {
      // TODO обработать ошибки
    }
  }

  useEffect(() => {
    const data = toJS(store.ubo);
    data.map((obj) => [obj]);

    if (!data.length && currentStep === 2 && profile.entity_type === "Entity") {
      createUBO()
        .then(() => {
          fetchUBO();
        })
        .then(() => {
          const uboData = toJS(store.ubo);
          uboData.map((obj) => [obj]);

          setProfileUBO([...uboData]);
        });
    }

    setProfileUBO([...data]);
  }, [store.ubo]);

  useEffect(() => {
    if (ubo.complete) {
      store.setProfileUBO(ubo.data);
    }
  }, [ubo]);

  const triggerConfirmation = () => fetch(
    `${REACT_APP_API_URL}/api/v1/investors/fill_personal_data/`, {
      method: "PATCH",
      body: JSON.stringify({
        is_questionnaire_filled: true
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
  })

  const saveProfile = async (data) => {
    const response = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_personal_data/`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });

    return response
  }

  const onSubmit = async (dataFromStep, shouldChangeStep = true) => {
    store.setLoading(true);
    const data = dataFromStep;

    // если юрлицо, то пока пропустим 
    // триггер отправки письма подтверждения
    if (profile.entity_type !== 'Entity' && shouldChangeStep) {
      data.is_questionnaire_filled = true;
    }

    const result = await saveProfile(data)

    if (result.status !== 200 && result.status !== 500) {
      NotificationManager.error(
        "There's an error. Please, check your input data."
      );
      return
    }

    if (!shouldChangeStep) {
      return
    }

    NotificationManager.success(getText("messages.profileSaved"));
    store.setLoading(false);

    // eslint-disable-next-line consistent-return
    return result;
  };

  const preSumbitCheck = useCallback(
    async (move, hasChangeStep = true, shouldValidate = true) => {
      const dataFromStep = {
        last_name_en: profile.last_name_en,
        first_name_en: profile.first_name_en,
        middle_name_en: profile.middle_name_en,
        date_of_birth: profile.date_of_birth,
        citizenship: profile.citizenship,
        passport_number: profile.passport_number,
        date_of_passport_issue: profile.date_of_passport_issue,
        residence_country: profile.residence_country,
        is_us_person: profile.is_us_person,
        is_us_federal_taxes: profile.is_us_federal_taxes,
        social_security_number: profile.social_security_number,
        green_card_number: profile.green_card_number,
        tingin: profile.tingin,
        residence_address: profile.residence_address,
        mailing_address: profile.mailing_address,
        agree_to_electronic_signing: profile.agree_to_electronic_signing,
        investment_planned_amount: profile.investment_planned_amount,
        salary: profile.salary,
        bank_name: profile.bank_name,
        bank_country: profile.bank_country,
        swift: profile.swift,
        account_number: profile.account_number,
        total_estimated_net_wealth: profile.total_estimated_net_wealth,
        employment_wealth: profile.employment_wealth,
        dividens_wealth: profile.dividens_wealth,
        paternal_inheritance: profile.paternal_inheritance,
        property_sales_wealth: profile.property_sales_wealth,
        investments_sales_wealth: profile.investments_sales_wealth,
        other_sales_wealth: profile.other_sales_wealth,
        is_senior_foreign_political_figure: profile.is_senior_foreign_political_figure,
        convictions: profile.convictions,
        criminal_descriptions: profile.criminal_descriptions,
        under_investigation: profile.under_investigation,
        under_investigation_text: profile.under_investigation_text,
        is_wiring_bank_fatf_approved: profile.is_wiring_bank_fatf_approved,
        is_wiring_bank_customer: profile.is_wiring_bank_customer,
        accredited_investor_representation_points:
          profile.accredited_investor_representation_points && profile.accredited_investor_representation_points.length > 0 ? profile.accredited_investor_representation_points : null,

        accredited_investor_representation_points_other:
          profile.accredited_investor_representation_points_other && profile.accredited_investor_representation_points_other.length > 0
            ? profile.accredited_investor_representation_points_other
            : null,
        company_country: profile.company_country,
        company_address: profile.company_address,
        source_of_wealth: profile.source_of_wealth,
        source_of_funds: profile.source_of_funds,
        // ------
        company_name: profile.company_name,
        non_individual_interest_ownership: profile.non_individual_interest_ownership,
        non_individual_interest_ownership_other: profile.non_individual_interest_ownership_other,
        jurisdiction_of_organizaton: profile.jurisdiction_of_organizaton,
        organization_formation_date: profile.organization_formation_date,
        type_of_trust: profile.type_of_trust,
        name_of_all_beneficiaries: profile.name_of_all_beneficiaries,
        registration_address_en: profile.registration_address_en,
        is_risks_approved: profile.is_risks_approved,
        individual_interest_ownership: profile.individual_interest_ownership,
        individual_interest_ownership_other: profile.individual_interest_ownership_other,
        company_number: profile.company_number
      };



      if (!shouldValidate) {
        onSubmit(dataFromStep, shouldValidate);
        return
      }

      // Прости меня :(
      const cast = document.querySelectorAll("[data-castil]");

      const obj = {};

      for (let i = 0; i < cast.length; i += 1) {
        if (Array.isArray(profile[cast[i].dataset.castil])) {
          obj[cast[i].dataset.castil] = !profile[cast[i].dataset.castil].length;
        } else {
          obj[cast[i].dataset.castil] = !profile[cast[i].dataset.castil];
        }
      }

      setIsValidation(obj);

      if (!Object.values(obj).includes(true)) {
        if (currentStep === 1) {
          onSubmit(dataFromStep);
        }

        if (currentStep === 2 && profile.entity_type === "Entity") {
          saveCompanyType("ubo", profileUBO);
          // postProfile();
        }

        if (currentStep === 3 && profile.entity_type === "Entity") {
          triggerConfirmation();
        }

        if (hasChangeStep) {
          handleChangeClick();
        }

      } else {

        NotificationManager.error("There's an error. Please, check your input data.");

      }
    },
    [profile, stepNow, profileUBO, currentStep]
  );

  useEffect(() => {
    if (currentStep !== 1) {
      preSumbitCheck('next', false)
    }
  }, [currentStep])

  return (
    <PopupPaper inline style={{ margin: "0 auto" }}>
      <Box mt={5} display="flex" justifyContent="space-between">
        <Typography variant="h5">
          <strong>{getText("investorProfile")}</strong>
        </Typography>

        {currentStep === 1 && (profile.entity_type === "Individual" || !profile.entity_type) &&
          <SexyLink
            component="a"
            href="https://us.altaclub.vc/media/Investor_Questionnaire_FAQ.pdf"
            target="_blank" rel="noopener noreferrer"
          >
            FAQ for Individual Profile Questionnaire
          </SexyLink>
        }
        {currentStep === 1 && profile.entity_type === "Entity" &&
          <SexyLink
            component="a"
            href="https://us.altaclub.vc/media/Corporate_Profile_Questionnaire_FAQ.pdf"
            target="_blank" rel="noopener noreferrer"
          >
            FAQ for Corporate Profile Questionnaire
          </SexyLink>
        }
        {currentStep === 2 && profile.entity_type === "Entity" &&
          <SexyLink
            component="a"
            href="https://us.altaclub.vc/media/Beneficiary_Questionnaire_FAQ.pdf"
            target="_blank" rel="noopener noreferrer"
          >
            FAQ for UBO Profile Questionnaire
          </SexyLink>
        }
      </Box>
      <form onSubmit={(event) => {
        event.preventDefault()
        preSumbitCheck("next")
      }}>
        {stepNow && isLoaded && (
          <>
            <PaginationBar
              profile={profile}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            {currentStep === 1 && profile.entity_type === "Entity" && (
              <FirstStepEntity
                profile={profile}
                handleChangeAutoComplete={handleChangeAutoComplete}
                isValidation={isValidation}
                handleChange={handleChange}
                handleChangeDate={handleChangeDate}
                handleChangeChecked={handleChangeChecked}
                handleChangeSelect={handleChangeSelect}
                handleChangeProfileProp={handleChangeProfileProp}
                notificationManager={NotificationManager}
              />
            )}
            {currentStep === 1 && (profile.entity_type === "Individual" || !profile.entity_type) && (
              <FirstStepIndividual
                handleChangeSelect={handleChangeSelect}
                profile={profile}
                handleChangeAutoComplete={handleChangeAutoComplete}
                handleChange={handleChange}
                handleChangeChecked={handleChangeChecked}
                handleChangeDate={handleChangeDate}
                handleChangeFollows={handleChangeFollows}
                isValidation={isValidation}
                saveCompanyType={saveCompanyType}
                handleChangeProfileProp={handleChangeProfileProp}
                notificationManager={NotificationManager}
                // profileUBO={profileUBO}
                // fetchUBO={fetchUBO}
              />
            )}
            {profile.entity_type === "Entity" && currentStep === 2 && (
              <UBO
                cabinet
                handleChange={handleChange}
                profile={profile}
                profileUBO={profileUBO}
                fetchUBO={fetchUBO}
                saveCompanyType={saveCompanyType}
                handleChangeDate={handleChangeDate}
                setProfileUBO={setProfileUBO}
                isValidation={isValidation}
              />
            )}
            {profile.entity_type === "Entity" && currentStep === 3 && <Sumsub />}
            {(profile.entity_type === "Individual" || !profile.entity_type) && currentStep === 2 && <Sumsub />}
          </>
        )}
        {(profile.entity_type === "Entity" && currentStep === 3) || ((profile.entity_type === "Individual" || !profile.entity_type) && currentStep === 2) ? (
          ""
        ) : (
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={!isLoaded}
          >
            {getText("next")}
          </Button>
        )}
      </form>
    </PopupPaper>
  );
});
