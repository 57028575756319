import React, { useEffect } from "react";
import { Box, Button, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import TextInput from "../../../components/TextInput";
import useLanguage from "../../../languages";
import { Autocomplete } from "../../../components/Autocomplete";
import { useStore } from "../../../store";

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    justifyContent: "center",
  },
  input: { marginTop: 24 },
  button: {
    width: 190,
    padding: "12px 0",
  },
  tableHead: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #e4eefa",
  },
  tableBody: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  tableCell: {
    display: "flex",
    flexDirection: "column",
    width: "33%",
  },
});

const budgetMock = [`200 000 $ - 500 000 $`, "500 000 $ - 1 000 000 $", "1 000 000 $ +"];

export const Investment = observer(
  ({ handleChangeCheck, state, handleChange, checkForm, handleChangeInvestmentFocus, history }) => {
    const getText = useLanguage();
    const classes = useStyles();
    const store = useStore();

    const investmentConstraints = {
      commentEnd: {
        length: { maximum: 200, message: `^${getText("pages.signUp.errors.commentEndMax", 1)}` },
      },
      sumInvest: {
        presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.sumInvest")}` },
      },
    };

    useEffect(() => {
      if (store.language === "ru") {
        history.push("?investment&language=ru");
      } else {
        history.push("?investment&language=en");
      }
    }, [store.language]);

    const handleClick = (prop) => () => {
      checkForm(prop, investmentConstraints);
    };

    return (
      <div>
        <Box whiteSpace="pre-wrap" mt={2} className={classes.formContainer}>
          <h3>{getText("pages.signUp.investmentFocus.caption")}</h3>
        </Box>
        <Box>
          <div>
            {state.investmentFocus.map((el) => (
              <Box display="flex" key={el.stage}>
                <Checkbox onChange={handleChangeCheck(el.label)} checked={el.status} color="primary" />
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: 15 }}>{getText(el.stage)}</div>
                  <div style={{ fontSize: 15, marginLeft: 6 }}>{getText(el.label)}</div>
                </Box>
              </Box>
            ))}
          </div>
        </Box>
        <Box mt={3}>
          <Autocomplete
            notFlag
            error={state.errors.sumInvest}
            style={{ paddingLeft: 1 }}
            options={budgetMock.map((el) => ({ label: el, value: el }))}
            caption={getText("pages.signUp.money")}
            value={state.sumInvest}
            onChange={handleChange("sumInvest")}
          />
        </Box>
        <TextInput
          type="text"
          className={classes.input}
          value={state.commentEnd}
          onChange={handleChange("commentEnd")}
          caption={getText("pages.signUp.commentEnd")}
        />
        <TextInput
          type="text"
          className={classes.input}
          value={state.recommended}
          onChange={handleChange("recommended")}
          caption={getText("pages.signUp.recommended")}
        />
        <Box mt={3} display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
          <Button onClick={handleClick("back")} className={classes.button} size="large" color="secondary" variant="contained">
            {getText("pages.signUp.back")}
          </Button>
          <Button onClick={handleClick("next")} size="large" variant="contained" color="primary" className={classes.button}>
            {getText("pages.signUp.next")}
          </Button>
        </Box>
      </div>
    );
  }
);
