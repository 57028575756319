import { Input, Button, Box } from "@material-ui/core";
import { React, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import useLanguage from "../../../languages/index";

const DOCUMENT_UPLOAD = {
  documentIndividualNonRFPersonSelect: [
    { label: "certificateBank", value: 1 },
    { label: "twoSpreadsPassport", value: 5 },
    { label: "internationalPassportNotarized", value: 6 },
    { label: "investorQuestionnaire", value: 7 },
    { label: "internationalPassport", value: 22 },
  ],
  documentIndividualRFPersonSelect: [
    { label: "certificateBank", value: 4 },
    { label: "twoSpreadsPassport", value: 5 },
    { label: "internationalPassportNotarized", value: 6 },
    { label: "investorQuestionnaire", value: 7 },
    { label: "internationalPassport", value: 22 },
    { label: "notificationRegistration", value: 8 },
    { label: "extractEGRIP", value: 9 },
    { label: "recordSheetEGRIP", value: 10 },
  ],
  documentLegalRFPersonSelect: [
    { label: "companyQuestionnaire", value: 16 },
    { label: "listShareholders", value: 17 },
    { label: "certificateTaxAuthority", value: 18 },
    { label: "certificateStateRegistration", value: 19 },
    { label: "charterCompany", value: 20 },
    { label: "extractUGRL", value: 21 },
  ],
  documentLegalNonRFPersonSelect: [
    { label: "crtificateIncorporation", value: 11 },
    { label: "memorandum", value: 12 },
    { label: "registerShareholders", value: 13 },
    { label: "registerDirectors", value: 14 },
    { label: "certificateStanding", value: 15 },
    { label: "investorProfile", value: 16 },
  ],
};

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  document: {
    display: "flex",
    flexDirection: "column",
    "& > label": {
      margin: "10px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  text: {
    userSelect: "none",
    fontSize: "1rem",
    fontWeight: 700,
    maxWidth: "70%",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default observer(({ documentList, handleChangeDoc }) => {
  const getText = useLanguage();
  const classes = useStyles();
  const [documentType, setDocumentType] = useState("");

  return (
    <Box mt={2} className={classes.document}>
      {DOCUMENT_UPLOAD[documentList].map((el) => (
        <label key={el.value} htmlFor="contained-button-file">
          <span className={classes.text}>{getText(`${documentList}.${el.label}`)}</span>
          <Input
            className={classes.input}
            onChange={(event) => handleChangeDoc(event.target.files[0], documentType)}
            accept="image/*"
            id="contained-button-file"
            type="file"
          />
          <Button variant="contained" color="secondary" component="span" onClick={() => setDocumentType(el.value)}>
            {getText("addDocument")}
          </Button>
        </label>
      ))}
    </Box>
  );
});
