import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@material-ui/core";
import { toJS } from "mobx";
import { isArray } from "lodash";
import ReactHtmlParser from "react-html-parser";
import { useStore } from "../../store";
import PopupPaper from "../../components/PopupPaper";
import { useStyles } from "./styles";
import { useNews } from "../../api/api";

export default observer((articleId) => {
  const store = useStore();
  const classes = useStyles();
  const [news, fetchNews] = useNews();
  const [profileNews, setNews] = useState([store.news]);

  const newsId = useMemo(() => articleId.articleId.split("/")[0], [articleId]);
  const currentNews = useMemo(
    () => !isArray(profileNews) && profileNews.results.filter((paper) => paper.startup === +newsId),
    [profileNews]
  );

  useEffect(() => {
    fetchNews(articleId.articleId.split("/")[1]);
  }, []);

  useEffect(() => {
    const data = toJS(store.news);
    setNews(data);
  }, [store.news]);

  useEffect(() => {
    if (news.complete) {
      store.setNews(news.data);
    }
  }, [news]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PopupPaper className={classes.popup} inline>
      {currentNews && (
        <>
          <Typography variant="h5">
            <strong>{currentNews[0].title}</strong>
          </Typography>
          <Box>
            <Box display="flex" justifyContent="center">
              {currentNews[0].image && <img className={classes.articleImage} src={currentNews[0].image} alt="article" />}
            </Box>
            <Typography className={classes.articleText}>
              {currentNews[0].full_text ? ReactHtmlParser(currentNews[0].full_text) : currentNews[0].description}
            </Typography>
          </Box>
        </>
      )}
    </PopupPaper>
  );
});
