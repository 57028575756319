import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { NotificationManager } from "react-notifications";
import DeleteDialog from "../../../components/DeleteDialog";
import { AddIcon } from "../../../components/Icons";
import PopupPaper from "../../../components/PopupPaper";
import useLanguage from "../../../languages";
import TextInput from "../../../components/TextInput";
import { Autocomplete } from "../../../components/Autocomplete";
import { SELECTS_OPTIONS } from "./ProfileAutocomplete";
import { useStore } from "../../../store";
import {
  handleChangeCompanyType,
  handleChangeCompanyAutoCompleteType,
  REACT_APP_API_URL,
} from "../../../constants/selectConstants";
import { AutocompleteMaterial } from "../../../components/AutocompleteMaterial";
// style
import { PaperButton, Hover, HoverItem } from "../style";

async function createDirector(data) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/create_director/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

async function fillDirectorIndividualProfile(data) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_director_individual_profile/`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

async function deleteDirector(id) {
  const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/remove_director/${id}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  });
  if (result.status !== 200) {
    // TODO обработать ошибки
  }
}

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28 },
  input_small: {
    maxWidth: 200,
  },
  button: {
    width: 190,
    padding: "12px 0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
  under: {
    textDecorationLine: "underline",
  },
  select: { marginTop: 28 },
  addАccaunt: {
    marginTop: 16,
  },
  addАccaunt_btn: {
    display: "flex",
    alignItems: "center",
  },
  new_beneficiary: {
    margin: "16px 0",
  },
  new_beneficiary_cabinet: {
    boxShadow: "none",
    borderRadius: 0,
    borderTop: "1px solid #DBE5EA",
    marginTop: 10,
  },
  bottomLine: {
    border: "1px solid #DBE5EA",
    margin: "32px 0 24px",
  },
  new_beneficiary_head: {
    marginBottom: 25,
    marginTop: 25,
  },
  new_beneficiary_head_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default observer(({ handleChange, profile, profileDirector, fetchDirector, cabinet, saveCompanyType }) => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();

  const [errorDocsKys, setErrorDocsKys] = useState(false);
  const [deleteTitle, setDeleteTitle] = useState();
  const [deleteIndex, setDeleteIndex] = useState();
  const [openDelete, openDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const [openSecretary, setOpenSecretary] = useState(false);
  const handleOpenSecretary = () => setOpenSecretary(!openSecretary);

  const addNewDirector = useCallback(() => {
    try {
      saveCompanyType("director", profileDirector);
    } catch (error) {
      NotificationManager.error("There's an error. Please, check your input data.");
    } finally {
      createDirector()
        .then(() => {
          fetchDirector();
        })
        .catch(); // TODO обработать ошибки
    }
  });

  useEffect(() => {
    fetchDirector();
  }, []);

  const deleteAccount = (index, id) => () => {
    setDeleteTitle(getText("tips.removeAccount", index + 1));
    setDeleteId(id);
    setDeleteIndex(index);
    openDeleteDialog(true);
  };

  const handleDelete = useCallback((confirm, id) => {
    try {
      saveCompanyType("director", profileDirector);
    } catch (error) {
      NotificationManager.error("There's an error. Please, check your input data.");
    } finally {
      if (confirm) {
        deleteDirector(id)
          .then(() => {
            fetchDirector();
          })
          .catch(); // TODO обработать ошибки;
      }
    }
    openDeleteDialog(false);
  });
  return (
    <div>
      <Box mt={2} />
      <PopupPaper inline={!cabinet}>
        {!cabinet && (
          <Box mt={5}>
            <Typography variant="h5">
              <strong>{getText("director")}</strong>
            </Typography>
          </Box>
        )}
        {profileDirector.length !== 0 && !cabinet && (
          <>
            <TextInput
              type="text"
              multiline
              hideMultiline
              toolTip="sourceWealth"
              className={classes.input}
              value={profile?.source_of_wealth}
              onChange={handleChange("source_of_wealth")}
              caption={getText("source_of_wealth")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.postal_address}
              onChange={handleChange("postal_address")}
              caption={getText("postal_address")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.political_refugee_text}
              onChange={handleChange("political_refugee_text")}
              caption={getText("political_refugee")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.convictions_text}
              onChange={handleChange("convictions_text")}
              caption={getText("convictions")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.under_investigation_text}
              onChange={handleChange("under_investigation_text")}
              caption={getText("under_investigation")}
            />
            <Box className={classes.select}>
              <Autocomplete
                notflag
                options={SELECTS_OPTIONS.power_of_attorney_select.map((el) => ({
                  label: getText(`${el.label}`),
                  value: el.value,
                }))}
                caption={getText("power_of_attorney")}
                value={profile?.power_of_attorney}
                onChange={handleChange("power_of_attorney")}
              />
              {profile.power_of_attorney && (
                <>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={profile?.attorney_full_name}
                    onChange={handleChange("attorney_full_name")}
                    caption={getText("attorney_full_name")}
                  />
                  <TextInput
                    type="number"
                    className={classes.input}
                    value={profile?.attorney_phone}
                    onChange={handleChange("attorney_phone")}
                    caption={getText("attorney_phone")}
                  />
                  <TextInput
                    type="email"
                    className={classes.input}
                    value={profile?.attorney_email}
                    onChange={handleChange("attorney_email")}
                    caption={getText("attorney_email")}
                  />
                </>
              )}
            </Box>
            <Box className={classes.bottomLine} />
            <div className={classes.input}>{getText("tips.bankImportant")}</div>
            <div className={classes.input}>{getText("tips.attention")}</div>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.bank_name}
              onChange={handleChange("bank_name")}
              caption={getText("bank_name")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.bank_address}
              onChange={handleChange("bank_address")}
              caption={getText("bank_address")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.iban}
              onChange={handleChange("iban")}
              caption={getText("iban")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.swift}
              onChange={handleChange("swift")}
              caption={getText("swift")}
            />
            <Box className={classes.bottomLine} />
            <div className={classes.input}>{getText("tips.cashDistributions")}</div>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_account}
              onChange={handleChange("distributions_account")}
              caption={getText("distributions.account")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_address}
              onChange={handleChange("distributions_address")}
              caption={getText("distributions.address")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_bank_name}
              onChange={handleChange("distributions_bank_name")}
              caption={getText("distributions.bankName")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_bank_address}
              onChange={handleChange("distributions_bank_address")}
              caption={getText("distributions.bankAddress")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_swift}
              onChange={handleChange("distributions_swift")}
              caption={getText("distributions.SWIFT")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_account_number}
              onChange={handleChange("distributions_account_number")}
              caption={getText("distributions.acctNum")}
            />
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.distributions_instructions}
              onChange={handleChange("distributions_instructions")}
              caption={getText("distributions.instructions")}
            />
            <Box mt={2} />
            {errorDocsKys && (
              <div className="text-container">
                <div className="text-error">{getText("doc_errors")}</div>
              </div>
            )}
          </>
        )}
      </PopupPaper>

      {/* <Documents onlyKyc id="documents-kys" border={errorDocsKys} disableExpansion header={false} style={{ padding: 10 }} /> */}
      {profileDirector &&
        profileDirector.map((director, index) => (
          <div key={director.id}>
            <DeleteDialog onClose={handleDelete} title={deleteTitle} open={openDelete} id={deleteId} />
            <PopupPaper className={cabinet ? classes.new_beneficiary_cabinet : classes.new_beneficiary} inline={!cabinet}>
              <Box ml="auto" className={classes.new_beneficiary_head_box}>
                <Typography className={classes.new_beneficiary_head} variant="h5">
                  <strong>
                    {getText("director")} {index + 1}
                  </strong>
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={deleteAccount(index, director?.id)}
                >
                  {getText("delete")}
                </Button>
              </Box>
              {director.individual_profile_has_error && (
                <>
                  <Alert severity="warning">{director.individual_profile_error}!</Alert>
                  <Button
                    type="button"
                    onClick={() => fillDirectorIndividualProfile({ director: director.id })}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: 28 }}
                  >
                    {getText("fix")}
                  </Button>
                </>
              )}
              {!cabinet && (
                <>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.last_name}
                    onChange={handleChangeCompanyType("last_name", index, profileDirector)}
                    caption={getText("beneficiary_surname_cyrillic")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.first_name}
                    onChange={handleChangeCompanyType("first_name", index, profileDirector)}
                    caption={getText("beneficiary_name_cyrillic")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.middle_name}
                    onChange={handleChangeCompanyType("middle_name", index, profileDirector)}
                    caption={getText("beneficiary_middle_name_cyrillic")}
                  />
                  <Box className={classes.bottomLine} />
                </>
              )}
              <TextInput
                type="text"
                className={classes.input}
                value={director?.last_name_en}
                onChange={handleChangeCompanyType("last_name_en", index, profileDirector)}
                caption={getText("beneficiary_surname_latin")}
              />
              <TextInput
                type="text"
                className={classes.input}
                value={director?.first_name_en}
                onChange={handleChangeCompanyType("first_name_en", index, profileDirector)}
                caption={getText("beneficiary_name_latin")}
              />
              {!cabinet && (
                <>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.middle_name_en}
                    onChange={handleChangeCompanyType("middle_name_en", index, profileDirector)}
                    caption={getText("beneficiary_middle_name_latin")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.date_of_birth}
                    onChange={handleChange("date_of_birth")}
                    caption={getText("date_of_birth")}
                  />
                </>
              )}
              <Box className={classes.bottomLine} />
              <TextInput
                type="email"
                className={classes.input}
                value={director?.email}
                onChange={handleChangeCompanyType("email", index, profileDirector)}
                caption={getText("email")}
              />
              {!cabinet && (
                <>
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.cd_beneficiary_address}
                    onChange={handleChangeCompanyType("cd_beneficiary_address", index, profileDirector)}
                    caption={getText("cdBeneficiaryAddress")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.cd_beneficiary_name}
                    onChange={handleChangeCompanyType("cd_beneficiary_name", index, profileDirector)}
                    caption={getText("cdBeneficiaryName")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.cd_further_credit}
                    onChange={handleChangeCompanyType("cd_further_credit", index, profileDirector)}
                    caption={getText("cdFurtherCredit")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.cd_intermediary_bank_name}
                    onChange={handleChangeCompanyType("cd_intermediary_bank_name", index, profileDirector)}
                    caption={getText("cdIntermediaryBankName")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.criminal_descriptions}
                    onChange={handleChangeCompanyType("criminal_descriptions", index, profileDirector)}
                    caption={getText("criminalDescriptions")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.jurisdiction}
                    onChange={handleChangeCompanyType("jurisdiction", index, profileDirector)}
                    caption={getText("jurisdiction")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.paternal_inheritance}
                    onChange={handleChangeCompanyType("paternal_inheritance", index, profileDirector)}
                    caption={getText("paternalInheritance")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.salary}
                    onChange={handleChangeCompanyType("salary", index, profileDirector)}
                    caption={getText("salary")}
                  />
                  <TextInput
                    type="text"
                    className={classes.input}
                    value={director?.sale_of_business}
                    onChange={handleChangeCompanyType("sale_of_business", index, profileDirector)}
                    caption={getText("saleOfBusiness")}
                  />
                </>
              )}
              {cabinet && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={openSecretary}
                        onChange={handleOpenSecretary}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={getText("pages.signUp.openSecretary")}
                  />
                  {openSecretary && (
                    <TextInput
                      type="email"
                      className={classes.input}
                      value={director?.email_secretary}
                      onChange={handleChangeCompanyType("email_secretary", index, profileDirector)}
                      caption={getText("emailSecretary")}
                    />
                  )}
                  <Box id="citizenship" mt={3}>
                    <AutocompleteMaterial
                      flag
                      options={
                        store.language === "ru"
                          ? store.citizenships.map((code) => ({ label: getText(`countries.${code}`), value: code }))
                          : store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))
                      }
                      text="citizenship"
                      value={director?.citizenship}
                      onChange={handleChangeCompanyAutoCompleteType(
                        "citizenship",
                        index,
                        profileDirector,
                        saveCompanyType,
                        fetchDirector,
                        "director"
                      )}
                    />
                  </Box>
                </>
              )}
            </PopupPaper>
          </div>
        ))}
      <Box className={classes.addАccaunt}>
        <Hover className={classes.forms}>
          <PaperButton fullWidth color="secondary" variant="contained" onClick={addNewDirector}>
            <Box className={classes.addАccaunt_btn}>
              <HoverItem>
                <Typography color="textSecondary" variant="inherit">
                  <strong>{getText("add_director")}</strong>
                </Typography>
              </HoverItem>
              <HoverItem>
                <Box fontSize={40} lineHeight={0} ml="auto">
                  <AddIcon fontSize="inherit" />
                </Box>
              </HoverItem>
            </Box>
          </PaperButton>
        </Hover>
      </Box>
    </div>
  );
});
