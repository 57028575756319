import React, { useState, useEffect } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { observer } from "mobx-react-lite";
import { NotificationManager } from "react-notifications";
import { toJS } from "mobx";
import { makeStyles } from "@material-ui/styles";
import { useStore } from "../../../../store";
import urls, { useAsyncEndpoint } from "../../../../urls";
import PopupPaper from "../../../../components/PopupPaper";
import useLanguage from "../../../../languages";
import { REACT_APP_API_URL } from "../../../../constants/selectConstants";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "39px 110px 56px",
  },
  dialogTitle: {
    padding: "16px 0",
    textAlign: "center",
    "& h2": {
      fontSize: 24,
      fontWeight: 600,
    },
  },
}));

const useSumsubToken = () =>
  useAsyncEndpoint((data) => ({
    url: urls.sumsubToken(),
    method: "GET",
  }));

export default observer(({ history, location }) => {
  const store = useStore();
  const classes = useStyles();
  const getText = useLanguage();
  const [profile, setProfile] = useState({ ...store.profile });
  const [sumsubTokenResponse, fetchSumsubToken] = useSumsubToken();

  useEffect(() => {
    async function fetchGet() {
      let response = await fetch(`${REACT_APP_API_URL}/api/v1/investors/profile/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      response = await response.json();
      if (response.entity_type === "Individual" && response.citizenship === "") {
        NotificationManager.error(getText("messages.sumsubCitizenship"));
        history.push("/settings/profile");
      }
      if (response.entity_type === "Legal" && !response.is_sumsub_ready) {
        NotificationManager.error(getText("messages.sumsubLegal"));
        history.push("/settings/profile");
      } else {
        fetchSumsubToken();
      }
    }
    fetchGet();
  }, []);

  useEffect(() => {
    const data = toJS(store.profile);
    setProfile(data);
  }, [store.profile]);

  const messageHandler = async (type, payload) => {
    if (type === "idCheck.onApplicantSubmitted" || type === "idCheck.onApplicantResubmitted") {
      window.location.pathname = "/thank-you";
    }
    if (type === "idCheck.stepCompleted") {
      const sumsubStageTranslate = getText(`sumsub.${payload.step.toLowerCase()}`);
      const data = { stage_name: sumsubStageTranslate };
      const response = await fetch(`${REACT_APP_API_URL}/api/v1/investors/on_sumsub_stage_uploaded/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
    }
  };

  return (
    <div>
      {sumsubTokenResponse.complete && (
        <PopupPaper style={{ margin: "50px auto 0 auto" }}>
          <SumsubWebSdk
            accessToken={sumsubTokenResponse.data.token}
            expirationHandler={fetchSumsubToken}
            onMessage={messageHandler}
            config={{ lang: store.language }}
          />
        </PopupPaper>
      )}
    </div>
  );
});
