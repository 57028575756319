export const SELECTS_OPTIONS = {
  entity_type: [
    { label: "entity_type.INDIVIDUAL", value: "INDIVIDUAL" },
    { label: "entity_type.LEGAL", value: "LEGAL" },
  ],
  investment_size: [
    { label: "investment_size.FROM_100_TO_250", value: "FROM_100_TO_250" },
    { label: "investment_size.FROM_250_TO_500", value: "FROM_250_TO_500" },
    { label: "investment_size.FROM_500_TO_1000", value: "FROM_500_TO_1000" },
    { label: "investment_size.FROM_1000", value: "FROM_1000" },
  ],
  investment_type: [
    { label: "investment_type.NOTHING", value: "NOTHING" },
    { label: "investment_type.SEED_STAGE", value: "SEED_STAGE" },
    { label: "investment_type.MVP", value: "MVP" },
    { label: "investment_type.EARLY_STAGE", value: "EARLY_STAGE" },
    { label: "investment_type.MID_STAGE", value: "MID_STAGE" },
    { label: "investment_type.LATE_STAGE", value: "LATE_STAGE" },
  ],
  investment_industry: [
    { label: "investment_industry.NOTHING", value: "NOTHING" },
    { label: "investment_industry.BIG_DATA", value: "BIG_DATA" },
    { label: "investment_industry.FINANCES", value: "FINANCES" },
    { label: "investment_industry.MOBILE_APPS", value: "MOBILE_APPS" },
    { label: "investment_industry.BITCOIN", value: "BITCOIN" },
    { label: "investment_industry.GAMES", value: "GAMES" },
    { label: "investment_industry.PHOTO", value: "PHOTO" },
    { label: "investment_industry.CLOUD_SOLUTIONS", value: "CLOUD_SOLUTIONS" },
    { label: "investment_industry.HEALTHCARE", value: "HEALTHCARE" },
    { label: "investment_industry.ROBOTICS", value: "ROBOTICS" },
    {
      label: "investment_industry.INTERNET_SERVICES",
      value: "INTERNET_SERVICES",
    },
    { label: "investment_industry.INFRASTRUCTURE", value: "INFRASTRUCTURE" },
    { label: "investment_industry.SOCIAL_NETWORKS", value: "SOCIAL_NETWORKS" },
    { label: "investment_industry.EDUCATION", value: "EDUCATION" },
    { label: "investment_industry.SECURITY", value: "SECURITY" },
    { label: "investment_industry.SPORT", value: "SPORT" },
    { label: "investment_industry.INDUSTRY", value: "INDUSTRY" },
    {
      label: "investment_industry.LEADS_GENERATION",
      value: "LEADS_GENERATION",
    },
    { label: "investment_industry.TRAVELS", value: "TRAVELS" },
  ],
  legal_entity_type: [
    { label: "legal_entity_type.ACCREDITED", value: "ACCREDITED" },
    {
      label: "legal_entity_type.NON_ACCREDITED",
      value: "NON_ACCREDITED",
    },
  ],
  russian_individual_person_select: [
    {
      label:
        "russian_individual_person_select.SECURITIES_AND_FINANCIAL_INSTRUMENTS",
      value: "SECURITIES_AND_FINANCIAL_INSTRUMENTS",
    },
    {
      label: "russian_individual_person_select.WORKING_EXPERIENCE",
      value: "WORKING_EXPERIENCE",
    },
    {
      label: "russian_individual_person_select.FOUR_QUARTERS_TEN",
      value: "FOUR_QUARTERS_TEN",
    },
    {
      label: "russian_individual_person_select.AMOUNT_OF_PROPERTY",
      value: "AMOUNT_OF_PROPERTY",
    },
    {
      label: "russian_individual_person_select.ECONOMICS_DEGREE",
      value: "ECONOMICS_DEGREE",
    },
    {
      label: "russian_individual_person_select.NOTHING",
      value: "NOTHING",
    },
  ],
  russian_legal_person_select: [
    {
      label: "russian_legal_person_select.QUALIFIED_INVESTOR",
      value: "QUALIFIED_INVESTOR",
    },
    {
      label: "russian_legal_person_select.OTHER",
      value: "OTHER",
    },
  ],
  russian_legal_qualified_investor_select: [
    {
      label: "russian_legal_qualified_investor_select.PROFESSIONAL_MEMBER",
      value: "PROFESSIONAL_MEMBER",
    },
    {
      label: "russian_legal_qualified_investor_select.CLEARING_COMPANY",
      value: "CLEARING_COMPANY",
    },
    {
      label: "russian_legal_qualified_investor_select.CREDIT_ORGANIZATION",
      value: "CREDIT_ORGANIZATION",
    },
    {
      label: "russian_legal_qualified_investor_select.EQUITY_INVESTMENT_FUND",
      value: "EQUITY_INVESTMENT_FUND",
    },
    {
      label: "russian_legal_qualified_investor_select.MANAGEMENT_COMPANY",
      value: "MANAGEMENT_COMPANY",
    },
    {
      label: "russian_legal_qualified_investor_select.INSURANCE_COMPANY",
      value: "INSURANCE_COMPANY",
    },
    {
      label: "russian_legal_qualified_investor_select.NON_PROFIT_ORGANIZATION",
      value: "NON_PROFIT_ORGANIZATION",
    },
    {
      label: "russian_legal_qualified_investor_select.NON_STATE_PENSION_FUND",
      value: "NON_STATE_PENSION_FUND",
    },
    {
      label: "russian_legal_qualified_investor_select.BANK_OF_RUSSIA",
      value: "BANK_OF_RUSSIA",
    },
    {
      label: "russian_legal_qualified_investor_select.VEB_RU_CORPORATION",
      value: "VEB_RU_CORPORATION",
    },
    {
      label: "russian_legal_qualified_investor_select.DEPOSIT_INSURANCE_AGENCY",
      value: "DEPOSIT_INSURANCE_AGENCY",
    },
    {
      label:
        "russian_legal_qualified_investor_select.RUSSIAN_CORPORATION_NANOTECHNOLOGIES",
      value: "RUSSIAN_CORPORATION_NANOTECHNOLOGIES",
    },
    {
      label:
        "russian_legal_qualified_investor_select.INTERNATION_FINANCIAL_ORGANIZATION",
      value: "INTERNATION_FINANCIAL_ORGANIZATION",
    },
  ],
  russian_legal_other_select: [
    {
      label: "russian_legal_other_select.EQUITY",
      value: "EQUITY",
    },
    {
      label: "russian_legal_other_select.FOUR_QUARTERS_FIVE",
      value: "FOUR_QUARTERS_FIVE",
    },
    {
      label: "russian_legal_other_select.REVENUE",
      value: "REVENUE",
    },
    {
      label: "russian_legal_other_select.COST",
      value: "COST",
    },
    {
      label: "russian_legal_other_select.NOTHING",
      value: "NOTHING",
    },
  ],
  israel_individual_person_select: [
    {
      label: "israel_individual_person_select.EIGHT_MILLION_NIS",
      value: "EIGHT_MILLION_NIS",
    },
    {
      label: "israel_individual_person_select.TWO_PREVIOUS_YEARS",
      value: "TWO_PREVIOUS_YEARS",
    },
    {
      label: "israel_individual_person_select.FIVE_MILLION_NIS",
      value: "FIVE_MILLION_NIS",
    },
    {
      label: "israel_individual_person_select.NOTHING",
      value: "NOTHING",
    },
  ],
  israel_legal_person_select: [
    {
      label: "israel_legal_person_select.JOINT_TRUST_PROPERTY",
      value: "JOINT_TRUST_PROPERTY",
    },
    {
      label: "israel_legal_person_select.STAFF_PROVINDENT_FUND",
      value: "STAFF_PROVINDENT_FUND",
    },
    {
      label: "israel_legal_person_select.BANKING_CORPORATION",
      value: "BANKING_CORPORATION",
    },
    {
      label: "israel_legal_person_select.PORTFOLIO_MANAGER",
      value: "PORTFOLIO_MANAGER",
    },
    {
      label: "israel_legal_person_select.INVESTMENT_ADVISER",
      value: "INVESTMENT_ADVISER",
    },
    {
      label: "israel_legal_person_select.MEMBER_OF_STOCK_EXCHANGE",
      value: "MEMBER_OF_STOCK_EXCHANGE",
    },
    {
      label: "israel_legal_person_select.UNDERWRITER",
      value: "UNDERWRITER",
    },
    {
      label: "israel_legal_person_select.VENTURE_CAPITAL_FUND",
      value: "VENTURE_CAPITAL_FUND",
    },
    {
      label: "israel_legal_person_select.INVESTORS_CORPORATION",
      value: "INVESTORS_CORPORATION",
    },
    {
      label: "israel_legal_person_select.FIFTY_MILLION_NIS",
      value: "FIFTY_MILLION_NIS",
    },
    {
      label: "israel_legal_person_select.TRUST_FUND",
      value: "TRUST_FUND",
    },
    {
      label: "israel_legal_person_select.INSURER",
      value: "INSURER",
    },
    {
      label: "israel_legal_person_select.NOTHING",
      value: "NOTHING",
    },
  ],
  usa_individual_person_select: [
    {
      label: "usa_individual_person_select.PRIVATE_INVESTOR",
      value: "PRIVATE_INVESTOR",
    },
    {
      label: "usa_individual_person_select.TWO_HUNDRED_THOUSAND",
      value: "TWO_HUNDRED_THOUSAND",
    },
    {
      label: "usa_individual_person_select.NOTHING",
      value: "NOTHING",
    },
  ],
  usa_legal_person_select: [
    {
      label: "usa_legal_person_select.BANK",
      value: "BANK",
    },
    {
      label: "usa_legal_person_select.PRIVATE_BUSINESS",
      value: "PRIVATE_BUSINESS",
    },
    {
      label: "usa_legal_person_select.ORGANIZATION",
      value: "ORGANIZATION",
    },
    {
      label: "usa_legal_person_select.DIRECTOR",
      value: "DIRECTOR",
    },
    {
      label: "usa_legal_person_select.TRUST",
      value: "TRUST",
    },
    {
      label: "usa_legal_person_select.ACCREDITED_INVESTORS",
      value: "ACCREDITED_INVESTORS",
    },
    {
      label: "usa_legal_person_select.NOTHING",
      value: "NOTHING",
    },
  ],
  power_of_attorney_select: [
    { label: "power_of_attorney_select.YES", value: true },
    { label: "power_of_attorney_select.NO", value: false },
  ],
  roleType: [
    { label: "beneficiar", value: "UBO" },
    { label: "director", value: "Director" },
    { label: "shareholder", value: "Shareholder" },
  ],
};
