import React, { useState } from "react";
import { Dialog, DialogTitle, Button, TextField, DialogContent, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useLanguage from "../languages/index";
import TextInput from "./TextInput";
import { Autocomplete } from "./Autocomplete";
import { SELECTS_OPTIONS } from "../pages/Settings/ProfileComponents/ProfileAutocomplete";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "39px 110px 56px",
  },
  dialogTitle: {
    padding: "16px 0",
    textAlign: "center",
    "& h2": {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  redButton: {
    background: theme.palette.text.red,
    color: theme.palette.background.default,
    "&:hover": {
      background: theme.palette.text.red,
    },
  },
  greenButton: {
    background: theme.palette.text.green,
    color: theme.palette.background.default,
    "&:hover": {
      background: theme.palette.text.green,
    },
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 24,
  },
}));

export default function DeleteDialog({ title, open, onClose, id, send }) {
  const classes = useStyles();
  const getText = useLanguage();
  const [textValue, setTextValue] = useState("");
  const [stage, setStage] = useState("");
  const [type, setType] = useState("");

  const confirm = () => {
    onClose(true, id, textValue, stage);
    setTextValue("");
  };
  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth open={open}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      {send && (
        <DialogContent style={{ padding: 8 }}>
          <Box id="investment_amount" mt={3}>
            <TextInput
              type={send ? "number" : "text"}
              value={textValue}
              onChange={(event) => setTextValue(event.target.value)}
              caption={getText("amount")}
            />
          </Box>
        </DialogContent>
      )}
      <div className={classes.buttons}>
        <Button onClick={confirm} className={send ? classes.greenButton : classes.redButton} size="large" variant="contained">
          {send ? getText("send") : getText("delete")}
        </Button>
        <Button onClick={() => onClose(false, id, textValue)} size="large" variant="contained" color="secondary">
          {getText("cancel")}
        </Button>
      </div>
    </Dialog>
  );
}
