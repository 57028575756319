import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  pageToPdf: {
    width: "100%",
    maxWidth: "1920px",
    minHeight: "595px",
    marginTop: "1rem",
  },
  button: {
    outline: "none !important",
    marginRight: "1.25rem"
  },
}));
