import React from "react";
import { SvgIcon } from "@material-ui/core";

export function BriefIcon({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path
        d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function InvestIcon({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path d="M14 3H10V21H14V3Z" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 8H18V21H22V8Z" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 13H2V21H6V13Z" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export function InvestIconVideo({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path d="M14 3H10V21H14V3Z" fill="#00ff8c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 8H18V21H22V8Z" fill="#00ff8c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 13H2V21H6V13Z" fill="#00ff8c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export function SecondaryMarketIcon({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path d="M19 7H4" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11L4 7L8 3" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 17L20 17" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 21L20 17L16 13" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export function VideoLibraryIcon({ ...other }) {
  return (
    <SvgIcon class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z" />
    </SvgIcon>
  );
}

export function NotifyIcon({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path
        d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M22 17H2C2.79565 17 3.55871 16.6839 4.12132 16.1213C4.68393 15.5587 5 14.7956 5 14V9C5 7.14348 5.7375 5.36301 7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9V14C19 14.7956 19.3161 15.5587 19.8787 16.1213C20.4413 16.6839 21.2044 17 22 17V17Z"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function NewsIcon({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path d="m22 3-1.67 1.67L18.67 3 17 4.67 15.33 3l-1.66 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z" />
    </SvgIcon>
  );
}

export function ArrowIcon({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path d="M1 10L5 5.5L1 1" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export function PDFIcon({ ...others }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 309.267 309.267"
      style={{ "enable-background": "new 0 0 309.267 309.267", "min-width": "75px" }}
      width="75px"
      height="75px"
      {...others}
    >
      <path
        style={{ fill: "#E2574C" }}
        d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658
		c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z"
      />
      <path style={{ fill: "#B53629" }} d="M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z" />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44
		c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153
		c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544z
		 M155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159
		c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413
		c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964
		c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379
		c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703
		c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z"
      />
    </svg>
  );
}

export function AddIcon({ ...others }) {
  return (
    <SvgIcon viewBox="0 0 42 42" {...others}>
      <path d="M21 1V41" fill="none" stroke="#7E9FC1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 21H41" fill="none" stroke="#7E9FC1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export function PdfIcon({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path d="M11.75 10.75V14.25C12.715 14.25 13.5 13.465 13.5 12.5C13.5 11.535 12.715 10.75 11.75 10.75Z" fill="inherit" />
      <path
        d="M5.625 10.75H4.75V12.5H5.625C6.107 12.5 6.5 12.107 6.5 11.625C6.5 11.143 6.107 10.75 5.625 10.75Z"
        fill="inherit"
      />
      <path
        d="M22 6H2C0.896 6 0 6.896 0 8V17C0 18.104 0.896 19 2 19H22C23.104 19 24 18.104 24 17V8C24 6.896 23.104 6 22 6ZM5.625 14.25H4.75V16H3V9H5.625C7.072 9 8.25 10.178 8.25 11.625C8.25 13.073 7.072 14.25 5.625 14.25ZM11.75 16H10V9H11.75C13.68 9 15.25 10.569 15.25 12.5C15.25 14.431 13.68 16 11.75 16ZM22.25 10.75H18.75V11.625H21.375V13.375H18.75V16H17V10.75C17 9.785 17.785 9 18.75 9H22.25V10.75Z"
        fill="inherit"
      />
    </SvgIcon>
  );
}

export function StatusCheck({ ...others }) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...others}>
      <circle cx="8" cy="8" r="8" fill="#4DCC97" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.529 11.2652L4.83325 9.24338L5.64198 8.16508L7.25942 9.37817L10.09 5.60413L11.1683 6.41285L7.529 11.2652Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export function ReportsIcon({ ...others }) {
  return (
    <SvgIcon viewBox="0 0 34 34" {...others}>
      <path
        d="M26.9167 5.66675H7.08333C5.51853 5.66675 4.25 6.93527 4.25 8.50008V28.3334C4.25 29.8982 5.51853 31.1667 7.08333 31.1667H26.9167C28.4815 31.1667 29.75 29.8982 29.75 28.3334V8.50008C29.75 6.93527 28.4815 5.66675 26.9167 5.66675Z"
        stroke="#2356B2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M22.6666 2.83325V8.49992" stroke="#2356B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3334 2.83325V8.49992" stroke="#2356B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.25 14.1667H29.75" stroke="#2356B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export function AssignmentIcon({ ...others }) {
  return (
    <SvgIcon {...others}>
      <path
        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
        fill="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function AuditIcon({ ...others }) {
  return (
    <SvgIcon viewBox="0 0 34 34" {...others}>
      <path
        d="M22.6667 5.66675H25.5001C26.2515 5.66675 26.9722 5.96526 27.5035 6.49661C28.0349 7.02797 28.3334 7.74863 28.3334 8.50008V28.3334C28.3334 29.0849 28.0349 29.8055 27.5035 30.3369C26.9722 30.8682 26.2515 31.1667 25.5001 31.1667H8.50008C7.74863 31.1667 7.02797 30.8682 6.49661 30.3369C5.96526 29.8055 5.66675 29.0849 5.66675 28.3334V8.50008C5.66675 7.74863 5.96526 7.02797 6.49661 6.49661C7.02797 5.96526 7.74863 5.66675 8.50008 5.66675H11.3334"
        stroke="#2356B2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2499 2.83325H12.7499C11.9675 2.83325 11.3333 3.46752 11.3333 4.24992V7.08325C11.3333 7.86566 11.9675 8.49992 12.7499 8.49992H21.2499C22.0323 8.49992 22.6666 7.86566 22.6666 7.08325V4.24992C22.6666 3.46752 22.0323 2.83325 21.2499 2.83325Z"
        stroke="#2356B2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function UsersIcon({ ...others }) {
  return (
    <SvgIcon viewBox="0 0 34 34" {...others}>
      <path
        d="M24.0834 29.75V26.9167C24.0834 25.4138 23.4864 23.9724 22.4237 22.9097C21.361 21.847 19.9196 21.25 18.4167 21.25H7.08341C5.58052 21.25 4.13918 21.847 3.07648 22.9097C2.01377 23.9724 1.41675 25.4138 1.41675 26.9167V29.75"
        stroke="#2356B2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7499 15.5833C15.8795 15.5833 18.4166 13.0463 18.4166 9.91667C18.4166 6.78705 15.8795 4.25 12.7499 4.25C9.62031 4.25 7.08325 6.78705 7.08325 9.91667C7.08325 13.0463 9.62031 15.5833 12.7499 15.5833Z"
        stroke="#2356B2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5833 29.7499V26.9166C32.5823 25.661 32.1644 24.4413 31.3952 23.449C30.626 22.4567 29.5489 21.748 28.3333 21.4341"
        stroke="#2356B2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6667 4.43408C23.8857 4.74618 24.966 5.45508 25.7376 6.44902C26.5091 7.44297 26.9279 8.66542 26.9279 9.92367C26.9279 11.1819 26.5091 12.4044 25.7376 13.3983C24.966 14.3923 23.8857 15.1012 22.6667 15.4132"
        stroke="#2356B2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function ModerateIcon({ ...others }) {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="#EFB968" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50002 8.99998L7.5 3H9.00002L9.00002 7.50001H13.5L13.5 8.99998H7.50002Z"
        fill="white"
      />
    </SvgIcon>
  );
}
