import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { NotificationManager } from "react-notifications";
import validate from "validate.js";
import ym from "react-yandex-metrika";
import { useStore } from "../../../store";
import TextInput from "../../../components/TextInput";
import useLanguage from "../../../languages";
import PopupPaper from "../../../components/PopupPaper";
import { postCreditionals, registerUser } from "../../../api/api";
import { REACT_APP_API_URL } from "../../../constants/selectConstants";
import { documentsState } from "./Documents";

const useStyles = makeStyles((theme) => ({
  input: { marginTop: 28 },
  button: {
    width: 190,
    padding: "12px 0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
  ndaContainer: {
    display: "flex",
    flexDirection: "column",
    "text-align": "justify",
    color: "rgb(161 161 161)",
  },
  ndaText: {
    fontSize: "12px",
    "text-align": "justify",
    color: "rgb(161 161 161)",
  },
}));

const initialState = {
  name: "",
  nameEn: "",
  surname: "",
  surnameEn: "",
  email: "",
  password: "",
  confirmPassword: "",
  voice: "",
  telegram: "",
  documents: documentsState,
  nda: false,
  errors: {},
  remember: true,
};

export default observer(({ history }) => {
  const getText = useLanguage();
  const classes = useStyles();
  const store = useStore();
  const [state, setState] = useState(initialState);
  const [telegramCheck, setTelegramCheck] = useState(false);
  const [resp, register] = registerUser();
  const [token, login] = postCreditionals();
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setState({ ...state, errors: {} });

    const urlParams = new URLSearchParams(window.location.search)

    const citizenship = urlParams.get('citizenship')
    const residence_country = urlParams.get('residence_country')

    const company_country = urlParams.get('company_country')
    const jurisdiction_of_organizaton = urlParams.get('jurisdiction_of_organizaton')

    const userType = urlParams.get('entity_type')

    if (citizenship && residence_country) {
      localStorage.setItem('userCountry', JSON.stringify({
        citizenship,
        residence_country
      }))
    }

    if (company_country && jurisdiction_of_organizaton) {
      localStorage.setItem('userCountry', JSON.stringify({
        company_country,
        jurisdiction_of_organizaton
      }))
    }

    if (userType) {
      localStorage.setItem('userType', userType)
    }

    const isUserCountrySetted = localStorage.getItem('userCountry')
      || (citizenship && residence_country)
      || (company_country && jurisdiction_of_organizaton)
    
    const isUserTypeSetted = localStorage.getItem('userType')
      || userType

    if (!isUserCountrySetted || !isUserTypeSetted) {
      window.location.href = "/sign-up/type"
    }

    localStorage.setItem('language', store.language)

    history.push("/sign-up?language=en");
  }, [store.language]);

  useEffect(() => {
    login({ email: state.email, password: state.password });
  }, [resp]);

  const personalConstraintsEn = {
    nameEn: {
      presence: {
        allowEmpty: false,
        message: `^${getText("pages.signUp.validation.notEmpty")}`,
      },
      length: {
        minimum: 1,
        message: `^${getText("pages.signUp.validation.notEmpty", 1)}`,
      },
      format: {
        pattern: "[a-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.nameEnFormat")}`,
      },
    },
    surnameEn: {
      presence: {
        allowEmpty: false,
        message: `^${getText("pages.signUp.validation.notEmpty")}`,
      },
      length: { minimum: 1, message: `^${getText("errors.passwordShort", 1)}` },
      format: {
        pattern: "[a-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.surnameEnFormat")}`,
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.emailEmpty")}`,
      },
      length: { minimum: 1, message: `^${getText("errors.emailInvalid", 1)}` },
      format: {
        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.emailFormat")}`,
      },
    },
    password: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.passwordEmpty")}`,
      },
      length: { minimum: 8, message: `^${getText("errors.passwordShort", 8)}` },
      format: {
        pattern: "^(?=\\S*[a-z])(?=\\S*[A-Z])(?=\\S*\\d)\\S*$",
        flags: "g",
        message: `^${getText("pages.settings.password.messages.validation.2")}`,
      },
    },
    confirmPassword: {
      presence: {
        allowEmpty: false,
        message: `^${getText("errors.passwordEmpty")}`,
      },
      equality: {
        attribute: "password",
        message: `^${getText("pages.settings.password.messages.validation.3")}`,
        comparator(v1, v2) {
          return v1 === v2;
        },
      },
    },
    voice: {
      presence: {
        allowEmpty: false,
        message: `^${getText("pages.signUp.validation.phoneEmpty")}`,
      },
      length: {
        minimum: 10,
        message: `^${getText("pages.signUp.validation.phoneMin", 10)}`,
      },
    },
  };

  const handleChange = (prop) => (event) => {
    if (telegramCheck === true && prop === "voice") {
      setState({
        ...state,
        [prop]: event.target.value,
        telegram: event.target.value,
      });
      return;
    }
    setState({ ...state, [prop]: event.target.value });
  };

  async function authorizeInvestor(data) {
    const result = await fetch(`${REACT_APP_API_URL}/api/auth/token/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (result.status !== 200) {
      // TODO обработать ошибки
    }
    const json = await result.json();

    store.setToken(json, true);
  }

  async function sendOtherData() {
    const countryType = JSON.parse(localStorage.getItem("userCountry"));
    const data = {
      ...countryType,
      entity_type: localStorage.getItem("userType"),
    };
    const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_personal_data/`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    if (result.status !== 200) {
      NotificationManager.error("There's an error. Please, check your input data.");
    }
  }
  async function registerInvestor(data) {
    setDisableButton(true);

    const email = data.email.toLowerCase().trim()

    const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/validate_email/${email}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const resp = await result.json();
    if (resp.in_use) {
      await NotificationManager.error(getText("pages.settings.password.messages.emailInUse"));
      setDisableButton(false);
      return;
    }

    const formData = { ...data }
    formData.email = email

    const result2 = await fetch(`${REACT_APP_API_URL}/api/v1/investors/register/`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    await authorizeInvestor({
      email: state.email.toLowerCase(),
      password: state.password,
    });
    await sendOtherData();
    setDisableButton(false);
    await history.push("/settings/profile");
  }

  const onSubmitNow = async (event) => {
    event.preventDefault();
    const errors = validate(state, personalConstraintsEn);

    if (!errors) {
      const userReg = {
        email: state.email,
        name_ru: state.name,
        name_en: state.nameEn,
        password: state.password,
        surname_ru: state.surname,
        surname_en: state.surnameEn,
        telegram: state.telegram,
        phone: state.voice,
        language: store.language,
      };
      setDisableButton(true);
      await registerInvestor(userReg);
    } else {
      NotificationManager.error(getText("pages.settings.password.messages.errorFormat"));
      setState({ ...state, errors: errors || {} });
    }
  };

  useEffect(() => {
    if (resp.error) {
      NotificationManager.error("Connection problems");
    }
  }, [resp]);

  const telegramChecked = () => {
    setTelegramCheck(!telegramCheck);
    state.telegram = telegramCheck ? "" : state.voice;
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <PopupPaper popup caption="Registration">
        <form>
          <TextInput
            type="text"
            error={state.errors.nameEn}
            className={classes.input}
            value={state.nameEn}
            onChange={handleChange("nameEn")}
            caption="Name"
          />
          <TextInput
            type="text"
            error={state.errors.surnameEn}
            className={classes.input}
            value={state.surnameEn}
            onChange={handleChange("surnameEn")}
            caption="Surname"
          />
          <TextInput
            type="email"
            error={state.errors.email}
            className={classes.input}
            value={state.email}
            onChange={handleChange("email")}
            caption={getText("pages.signUp.email")}
          />
          <TextInput
            type="password"
            error={state.errors.password}
            className={classes.input}
            value={state.password}
            onChange={handleChange("password")}
            caption="New Password"
          />
          <TextInput
            type="password"
            error={state.errors.confirmPassword}
            className={classes.input}
            value={state.confirmPassword}
            onChange={handleChange("confirmPassword")}
            caption="Confirm the password"
          />
          <TextInput
            type="number"
            error={state.errors.voice}
            className={classes.input}
            value={state.voice}
            onChange={handleChange("voice")}
            caption="Phone number"
          />
          <FormControlLabel
            style={{ marginTop: 10 }}
            label="Is Telegram linked to this number?"
            control={<Checkbox onChange={telegramChecked} color="primary" style={{ marginRight: 10 }} />}
          />
          {!telegramCheck && (
            <TextInput
              type="number"
              error={state.errors.telegram}
              className={classes.input}
              value={state.telegram}
              onChange={handleChange("telegram")}
              caption="Telegram"
            />
          )}
          <Box whiteSpace="pre-wrap" mt={2} className={classes.ndaText}>
            {/* <h4>I have read and accept the Risk Factors, Privacy Policy and Non Disclosure Agreement (NDA)</h4> */}
          </Box>
          <Box className={classes.ndaContainer} mt={2}>
            <FormControlLabel
              label={state.documents.map((el) => (
                <Typography variant="caption" display="block" gutterBottom>
                  {el.label}
                </Typography>
              ))}
              control={
                <Checkbox
                  onChange={(event) => setState({ ...state, nda: event.target.checked })}
                  checked={state.nda}
                  color="primary"
                  style={{ marginRight: 10 }}
                />
              }
            />
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
            <Button
              type="button"
              onClick={onSubmitNow}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ width: "100%" }}
              disabled={!state.nda || disableButton}
            >
              Register now
            </Button>
          </Box>
        </form>
      </PopupPaper>
    </div>
  );
});
