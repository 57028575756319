import React, { useRef, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, FormControlLabel, Checkbox, FormLabel, Typography, RadioGroup, Radio } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import useLanguage from "../../../languages";
import { useStyles } from "./style";
import { useStore } from "../../../store";
import { AutocompleteMaterial } from "../../../components/AutocompleteMaterial";
import { SELECTS_OPTIONS_CABINET } from "./CabinetAutoComplete";
import TextInput from "../../../components/TextInput";
import { REACT_APP_API_URL } from "../../../constants/selectConstants";
import MultiSelect from "../../../components/MultiSelect";
import { isFatfCountry } from "../../../api/api";

export default observer(
  ({
    profile,
    handleChangeAutoComplete,
    isValidation,
    handleChange,
    handleChangeChecked,
    handleChangeSelect,
    handleChangeDate,
    handleChangeProfileProp,
    notificationManager
  }) => {
    const getText = useLanguage();
    const classes = useStyles();
    const store = useStore();

    const canvasTextRef = useRef(null);
    const canvasDateRef = useRef(null);

    const convasTextDraw = useCallback(async () => {
      if (!profile.signature_text) return

      const canvas = canvasTextRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.textAlign = "center";
      ctx.textBaseline = "center";
      ctx.font = "48px Corinthia";
      ctx.fillText(profile?.signature_text || "", canvas.width / 2, canvas.height / 2);

      function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i += 1) ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
      }

      const image = new Image();
      image.src = canvas.toDataURL();
      const formData = new FormData();
      const file = DataURIToBlob(canvas.toDataURL());
      formData.append("signature_photo", file, "photo.jpg");

      const result = await fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_personal_data/`, {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
    }, [profile.signature_text]);

    useEffect(() => {
      if (!profile.profile_signed_date) return

      fetch(`${REACT_APP_API_URL}/api/v1/investors/fill_personal_data/`, {
        method: "PATCH",
        body: JSON.stringify({ profile_signed_date: profile.profile_signed_date }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          'Content-type': 'application/json'
        },
      })
    }, [profile.profile_signed_date])

    const convasDateDraw = useCallback(() => {
      const canvas = canvasDateRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.textAlign = "center";
      ctx.textBaseline = "center";
      ctx.font = "48px Corinthia";
      ctx.fillText(profile?.profile_signed_date || "", canvas.width / 2, canvas.height / 2);
    }, [profile.profile_signed_date]);

    useEffect(() => {
      convasTextDraw();
    }, [profile.signature_text]);

    useEffect(() => {
      convasDateDraw();
    }, [profile.profile_signed_date]);

    useEffect(() => {
      (async () => {
        if (profile.bank_country) {
          const fatfCountryResponse = await isFatfCountry(profile.bank_country).then(
            (res) => res.json()
          )

          const isFatfApproved = fatfCountryResponse.is_fatf_country ? "YES" : "NO"

          handleChangeProfileProp("is_wiring_bank_fatf_approved", isFatfApproved)

          if (!fatfCountryResponse.is_fatf_country) {
            notificationManager.error("Your bank is not FATF approved")
          }
        }
      })()
    }, [profile.bank_country])

    const BLOCKED_COUNTRIES = ['RU', 'BY'] // РФ, Белоруссия
    const isBlockedCountry = (country) => BLOCKED_COUNTRIES.includes(country)

    return (
      <div className={classes.container}>
        <Box id="company">
          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.company_name}
              onChange={handleChange("company_name")}
              caption={getText("pages.cabinet.companyName")}
              data-castil="company_name"
              error={isValidation.company_name}
              required
            />
          </Box>
          <Box mt={2}>
            <AutocompleteMaterial
              options={SELECTS_OPTIONS_CABINET.nonInterestOwnershipTypes.map((el) => ({
                label: getText(`${el.label}`),
                value: el.value,
              }))}
              text="non_individual_interest_ownership"
              title="pages.cabinet.nonInterestOwnershipTypes.title"
              value={profile?.non_individual_interest_ownership}
              onChange={handleChangeAutoComplete}
              data-castil="non_individual_interest_ownership"
              error={isValidation.non_individual_interest_ownership}
              required
            />
          </Box>
          {profile?.non_individual_interest_ownership === "TRUST" && (
            <>
              <Box mt={2}>
                <TextInput
                  type="text"
                  className={classes.input}
                  value={profile?.type_of_trust}
                  onChange={handleChange("type_of_trust")}
                  caption={getText("pages.cabinet.typeOfTrust")}
                />
              </Box>
              <Box mt={2}>
                <TextInput
                  type="text"
                  className={classes.input}
                  value={profile?.name_of_all_beneficiaries}
                  onChange={handleChange("name_of_all_beneficiaries")}
                  caption={getText("pages.cabinet.nameOfAllBeneficiaries")}
                />
              </Box>
            </>
          )}
          {profile.non_individual_interest_ownership === "OT" && (
            <Box mt={2}>
              <TextInput
                type="text"
                className={classes.input}
                value={profile?.non_individual_interest_ownership_other}
                onChange={handleChange("non_individual_interest_ownership_other")}
                caption={getText("pages.cabinet.nonInterestOwnershipTypes.titleOther")}
                data-castil="non_individual_interest_ownership_other"
                error={isValidation.non_individual_interest_ownership_other}
                helperMessage="Empty input"
                required
              />
            </Box>
          )}
          <Box mt={2}>
            <AutocompleteMaterial
              flag
              options={
                store.citizenships_en
                  .filter(((code) => !isBlockedCountry(code)))
                  .map((code) => ({ label: getText(`countries.${code}`), value: code }))
              }
              text="jurisdiction_of_organizaton"
              title="Jurisdiction of organizaton"
              value={profile?.jurisdiction_of_organizaton}
              onChange={handleChangeAutoComplete}
              data-castil="jurisdiction_of_organizaton"
              error={isValidation.jurisdiction_of_organizaton}
              required
            />
          </Box>
          <Box mt={2}>
            <AutocompleteMaterial
              flag
              options={
                store.citizenships_en
                  .filter(((code) => !isBlockedCountry(code)))
                  .map((code) => ({ label: getText(`countries.${code}`), value: code }))
              }
              text="company_country"
              title="Country of incorporation"
              value={profile?.company_country}
              onChange={handleChangeAutoComplete}
              data-castil="company_country"
              error={isValidation.company_country}
              required
            />
          </Box>

          <Box mt={2}>
            <span className={classes.datePickerLabel}>{getText("pages.cabinet.organizationDate")}*</span>
            <DatePicker
              className={classes.pickerContainerElement}
              onChange={handleChangeDate("organization_formation_date")}
              format="DD/MM/YYYY"
              animateYearScrolling
              value={profile?.organization_formation_date}
              data-castil="organization_formation_date"
              error={isValidation.organization_formation_date}
            />
          </Box>
          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.company_number}
              onChange={handleChange("company_number")}
              caption={getText("pages.cabinet.registrationnumber")}
              data-castil="company_number"
              error={isValidation.company_number}
              helperMessage="Empty input"
              required
            />
          </Box>
          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.registration_address_en}
              onChange={handleChange("registration_address_en")}
              caption={getText("pages.cabinet.registeredAddress")}
              data-castil="registration_address_en"
              required
            />
          </Box>
          <Box mt={2}>
            <FormControlLabel
              className={classes.checkboxTitle}
              label={<p className={classes.checkboxTitle}>{getText("pages.cabinet.electronicSigning")}</p>}
              control={
                <Checkbox
                  checked={profile.agree_to_electronic_signing === "YES"}
                  onChange={handleChangeChecked("agree_to_electronic_signing")}
                  color="primary"
                  className={isValidation.agree_to_electronic_signing ? classes.checkboxError : classes.checkbox}
                  data-castil="agree_to_electronic_signing"
                  error={isValidation.agree_to_electronic_signing}
                />
              }
            />
          </Box>
          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.investment_planned_amount}
              onChange={handleChange("investment_planned_amount")}
              caption={getText("pages.cabinet.investmentPlanned")}
              data-castil="investment_planned_amount"
              error={isValidation.investment_planned_amount}
              helperMessage="Empty input"
              required
            />
          </Box>
          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.salary}
              onChange={handleChange("salary")}
              caption={getText("pages.cabinet.salary")}
              data-castil="salary"
              error={isValidation.salary}
              helperMessage="Empty input"
              required
            />
          </Box>

          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.source_of_wealth}
              onChange={handleChange("source_of_wealth")}
              caption={getText("pages.cabinet.sourceOfWealth")}
              data-castil="source_of_wealth"
              error={isValidation.source_of_wealth}
              helperMessage="Empty input"
              required
            />
          </Box>

          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.source_of_funds}
              onChange={handleChange("source_of_funds")}
              caption={getText("pages.cabinet.sourceOfFunds")}
              data-castil="source_of_funds"
              error={isValidation.source_of_funds}
              helperMessage="Empty input"
              required
            />
          </Box>

          <Box className={classes.bottomLine} />

          <Box mt={2} />

          <Typography variant="h5">Bank information</Typography>
          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.bank_name}
              onChange={handleChange("bank_name")}
              caption={getText("pages.cabinet.bankName")}
              data-castil="bank_name"
              error={isValidation.bank_name}
              helperMessage="Empty input"
              required
            />
          </Box>
          <Box mt={2}>
            <AutocompleteMaterial
              flag
              options={store.citizenships_en.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
              text="bank_country"
              title={getText("pages.cabinet.bankCountry")}
              value={profile?.bank_country}
              onChange={handleChangeAutoComplete}
              data-castil="bank_country"
              error={isValidation.bank_country}
              required
            />
          </Box>
          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.swift}
              onChange={handleChange("swift")}
              caption={getText("pages.cabinet.swiftNo")}
              data-castil="swift"
              error={isValidation.swift}
              helperMessage="Empty input"
              required
            />
          </Box>

          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.account_number}
              onChange={handleChange("account_number")}
              caption={getText("pages.cabinet.acctNo")}
              data-castil="account_number"
              error={isValidation.account_number}
              helperMessage="Empty input"
              required
            />
          </Box>

          <Box mt={2}>
            <FormLabel className={classes.input} component="legend">
              <Typography className={classes.labelInput}>
                <span>{getText("pages.cabinet.antiMoneyInfo.wiringBank")} /</span>
                <a href="https://www.fatf-gafi.org/countries" target="_blank" rel="noreferrer" className={classes.linkText}>
                  FATF Countries
                </a>
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-label="is_wiring_bank_fatf_approved"
              value={profile.is_wiring_bank_fatf_approved || null}
              className={classes.radioGroup}
              onChange={handleChange("is_wiring_bank_fatf_approved")}
            >
              <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" style={{display: 'none'}} />
              <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" style={{display: 'none'}} />
              {
                profile.is_wiring_bank_fatf_approved && profile.is_wiring_bank_fatf_approved.length && (
                (profile.is_wiring_bank_fatf_approved.toLowerCase() === 'true' ||
                profile.is_wiring_bank_fatf_approved.toLowerCase() === 'yes') ? 'Yes' : 'No')
              }
              {
                (!profile.is_wiring_bank_fatf_approved || !profile.is_wiring_bank_fatf_approved.length) &&
                'Please, fill the company country'
              }
            </RadioGroup>
            <Box mt={2}>
              <Typography variant="body2" className={classes.fontSmall}>{getText("pages.cabinet.antiMoneyInfo.yes")}</Typography>
              <Typography variant="body2" className={classes.fontSmall}>{getText("pages.cabinet.antiMoneyInfo.no")}</Typography>
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel className={classes.input} component="legend">
              <Typography className={classes.labelInput}>{getText("pages.cabinet.antiMoneyInfo.investorCostumer")}</Typography>
            </FormLabel>
            <RadioGroup
              aria-label="is_wiring_bank_customer"
              value={profile.is_wiring_bank_customer || null}
              className={classes.radioGroup}
              onChange={handleChange("is_wiring_bank_customer")}
            >
              <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </Box>

          <Box className={classes.bottomLine} />

          <Box mt={2}>
            <MultiSelect
              select={SELECTS_OPTIONS_CABINET}
              moreText
              handleChangeSelect={handleChangeSelect}
              profile={profile.accredited_investor_representation_points}
              type="accredited_investor_representation_points"
              data-castil="accredited_investor_representation_points"
              error={isValidation.accredited_investor_representation_points}
              required
            />
          </Box>
          {profile?.accredited_investor_representation_points &&
            profile?.accredited_investor_representation_points.includes("OTHER_ENTITIES") && (
              <Box mt={2}>
                <MultiSelect
                  select={SELECTS_OPTIONS_CABINET}
                  moreText
                  handleChangeSelect={handleChangeSelect}
                  profile={profile.accredited_investor_representation_points_other}
                  type="accredited_investor_representation_points_other"
                  data-castil="accredited_investor_representation_points_other"
                  error={isValidation.accredited_investor_representation_points_other}
                  required
                />
              </Box>
            )}

          <Box className={classes.bottomLine} />

          <Box mt={2}>
            <style>{`
              .${classes.checkboxTitleSmall} > span {
                font-size: 12px;
              }
            `}</style>
            <FormControlLabel
              className={`${classes.checkboxTitle} ${classes.checkboxTitleSmall}`}
              label={`
                ${getText("pages.cabinet.isRisksApproved.investorQuestionnaire")}\n
                ${getText("pages.cabinet.isRisksApproved.acceptedAltaclub")}\n
                ${getText("pages.cabinet.isRisksApproved.dataProvided")}
              `}
              control={
                <Checkbox
                  checked={profile.is_risks_approved}
                  onChange={handleChangeChecked("is_risks_approved")}
                  color="primary"
                  className={!isValidation.is_risks_approved ? classes.checkboxError : classes.checkbox}
                  data-castil="is_risks_approved"
                  error={!isValidation.is_risks_approved}
                />
              }
            />
          </Box>

          <Box className={classes.bottomLine} />

          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.last_name_en}
              onChange={handleChange("last_name_en")}
              caption={getText("pages.cabinet.memberInformation.surname")}
              data-castil="last_name_en"
              error={isValidation.last_name_en}
              helperMessage="Empty input"
              required
            />
          </Box>

          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.first_name_en}
              onChange={handleChange("first_name_en")}
              caption={getText("pages.cabinet.memberInformation.forename")}
              data-castil="first_name_en"
              error={isValidation.first_name_en}
              helperMessage="Empty input"
              required
            />
          </Box>

          <Box mt={2}>
            <TextInput
              type="text"
              className={classes.input}
              value={profile?.middle_name_en}
              onChange={handleChange("middle_name_en")}
              caption={getText("pages.cabinet.memberInformation.patronymic")}
            />
          </Box>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Box>
              <canvas width="325" height="75" ref={canvasTextRef} />

              <TextInput
                type="text"
                className={classes.input}
                value={profile?.signature_text}
                onChange={handleChange("signature_text")}
                caption="Signature"
                data-castil="signature_text"
                error={isValidation.signature_text}
                helperMessage="Empty input"
                required
              />
            </Box>
            <Box mt={2.5}>
              <canvas width="325" height="75" ref={canvasDateRef} />
              <span className={classes.datePickerLabel}>Date *</span>
              <DatePicker
                className={classes.pickerContainerElement}
                onChange={handleChangeDate("profile_signed_date")}
                format="DD/MM/YYYY"
                animateYearScrolling
                value={profile?.profile_signed_date}
                data-castil="profile_signed_date"
                helperMessage="Empty input"
                error={isValidation.profile_signed_date}
              />
            </Box>
          </Box>
          <Box>
            <p className={classes.fontSmall} style={{maxWidth: "50%"}}>
              Only an authorized signatory of the company can sign the Corporate questionnaire.
            </p>
          </Box>
        </Box>
      </div>
    );
  }
);
