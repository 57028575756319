import React, { useEffect } from "react";
import { Box, Button, FormControlLabel, Typography, useMediaQuery, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import useLanguage from "../../../languages";
import { useStore } from "../../../store";
import { Autocomplete } from "../../../components/Autocomplete";
import TextInput from "../../../components/TextInput";

const useStyles = makeStyles({
  input: { marginTop: 24 },
  button: {
    width: 190,
    padding: "12px 0",
  },
});

export const Company = observer(({ state, handleChange, checkForm, handleChangeRole, history }) => {
  const getText = useLanguage();
  const desktop = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const store = useStore();

  const companyConstraints = {
    companyName: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.companyName")}` },
    },
    registeredAddressCompany: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.registeredAddressCompany")}` },
    },
    addressCompany: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.addressCompany")}` },
    },
    cityCompany: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.cityCompany")}` },
    },
    countryCompany: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.countryCompany")}` },
    },
  };

  const companyConstraintsEn = {
    companyNameEn: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.companyNameEn")}` },
      format: {
        pattern: "[a-z 0-9,.]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.companyNameEn")}`,
      },
    },
    addressCompanyEn: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.addressCompanyEn")}` },
      format: {
        pattern: "[a-z 0-9,.]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.addressCompanyEn")}`,
      },
    },
    cityCompanyEn: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.cityCompanyEn")}` },
      format: {
        pattern: "[a-z]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.cityCompanyEn")}`,
      },
    },
  };

  useEffect(() => {
    if (store.language === "ru") {
      history.push("?company&language=ru");
    } else {
      history.push("?company&language=en");
    }
  }, [store.language]);

  const handleClick = (prop) => (event) => {
    checkForm(prop, store.language === "ru" ? { ...companyConstraints, ...companyConstraintsEn } : companyConstraints);
  };

  return (
    <div>
      <Box mt={2} mb={4}>
        <Typography color="textSecondary" align={desktop ? "center" : "left"} variant="subtitle1">
          {getText("pages.signUp.personalDataCompany")}
        </Typography>
      </Box>
      <TextInput
        type="text"
        error={state.errors.companyName}
        className={classes.input}
        value={state.companyName}
        onChange={handleChange("companyName")}
        caption={getText("pages.signUp.companyName")}
      />
      <TextInput
        type="text"
        error={state.errors.registeredAddressCompany}
        className={classes.input}
        value={state.registeredAddressCompany}
        onChange={handleChange("registeredAddressCompany")}
        caption={getText("pages.signUp.registeredAddress")}
      />
      <TextInput
        type="text"
        error={state.errors.addressCompany}
        className={classes.input}
        value={state.addressCompany}
        onChange={handleChange("addressCompany")}
        caption={getText("pages.signUp.addressCompany")}
      />
      <TextInput
        type="text"
        error={state.errors.cityCompany}
        className={classes.input}
        value={state.cityCompany}
        onChange={handleChange("cityCompany")}
        caption={getText("pages.signUp.city")}
      />
      {store.language === "ru" && (
        <>
          <TextInput
            type="text"
            error={state.errors.companyNameEn}
            className={classes.input}
            value={state.companyNameEn}
            onChange={handleChange("companyNameEn")}
            caption={getText("pages.signUp.companyNameEn")}
          />
          <TextInput
            type="text"
            error={state.errors.addressCompanyEn}
            className={classes.input}
            value={state.addressCompanyEn}
            onChange={handleChange("addressCompanyEn")}
            caption={getText("pages.signUp.addressCompanyEn")}
          />
          <TextInput
            type="text"
            error={state.errors.cityCompanyEn}
            className={classes.input}
            value={state.cityCompanyEn}
            onChange={handleChange("cityCompanyEn")}
            caption={getText("pages.signUp.cityEn")}
          />
        </>
      )}
      <Box id="countryCompany" mt={3}>
        <Autocomplete
          error={state.errors.countryCompany}
          options={store.citizenships.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
          caption={getText("pages.signUp.countryCompany")}
          value={state.countryCompany}
          onChange={handleChange("countryCompany")}
        />
      </Box>
      <Box whiteSpace="pre-wrap" mt={3}>
        <h3>{getText("pages.signUp.role.caption")}</h3>
      </Box>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        {state.roles.map((el) => (
          <FormControlLabel
            key={el.role}
            label={getText(el.label)}
            control={<Checkbox onChange={handleChangeRole(el.role)} checked={el.status} color="primary" />}
          />
        ))}
      </Box>
      <Box mt={3} display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
        <Button onClick={handleClick("back")} className={classes.button} size="large" color="secondary" variant="contained">
          {getText("pages.signUp.back")}
        </Button>
        <Button onClick={handleClick()} size="large" variant="contained" color="primary" className={classes.button}>
          {getText("pages.signUp.next")}
        </Button>
      </Box>
    </div>
  );
});
