import React from "react";
import uuid from "react-uuid";
import { Box } from "@material-ui/core";
import PopupPaper from "../../../components/PopupPaper";
import { useStyles } from "../styles";

export const NewsPopupPaper = ({ paper }) => {
  const classes = useStyles();
  const getFormattedDate = (dateString) => new Date(dateString).toLocaleDateString('en-US')

  return (
    <PopupPaper wide key={uuid()} className={classes.newsContainer}>
      {paper.image &&
        <div className={classes.newsImageContainer}>
          <img src={paper.image} alt="news" className={classes.newsImage} />
        </div>
      }
      <div className={classes.newsTextContainer}>
        <a className={classes.newsTitle} href={paper.source_link} target="_blank" rel="noreferrer noopener">
          {paper.title}
        </a>

        <div className={classes.newsDescription}>{paper.description}...</div>
        <Box className={classes.newsLink} display="flex" justifyContent="space-between">
          <span>{paper.source_link.split(/\/+/)[1]}</span>
          <span>{getFormattedDate(paper.publish_date)}</span>
        </Box>
      </div>
    </PopupPaper>
  );
};
