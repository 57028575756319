import React, { useEffect } from "react";
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react-lite";
import useLanguage from "../../../languages";
import { useStore } from "../../../store";
import { Autocomplete } from "../../../components/Autocomplete";
import TextInput from "../../../components/TextInput";

const useStyles = makeStyles({
  input: { marginTop: 24 },
  button: {
    width: 190,
    padding: "12px 0",
  },
  popper: {
    marginLeft: 10,
  },
  paper: {
    boxShadow: "0px 8px 12px 4px rgba(57, 110, 190, 0.1)",
  },
  error: {
    "& input": {
      border: "1px solid #F36363",
    },
  },
  arrowLeft: {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: "10px solid white",
    position: "absolute",
    left: -8,
    top: "calc(50% - 10px)",
  },
  warning: {
    boxShadow: "0px 0px 1px 1px #f3ac63",
  },
  popover: { padding: "10px 15px" },
  eventsNone: { pointerEvents: "none" },
});

export const Personal = observer(({ history, state, handleChange, checkForm }) => {
  const getText = useLanguage();
  const desktop = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const store = useStore();

  const personalConstraints = {
    citizenship: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.citizenship")}` },
    },
    registeredAddress: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.registeredAddress")}` },
    },
    city: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.city")}` },
    },
    country: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.country")}` },
    },
  };

  const personalConstraintsEn = {
    registeredAddressEn: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.registeredAddressEn")}` },
      format: {
        pattern: "[a-z 0-9,.]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.registeredAddressFormatEn")}`,
      },
    },
    cityEn: {
      presence: { allowEmpty: false, message: `^${getText("pages.signUp.errors.cityEn")}` },
      format: {
        pattern: "[a-z 0-9,.]+",
        flags: "i",
        message: `^${getText("pages.signUp.validation.cityFormatEn")}`,
      },
    },
  };

  useEffect(() => {
    if (store.language === "ru") {
      history.push("?personal&language=ru");
    } else {
      history.push("?personal&language=en");
    }
  }, [store.language]);

  const handleClick = (prop) => (event) => {
    if (prop === "back") {
      history.push("/sign-up");
      return;
    }
    checkForm(prop, store.language === "ru" ? { ...personalConstraints, ...personalConstraintsEn } : personalConstraints);
  };

  return (
    <div>
      <Box mt={2} mb={4}>
        <Typography color="textSecondary" align={desktop ? "center" : "left"} variant="subtitle1">
          {getText("pages.signUp.personalData")}
        </Typography>
      </Box>
      <form>
        <Box id="citizenship" mt={3}>
          <Autocomplete
            error={state.errors.citizenship}
            options={store.citizenships.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
            caption={getText("pages.signUp.citizenship")}
            value={state.citizenship}
            onChange={handleChange("citizenship")}
          />
        </Box>
        <TextInput
          type="text"
          error={state.errors.registeredAddress}
          className={classes.input}
          value={state.registeredAddress}
          onChange={handleChange("registeredAddress")}
          caption={getText("pages.signUp.registeredAddress")}
        />
        <TextInput
          type="text"
          error={state.errors.city}
          className={classes.input}
          value={state.city}
          onChange={handleChange("city")}
          caption={getText("pages.signUp.city")}
        />
        {store.language === "ru" && (
          <>
            <TextInput
              type="text"
              error={state.errors.registeredAddressEn}
              className={classes.input}
              value={state.registeredAddressEn}
              onChange={handleChange("registeredAddressEn")}
              caption={getText("pages.signUp.registeredAddressEn")}
            />
            <TextInput
              type="text"
              error={state.errors.cityEn}
              className={classes.input}
              value={state.cityEn}
              onChange={handleChange("cityEn")}
              caption={getText("pages.signUp.cityEn")}
            />
          </>
        )}
        <Box id="country" mt={3}>
          <Autocomplete
            error={state.errors.country}
            options={store.citizenships.map((code) => ({ label: getText(`countries.${code}`), value: code }))}
            caption={getText("pages.signUp.country")}
            value={state.country}
            onChange={handleChange("country")}
          />
        </Box>
        <Box id="investorLegalType" mt={3}>
          <RadioGroup onChange={handleChange("investorLegalType")} value={state.investorLegalType}>
            <FormControlLabel
              className={classes.radioLabel}
              control={<Radio color="primary" />}
              value="individual"
              label={getText("pages.signUp.typeInvest.individual")}
            />
            <FormControlLabel
              className={classes.radioLabel}
              control={<Radio color="primary" />}
              value="company"
              label={getText("pages.signUp.typeInvest.company")}
            />
          </RadioGroup>
        </Box>
        <Box id="lookingToJoin" mt={3}>
          <FormControlLabel
            className={classes.radioLabel}
            control={<Checkbox color="primary" />}
            value="lookingToJoin"
            label={getText("pages.signUp.typeInvest.lookingToJoin")}
          />
        </Box>
        <Box mt={3} display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
          <Button onClick={handleClick("back")} className={classes.button} size="large" color="secondary" variant="contained">
            {getText("pages.signUp.back")}
          </Button>
          <Button onClick={handleClick("next")} size="large" variant="contained" color="primary" className={classes.button}>
            {getText("pages.signUp.next")}
          </Button>
        </Box>
      </form>
    </div>
  );
});
