/* eslint-disable camelcase, no-plusplus */
import moment from "moment";
import { useStore } from "../store";
import en from "./en.json";
import { countries_en } from "./countries";

export const langMap = [
  {
    label: "ENG",
    code: "en",
    dict: {
      ...en,
      countries: countries_en,
    },
  },
];

const resolve = (obj, path, ...args) => {
  try {
    let i = 0;
    const str = path.split(".").reduce((prev, curr) => prev[curr], obj);
    return str.replace(/%s/g, () => args[i++]);
  } catch {
    return null;
  }
};

const useLanguage = () => {
  const store = useStore();
  const text = langMap.find((lang) => lang.code === (store.language || "en"));

  moment.locale(store.language || "en");
  return (key, ...args) =>
    resolve(text.dict, key, ...args) ||
    resolve(langMap[0].dict, key, ...args) ||
    `${key}`;
};

export default useLanguage;
