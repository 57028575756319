import { makeStyles, withStyles } from "@material-ui/styles";
import { Tab } from "@material-ui/core";

export const useStylesNavigation = makeStyles((theme) => ({
  logo: {
  },
  logoCenter: {
    [theme.breakpoints.up("sm")]: {
      width: 190,
      position: "absolute",
      left: 0,
      right: 0,
      top: 24,
      zIndex: 1,
      margin: "0 auto",
    },
  },
  topBar: {
    padding: "0 34px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    boxShadow: "none",
    background: theme.palette.background.default,
  },
  addBorder: {
    borderBottom: "1px solid #DBE5EA",
  },
  topBarLang: {
    minHeight: 26,
    marginLeft: "auto",
    marginTop: -14,
  },
  barLang: { minHeight: 26 },
  topBarLangButton: {
    "&:first-child": {
      marginLeft: 0,
    },
    width: "auto",
    minHeight: 24,
    marginLeft: 14,
    padding: 0,
    minWidth: "auto",
  },

  menuButton: {
    marginLeft: "auto",
    marginTop: -10,
    padding: 0,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },

  menuIcon: { fontSize: 28 },
  drawerPaper: { width: 260 },
  drawerLeft: { borderRight: "none" },
  toolbar: { flexWrap: "wrap" },
  arrowIcon: { stroke: "#B9C2C9" },
}));

export const SidebarTab = withStyles((theme) => ({
  root: { stroke: "#B9C2C9", paddingLeft: 32 },
  wrapper: {
    flexDirection: "row",
    textTransform: "none",
    justifyContent: "flex-start",
    "& > *:first-child": {
      marginBottom: "0 !important",
      marginRight: 15,
    },
  },
  selected: { stroke: `${theme.palette.primary.main} !important` },
  textColorPrimary: {
    color: theme.palette.text.primary,
  },
}))(Tab);
