import React, { useState, useCallback } from "react";
import { Box, Button, Typography, Modal, IconButton } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import remove from "lodash/remove";
import DeleteDialog from "../../../components/DeleteDialog";
import useLanguage from "../../../languages";
import PopupPaper from "../../../components/PopupPaper";
import { PaperButton, Hover, HoverItem } from "../style";
import { AddIcon } from "../../../components/Icons";
import DocumentList from "./DocumentList";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  closeIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  popup: {
    width: "100%",
    borderBottom: "1px solid #DBE5EA",
  },
  addАccaunt: {
    margin: "16px 0",
  },
  addАccaunt_btn: {
    display: "flex",
    alignItems: "center",
  },
  bottomLine: {
    border: "1px solid #DBE5EA",
    margin: "32px 0 24px",
  },
}));

export default observer(({ profile, subject, handleChangeDoc }) => {
  const classes = useStyles();
  const getText = useLanguage();

  const [person, setPerson] = useState([]);
  const [deleteTitle, setDeleteTitle] = useState();
  const [deleteIndex, setDeleteIndex] = useState();
  const [openDelete, openDeleteDialog] = useState();

  const addNewBeneficiary = () => {
    setPerson([...person, []]);
  };

  const deleteAccount = (index) => () => {
    setDeleteTitle(getText(`documentMember.remove-${subject}`, index + 1));
    setDeleteIndex(index);
    openDeleteDialog(true);
  };
  const handleDelete = useCallback((confirm) => {
    if (confirm) {
      setPerson(remove(person, (_, index) => index !== deleteIndex));
    }
    openDeleteDialog(false);
  });

  return (
    <Box className={classes.container}>
      <DeleteDialog onClose={handleDelete} title={deleteTitle} open={openDelete} style={{ left: 0 }} />
      {person &&
        person.map((member, index) => (
          <PopupPaper className={classes.popup} key={member.beneficiary_id} inline>
            <Box ml="auto">
              <Typography variant="h5">
                <strong>
                  {getText(`documentMember.${subject}`)} {index + 1}
                </strong>
              </Typography>
              {profile.entity_type === "Individual" && profile.citizenship === "RU" && (
                <DocumentList documentList="documentIndividualNonRFPersonSelect" handleChangeDoc={handleChangeDoc} />
              )}
              {profile.entity_type === "Individual" && profile.citizenship !== "RU" && (
                <DocumentList documentList="documentIndividualRFPersonSelect" handleChangeDoc={handleChangeDoc} />
              )}
              {profile.entity_type === "Legal" && profile.citizenship === "RU" && (
                <DocumentList documentList="documentLegalRFPersonSelect" handleChangeDoc={handleChangeDoc} />
              )}
              {profile.entity_type === "Legal" && profile.citizenship !== "RU" && (
                <DocumentList documentList="documentLegalNonRFPersonSelect" handleChangeDoc={handleChangeDoc} />
              )}
              <Button variant="contained" color="secondary" className={classes.button} onClick={deleteAccount(index)}>
                {getText("delete")}
              </Button>
            </Box>
          </PopupPaper>
        ))}
      <Box className={classes.addАccaunt}>
        <Hover className={classes.forms}>
          <PaperButton fullWidth color="secondary" variant="contained" onClick={addNewBeneficiary}>
            <Box className={classes.addАccaunt_btn}>
              <HoverItem>
                <Typography color="textSecondary" variant="inherit">
                  <strong>{getText(`documentMember.add-${subject}`)}</strong>
                </Typography>
              </HoverItem>
              <HoverItem>
                <Box fontSize={40} lineHeight={0} ml="auto">
                  <AddIcon fontSize="inherit" />
                </Box>
              </HoverItem>
            </Box>
          </PaperButton>
        </Hover>
      </Box>
    </Box>
  );
});
