import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const PopupPaper = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      borderRadius: 20,
      boxShadow: "0px 8px 24px rgba(57, 110, 190, 0.08)",
    },
    boxShadow: "none",
  },
}))(Paper);

const useStyles = makeStyles((theme) => ({
  popup: {
    marginTop: 115,
    [theme.breakpoints.up("sm")]: {
      width: 672,
      marginTop: 215,
      padding: "71px 136px 69px",
    },
  },
  wide: {
    [theme.breakpoints.up("sm")]: {
      padding: "40px 40px",
      borderRadius: 10,
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 30,
    },
  },
  inline: {
    width: "100%",
    padding: "39px 0 48px",
    [theme.breakpoints.up("sm")]: {
      padding: "39px 110px 48px",
      width: 952,
    },
  },
  clickable: {
    cursor: "pointer",
    transition: "background .2s",
    background: theme.palette.secondary.main,
    boxShadow: "none",
    padding: "40px 40px 40px",
    "&:hover": {
      background: theme.palette.secondary.light,
    },
  },
}));

const Caption = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    textAlign: "center",
  },
}))(Typography);

export default function PopupPaperComponent({ popup, wide, caption, children, inline, clickable, className, ...others }) {
  const classes = useStyles();

  return (
    <PopupPaper
      className={clsx(
        inline ? classes.inline : "",
        popup ? classes.popup : "",
        clickable ? classes.clickable : "",
        wide ? classes.wide : "",
        className
      )}
      {...others}
    >
      {caption && <Caption variant="h5">{caption}</Caption>}
      {children}
    </PopupPaper>
  );
}
