import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import PopupPaper from "../components/PopupPaper";
import { obtainMappingTokens } from "../api/api";

export default observer(({ location }) => {
  useEffect(() => {
    const { search } = location;

    const searchParams = new URLSearchParams(search);
    const secret = searchParams.get('secret')

    if (!secret) {
      window.location.href = "/login"
      return
    }

    const { access, refresh } = localStorage

    if (access && refresh) {
      window.location.href = "/portfolio/volume"
      return
    }

    obtainMappingTokens(JSON.stringify({ secret }))
      .then((response) => response.json())
      .then((response) => {
        const { access, refresh } = response

        localStorage.setItem('access', access)
        localStorage.setItem('refresh', refresh)

        window.location.href = "/portfolio/volume"
      })
  }, [])

  return (
    <PopupPaper popup caption="Authorization">
      <Box mt={2}>
        Waiting for authorization...
      </Box>
    </PopupPaper>
  );
});
