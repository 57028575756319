import React from "react";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Box, Typography, Chip, Hidden } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as numeral from "numeraljs";
import useLanguage from "../languages/index";
import pluralize from "../utils/pluralize";
import { useStore } from "../store";
import theme from "../theme";

const CompanySummary = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },
  content: {
    margin: "0 !important",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.down("xs")]: {
      borderBottom: "none",
    },
  },
  expandIcon: {
    marginTop: 50,
  },
}))(ExpansionPanelSummary);

const useStyles = makeStyles((theme) => ({
  leftBorder: {
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
  },
  image: {
    borderRight: `1px solid ${theme.palette.secondary.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },
    "& img": {
      width: "80%",
      height: "80%",
    },
  },
}));

const BlueChip = withStyles((theme) => ({
  root: {
    background: theme.palette.text.secondary,
    color: "#FFFFFF",
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "8px 6px",
    marginBottom: 14,
  },
}))(Chip);

export default function PanelCompany({ round, children, ...others }) {
  const classes = useStyles();
  const getText = useLanguage();
  const store = useStore();

  const {
    startup_logo: image,
    startup_name: title,
    startup_description: startupDescription,
    startup_description_en: startupDescriptionEn,
    description,
    description_en: descriptionEn,
    shares_amount: sharesAmount,
  } = round;

  return (
    <>
      <Hidden smUp>
        <ExpansionPanel>
          <CompanySummary expandIcon={<ExpandMoreIcon />}>
            <Box pt={7} display="flex" flexDirection="column" alignItems="flex-start">
              <Box mb={3.25} className={classes.image} display="flex">
                <img alt="" src={image} style={{ objectFit: "contain" }} />
              </Box>
              <Box fontSize={18}>
                <Typography variant="inherit">
                  <strong>{title}</strong>
                </Typography>
              </Box>
              <Box fontSize={12} mt={1.3}>
                <Typography variant="inherit">
                  <strong>
                    {store.languange === "en" ? startupDescriptionEn || descriptionEn : startupDescription || description}
                  </strong>
                </Typography>
              </Box>
              <Box mt={5}>
                <BlueChip label={round.round_level} />
                <Box mb={1}>
                  <Typography color="textSecondary" component="p" variant="subtitle2">
                    {store.language === "en"
                      ? getText(
                          sharesAmount > 1 ? "tips.sharesAmountPlural" : "tips.sharesAmount",
                          numeral(sharesAmount).format("0,0")
                        )
                      : pluralize(
                          sharesAmount,
                          getText("tips.sharesAmount", numeral(sharesAmount).format("0,0")),
                          getText("tips.sharesAmountTwo", numeral(sharesAmount).format("0,0")),
                          getText("tips.sharesAmountPlural", numeral(sharesAmount).format("0,0"))
                        )}
                  </Typography>
                </Box>
                <Typography variant="h5" component="p">
                  <strong>{numeral(round.total_value_usd).format("$0,0.00") || 0}</strong>
                </Typography>
                {round.growth ? (
                  <Box mt={1.3} color={round.growth > 0 ? theme.palette.text.green : theme.palette.text.red}>
                    <Typography component="p" variant="subtitle2">
                      {round.growth.toFixed(2)}%
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </CompanySummary>
          <ExpansionPanelDetails>
            <Box pr={2.5}>{children}</Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Hidden>
      <Hidden xsDown>
        <ExpansionPanel expanded>
          <CompanySummary>
            <Box display="flex" width="100%">
              <Box className={classes.image} display="flex" height={213} maxWidth={300} minWidth={262}>
                <img alt="" src={image} style={{ objectFit: "contain" }} />
              </Box>
              <Box fontSize={18} pl={5} pr={5} display="flex" justifyContent="center" flexDirection="column">
                <Typography variant="inherit">
                  <strong>{title}</strong>
                </Typography>
                <Box fontSize={12} mt={1.3}>
                  <Typography variant="inherit">
                    <strong>
                      {store.language === "en" ? startupDescriptionEn || descriptionEn : startupDescription || description}
                    </strong>
                  </Typography>
                </Box>
              </Box>
              <Box p={5} textAlign="right" minWidth={248} marginLeft="auto" className={classes.leftBorder}>
                <BlueChip label={round.round_level} />
                <Box mb={1}>
                  <Typography color="textSecondary" component="p" variant="subtitle2">
                    {store.language === "en"
                      ? getText(
                          sharesAmount !== 1 ? "tips.sharesAmountPlural" : "tips.sharesAmount",
                          numeral(sharesAmount).format("0,0")
                        )
                      : pluralize(
                          sharesAmount,
                          getText("tips.sharesAmount", numeral(sharesAmount).format("0,0")),
                          getText("tips.sharesAmountTwo", numeral(sharesAmount).format("0,0")),
                          getText("tips.sharesAmountPlural", numeral(sharesAmount).format("0,0"))
                        )}
                  </Typography>
                </Box>
                <Typography variant="h5" component="p">
                  <strong>{numeral(round.total_value_usd).format("$0,0.00") || 0}</strong>
                </Typography>
                {round.growth ? (
                  <Box mt={1.3} color={round.growth > 0 ? theme.palette.text.green : theme.palette.text.red}>
                    <Typography component="p" variant="subtitle2">
                      {round.growth.toFixed(2)}%
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </CompanySummary>
          <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
        </ExpansionPanel>
      </Hidden>
    </>
  );
}
