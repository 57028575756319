import { observer } from "mobx-react-lite";
import React, { useState, useMemo } from "react";
import find from "lodash/find";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, Input } from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { ModerateIcon, PdfIcon, StatusCheck } from "../../../components/Icons";
import SexyLink from "../../../components/SexyLink";
import useLanguage from "../../../languages";
import { useStore } from "../../../store";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  document: {
    display: "flex",
    flexDirection: "column",
    "& > label": {
      margin: "10px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  text: {
    userSelect: "none",
    fontSize: "1rem",
    fontWeight: 700,
    maxWidth: "70%",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default observer(({ documentsTypes, handleChangeDoc, documents }) => {
  const store = useStore();
  const classes = useStyles();
  const getText = useLanguage();

  const getType = (documentType) => {
    const _type = find(documentsTypes.data.document_types, (_type) => _type.id === documentType);
    if (!_type) return "";
    return store.language === "ru" && _type.name ? _type.name : _type.name_en;
  };

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>{getText("tables.document")}</TableCell>
          <TableCell>{getText("tables.documentFile")}</TableCell>
          <TableCell>{getText("tables.uploaded")}</TableCell>
          <TableCell>{getText("tables.status")}</TableCell>
          <TableCell align="right">{getText("tables.example")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documentsTypes.complete ? (
          documents.map((document, index) => (
            <TableRow key={document.id}>
              <TableCell id="col_1">
                {document.title ? (
                  <>
                    <Typography>{document.title}</Typography>
                    <Typography variant="inherit" color="textSecondary">
                      {getType(document.document_type)}
                    </Typography>
                  </>
                ) : document.document_type ? (
                  getType(document.document_type)
                ) : (
                  <Typography>{getText("titleNotFound")}</Typography>
                )}
              </TableCell>
              <TableCell id="col_2">
                <div className={classes.flex}>
                  {document.document_file && (
                    <>
                      <Box mr={1.5} height={24}>
                        <PdfIcon color="primary" />
                      </Box>
                      <Typography variant="inherit" noWrap>
                        <SexyLink component="a" color="inherit" target="_blank" href={document.document_file}>
                          {document.document_file_name}
                        </SexyLink>
                      </Typography>
                    </>
                  )}
                </div>
              </TableCell>
              <TableCell id="col_3">
                <Typography variant="inherit" noWrap>
                  {document.document_file && moment(document.created_at).format("YYYY/MM/DD")}
                </Typography>
              </TableCell>
              <TableCell id="col_4">
                <div className={classes.flex}>
                  <Box mr={1.5} height={20}>
                    {(document.document_file && document.is_active) || document.signed ? (
                      <StatusCheck fontSize="small" />
                    ) : document.document_file && !document.is_active ? (
                      <ModerateIcon fontSize="small" />
                    ) : (
                      ""
                    )}
                  </Box>
                  <Typography variant="inherit" noWrap>
                    {document.document_file || document.signed ? (
                      getText(document.is_active || document.signed ? "documentStatus.verified" : "documentStatus.check")
                    ) : (
                      <Typography variant="inherit" noWrap color="secondary">
                        {getText("documentStatus.notLoaded")}
                      </Typography>
                    )}
                  </Typography>
                </div>
              </TableCell>
              <TableCell id="col_5" align="right">
                {!document.document_file ? (
                  <label htmlFor={`contained-button-file-${index}`}>
                    <Input
                      className={classes.input}
                      onChange={(event) => handleChangeDoc(event.target.files[0], document.document_type, document.id)}
                      accept="image/*"
                      id={`contained-button-file-${index}`}
                      type="file"
                    />
                    <Button variant="contained" color="secondary" component="span" style={{ whiteSpace: "nowrap" }}>
                      {getText("addDocument")}
                    </Button>
                  </label>
                ) : (
                  <Button variant="contained" color="secondary">
                    <a download href={document.document_file}>
                      <strong>{getText("download")}</strong>
                    </a>
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <></>
        )}
      </TableBody>
    </Table>
  );
});
